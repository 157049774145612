import { Inject, Injectable } from '@angular/core';
import { CustomerPK, CountableItem } from '@gfs/shared-models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, concatMap, first } from 'rxjs';
import moment from 'moment';
import { InjectionTokens } from '../../injection-tokens';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class CountableItemService {

  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private INVENTORY_API_BASE_URL: string,
  ) {
    this.baseUrl = INVENTORY_API_BASE_URL + '/api/v1';
  }

  getCountableItems(
    customerPK: CustomerPK,
    userLocale: string,
    searchText: string,
    offset: number,
    limit: number,
    date: string,
    type: string
  ): Observable<CountableItem[]> {
    const obj = Object.assign(customerPK,
      {
        locale: userLocale,
        searchText,
        offset: offset.toString(),
        limit: limit.toString(),
        date: moment.utc(date).format('YYYY-MM-DD'),
        type
      });
     return this.store.select(state=>state.worksheets.worksheetPricingOption).pipe(
        concatMap((worksheetPricingOption)=>
          this.http.get<CountableItem[]>(`${this.baseUrl}/countable-items`, {
            headers: new HttpHeaders({
              'worksheetPricingOptions' : worksheetPricingOption
          }) ,
          params: { ...obj } }
          )
         .pipe(first())
        ))
  }

}
