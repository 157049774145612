import { Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MeasurementUnit, Recipe, RecipeCategory } from '@gfs/shared-models';
import { WINDOW } from '@gfs/shared-services';
import { AnalyticsService } from '@gfs/store/feature/analytics-service/analytics-service.service';
import { AppState, selectAllCategories } from '@gfs/store/recipe/reducers';
import { Store, select } from '@ngrx/store';
import { CategoryUtilsService } from '@recipe-ui/services/category-utils.service';
import jsPDF from 'jspdf';
import moment from 'moment';
import { Observable, Subject, combineLatest, takeUntil } from 'rxjs';

@Component({
  selector: 'gfs-app-print-recipe-summary',
  templateUrl: './print-recipe-summary.component.html',
  styleUrls: ['./print-recipe-summary.component.scss']
})
export class PrintRecipeSummaryComponent implements OnInit ,OnDestroy{
  summaryData = this.data.recipes.filter((recipe)=> !recipe.deleted && !recipe.details.batch);
  categories$: Observable<RecipeCategory[]> = this.store.pipe(select(selectAllCategories));
  currentCustomer$ = this.store.select(state => state.auth.customerName)
  customerId$ = this.store.select(state => state.auth.pk.customerId)
  categoryList : RecipeCategory[] = []
  recipeWithCategoryList
  categoryNames: {};
  destroy$ = new Subject();
  public removeRecipePrintEventListener: () => void;
  @ViewChild('pdfTable') pdfTable :ElementRef;
  standardMeasurementUnits$: Observable<MeasurementUnit[]> = this.store
    .select((state) => state.addItemsFeature.measurementUnits);
  resolvedMeasurementUnits : MeasurementUnit[]
  overallAverages: any;
  currentLang$ = this.store.select(state => state.layout.language);

  constructor( 
  public dialogRef: MatDialogRef<PrintRecipeSummaryComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  @Inject(WINDOW) private window: Window,
  public elementRef: ElementRef, 
  public renderer: Renderer2,
  private store: Store<AppState>,
  public categoryUtils: CategoryUtilsService,
  public analyticsService : AnalyticsService,

  ){
    combineLatest([
      this.categories$,
      this.standardMeasurementUnits$
    ]).pipe(takeUntil(this.destroy$)).pipe(takeUntil(this.destroy$)).subscribe((data)=>{
      this.categoryList = data[0];
      this.resolvedMeasurementUnits = data[1];
      this.recipeWithCategoryList =  this.mapRecipeTocategory()
    })
    
  }

public downloadAsPDF() {
  this.analyticsService.GA4EventLogger('download',{file_type : 'Pdf', file_name : 'RecipeBook.pdf'})
  const doc = new jsPDF();
  const element = document.getElementById('pdfTable');
  doc.html(element, {
      html2canvas: {
          scale: 0.2
      },
      x: 10,
      y: -10,
      width: 210,
      windowWidth: 1700,
      autoPaging:'text',
      margin: [10, 0, 16, 0],

      callback: function (doc) {
          const totalPages = doc.internal.pages.length;
          for (let i = 1; i <= totalPages-1; i++) {
              doc.setPage(i);
              const footerText = `Page ${i} of ${totalPages-1}`;
              doc.setFontSize(8);
              doc.text(footerText, 100, 288);
              doc.setFontSize(12);
              doc.text('Gordon Food Service®',88,294)
              doc.setFontSize(10);
              doc.text(moment().format(("DD/MM/YYYY")),180,294)
          }
          const filename = 'RecipeBook.pdf';
          doc.save(filename);
      }
  });
}
  ngOnInit(): void {
    this.overallAverages = this.calculateOveallAverages()
    this.removeRecipePrintEventListener = this.renderer.listen(document, 'keydown', (event) => {
      if (event.keyCode === 80 && (event.ctrlKey || event.metaKey) && !event.altKey && (!event.shiftKey)) {event.preventDefault();
        this.downloadAsPDF();}
    });
    }
    ngOnDestroy(): void {
      this.destroy$.next(true)
      this.destroy$.complete()
    this.removeRecipePrintEventListener();
    }

  mapRecipeTocategory(){
  const categorizedItems = {};
  const categoryNames = {}
  categorizedItems['unassigned'] = []
  this.categoryList.forEach(category => {
  categorizedItems[category.id] = [];
  categoryNames[category.id]=[category.name]
  });
  this.categoryNames = categoryNames
    this.summaryData.forEach(item => {
  if (categorizedItems[item.categoryId]) {
    categorizedItems[item.categoryId].push(item);
  } else if(item.categoryId === null){
    categorizedItems['unassigned'].push(item)
  }
});

const recipeSummaryObject = Object.entries(categorizedItems).map(([key, value]) => ({
  categoryid: key,
  items: value as Recipe[],
  categoryAverages: this.calculateCategoryAverage(value)
}));
recipeSummaryObject.forEach((recipe)=>{
  recipe.items.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
})
return recipeSummaryObject
}

calculateOveallAverages():any {
  let totalportion = 0;
  let totalsellingPrice = 0;
  let totalgrossMargin = 0;
  let totalfoodCost = 0;
  const totalItems = this.summaryData.length
  this.summaryData.forEach((recipe)=>{
    totalportion = totalportion + recipe.recipePrice.totalCost ;
    totalsellingPrice = totalsellingPrice + recipe.recipePrice.menuPrice;
    totalgrossMargin = totalgrossMargin + (recipe.recipePrice.menuPrice - recipe.recipePrice.totalCost);
    totalfoodCost = totalfoodCost + recipe.recipePrice.foodCostPercent
  })
  return {
    portionCostOverall : totalportion ? totalportion/totalItems : 0,
    sellingPriceOverall : totalsellingPrice ?  totalsellingPrice/totalItems :0,
    grossMarginOverall : totalgrossMargin ?  totalgrossMargin/totalItems :0 ,
    foodCostOverall :totalfoodCost? totalfoodCost/totalItems : 0
  }
}

calculateCategoryAverage(items): any {
  if (items.length === 0) {
      return {}
  }
  const totalItems = items.length
  let sellingPrice = 0;
  let grossProfit = 0;
  let foodCostPercentage = 0;
  let totalPortion =0;
  
  items.forEach((item)=>{
      totalPortion = totalPortion + item.recipePrice?.totalCost
      sellingPrice = sellingPrice + item.recipePrice.menuPrice
      grossProfit = grossProfit + (item.recipePrice.menuPrice - item.recipePrice.totalCost)
      foodCostPercentage = foodCostPercentage + item.recipePrice.foodCostPercent
  })
  return {
    portionCostAvg : totalPortion ? totalPortion/totalItems : 0,
    sellingPriceAvg: sellingPrice ? sellingPrice/totalItems : 0, 
    grossProfitAvg : grossProfit? grossProfit/totalItems : 0,
    foodCostPercentageAvg : foodCostPercentage ? foodCostPercentage/totalItems : 0
}

}

}


