import { CustomerPK } from './user.model';

export interface Cart {
  totalQuantity: string;
  totalPrice?: string;
}
export interface AddToCartPayload {
  itemId:string;
  quantity:string;
  unitType:string;
  includePrice?:boolean;
  customerPK?:CustomerPK;
}
