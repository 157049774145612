import { Inject, Injectable } from '@angular/core';
import { Cart, CustomerPK } from '@gfs/shared-models';
import { Observable, BehaviorSubject, catchError, filter, of, takeUntil } from 'rxjs';
import { InjectionTokens } from '../../injection-tokens';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '@gfs/shared-services/services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject } from '@ngrx/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ofType } from '@ngrx/effects';
import { ActionTypes } from '../cart';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private baseUrl: string;
  disbaleSubject = new BehaviorSubject({disable : false , itemId : ''})
  constructor(
    @Inject(InjectionTokens.INVENTORY_API_BASE_URL) private API_BASE_URI: string,
    private http: HttpClient,
    private messageService: MessageService,
    private translate: TranslateService,
    public actionsListener$: ActionsSubject,
    public snackBar: MatSnackBar,
  ) {
    this.baseUrl = API_BASE_URI + '/api/v1';
   }
  addIngredientToCart(payload: {
    itemId: string;
    quantity:string;
    unitType:string;
    customerPK:CustomerPK;
    includePrice:boolean
  }): Observable<Cart> {
    return this.http
      .post<Cart>(
        `${this.baseUrl}/addToCarts`,
      {
          itemId : payload.itemId,
          quantity: payload.quantity,
          unitType : payload.unitType,
          includePrice: payload.includePrice,
          customerPK: payload.customerPK
      }
      ).pipe(
        catchError((error) => this.logError(error, ' Please try again.', null))
      )
  }
  logError<T>(error: any, operation: string, result?: T): Observable<T> {

    const localizedErrorMessage = this.translate.instant(
      'MESSAGES.SNACKBAR_ERROR_MESSAGE'
    );
    const tryAgainMessage = `${localizedErrorMessage} ${operation}`
    this.messageService.queue(tryAgainMessage);
    return of(result);
  }

  handleAddToCartSuccess(){
    this.actionsListener$.pipe(ofType(ActionTypes.GetCartDataSuccess))
  .pipe(
    filter((data:any)=> (!!data?.payload?.cart && !!data?.payload?.itemId)))
    .subscribe((data: any) => {
      this.snackBar.open(this.translate.instant('MESSAGES.ADD_TO_CART_SUCCESS',{value:data.payload.itemId}),
      this.translate.instant('OK'), { duration: 4000 })
      this.disbaleSubject.next({disable : false,itemId : data?.payload?.itemId})
  })
  }
}
