import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InventoryConstants } from '@gfs/constants';
import { APPLICATION_USER_ROLE, ApplicationUserRole, CustomerPK, CustomItem, Supplier } from '@gfs/shared-models';
import { InventoryUtilsService } from '@gfs/shared-services';
import { GetMeasurementUnitsAttempt, GetSuppliersAttempt } from '@gfs/store/feature/add-items';
import { GetAllCustomItemsAttempt, GetAllGeneralItemsAttempt } from '@gfs/store/inventory/actions/customerItems.actions';
import {
  GetAllCustomItemDataAttempt,
  GetWorksheetAttempt,
  GetWorksheetSummaryAttempt,
  SelectWorksheet,
  SetManageNonGfsbulkMode
} from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, first, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { AddItemDialogComponent } from '../../add-items/add-item-dialog/add-item-dialog.component';
import { NonGfsInfoDialogComponent } from '../../add-items/non-gfs-info-dialog/non-gfs-info-dialog.component';
import { SupplierItemPanelComponent } from '../supplier-item-panel/supplier-item-panel.component';

@Component({
  selector: 'gfs-manage-custom-items',
  templateUrl: './manage-custom-items.component.html',
  styleUrls: ['./manage-custom-items.component.scss']
})
export class ManageCustomItemsComponent implements OnInit, OnDestroy {

  notifier = new Subject<void>();

  currentLang$ = this.store.select(state => state.layout.language);
  isMobile$: Observable<boolean> = this.store
    .select(state => state.layout.isMobile);

  isOnline$: Observable<boolean> = this.store.select(state => state.network.isOnline);
  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet;
  customerPk$ = this.store.select(state => state.auth.pk);

  selected$ = this.store.select(state => state.worksheets.selected);
  customerPkSubscription: Subscription;
  toggleButtonState = false

  suppliers$: Observable<Supplier[]> = this.store
    .select(state => state.addItemsFeature.suppliers)
    .pipe(filter(sup => !!sup));

  customItems$: Observable<CustomItem[]> = this.store
    .select(state => state.customerItems.customItems)
    .pipe(
      filter(items => !!items),
      map(items => items.filter(item => !item.deleted))
    );
    useManageNonGfsGroupOperations$: Observable<boolean> = this.store.select((state)=> state.worksheets?.useManageNonGfsToggleState    ).pipe(
      filter(v => v !== undefined),
      );

    @ViewChild(SupplierItemPanelComponent) SupplierItemPanelComponent : SupplierItemPanelComponent;

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    @Inject(APPLICATION_USER_ROLE) public applicationUserRole : ApplicationUserRole,
  ) { }

  ngOnInit() {
    this.customerPk$.pipe(
      filter(pk => !!pk),
      first(),
      takeUntil(this.notifier)
    ).subscribe(pk => {
      if (!InventoryConstants.GROUP_ENTITIES.includes(pk.entityType)) {
        this.store.dispatch(new GetWorksheetSummaryAttempt());
      }
      this.store.dispatch(new GetSuppliersAttempt());
      this.store.dispatch(new GetAllCustomItemsAttempt());
      this.store.dispatch(new GetAllGeneralItemsAttempt());
      this.store.dispatch(new GetMeasurementUnitsAttempt());
      this.store.dispatch(new GetAllCustomItemDataAttempt());
    });

    this.store.select(state => state.worksheets.worksheets)
      .pipe(
        map(InventoryUtilsService.sortSummaries),
        filter(worksheets => worksheets.length > 0),
        withLatestFrom(this.selected$),
        takeUntil(this.notifier)
      )
      .subscribe(([worksheets, selected]) => {
        if (!selected) {
          this.store.dispatch(new SelectWorksheet({ worksheetId: worksheets[0].id, shouldNavigate: false }));
        }

        this.customerPkSubscription = this.customerPk$
          .pipe(
            first(),
            takeUntil(this.notifier)
          )
          .subscribe((customerPk: CustomerPK) => {
            if (InventoryConstants.CUSTOMER_ENTITIES.includes(customerPk.entityType)) {
              if (!worksheets[0].storageAreas) {
                this.store.dispatch(new GetWorksheetAttempt(worksheets[0].id));
              }
            }
          });
      });
  }

  ngOnDestroy(): void {
    this.customerPkSubscription?.unsubscribe();
    this.notifier.next();
    this.notifier.unsubscribe();
    this.SupplierItemPanelComponent.checkboxSelectedItems = [];
    this.store.dispatch(new SetManageNonGfsbulkMode(false));
    
  }

  toggleEditMode(event){
    this.toggleButtonState = !this.toggleButtonState;
    this.store.dispatch(new SetManageNonGfsbulkMode(this.toggleButtonState));
    this.SupplierItemPanelComponent.checkboxSelectedItems = [];
  }

  onAddItemClick(): void {
    this.dialog.open(AddItemDialogComponent, {
      data: {
        step: 3,
        isManage: true,
      },
      panelClass: 'inventory-add-item-dialog'
    });
  }

  showNonGFSDialog(): void {
    this.dialog.open(NonGfsInfoDialogComponent, {
      data: {
        step: 3,
        isManage: true,
      },
      panelClass: 'non-gfs-info-dialog'
    });
  }
}
