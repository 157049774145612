import { Inject, Injectable } from '@angular/core';
import { APPLICATION_USER_ROLE, ApplicationUserRole } from '@gfs/shared-models';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class HasPermissionService {

  constructor(
    private permissionsService: NgxPermissionsService,
    @Inject(APPLICATION_USER_ROLE) public applicationUserRole : ApplicationUserRole,
  ) { }

  hasPermission(permission: string | string[]): Promise<boolean> {
    if(this.applicationUserRole === ApplicationUserRole.Employee){
      return Promise.resolve(true);
    } 
    return this.permissionsService.hasPermission(permission);
  }

}