import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { InventoryConstants, RecipeConstants } from '@gfs/constants';
import {  APPLICATION_USER_ROLE, ApplicationUserRole, CustomerPK} from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

export function handleSubMenuPermissions(permissions: NgxPermissionsObject, pk: CustomerPK , isEmployee? : boolean): any {
  let showReports = false;

  if (pk === null || pk === undefined) {
    return {};
  }
  return {
   
    recipe: isEmployee ? RecipeConstants.RECIPE_EMPLOYEE_LINKS.filter((x) => x.roles?.some((requiredRole) => requiredRole === pk.entityType) ?? true) :
    RecipeConstants.RECIPE_LINKS.filter((x) => x.roles?.some((requiredRole) => requiredRole === pk.entityType) ?? true),
    inventory: InventoryConstants.INVENTORY_LINKS.filter((link) => {
      if (link.text === 'HEADER.MENU.REPORTS') {
        if (
          permissions[
          InventoryConstants.INVENTORY_ROLE_PERMISSIONS.reports
            .inventoryValue
          ]
        ) {
          showReports = true;
        }
        return showReports;
      }

      // hides counts page from chain/family users
      if (link.text === 'HEADER.MENU.INVENTORY_COUNTS') {
        if (!InventoryConstants.CUSTOMER_ENTITIES.includes(pk.entityType)) {
          return false;
        }
      }
      return true;
    }),
  };
}

@Component({
  selector: 'gfs-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubHeaderComponent implements OnInit {

  isOnline$ = this.store.select(state => state.network.isOnline);
  isMobile$ = this.store.select(state => state.layout.isMobile);
  customerPk$ = this.store.select(state => state.auth.pk);
  subHeaderLinks: Array<{
    text: string,
    link: string,
    isActive: boolean,
  }>;
  activeUrl: string;
  isCustomerUnitSelectionPage: boolean;
  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.header;
  activeApplication: 'recipe' | 'inventory';
  displayManageMenu = new BehaviorSubject(false);

  constructor(
    public router: Router,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private permissionsService: NgxPermissionsService,
    @Inject(InjectionTokens.ACTIVE_APPLICATION) activeApp: 'recipe' | 'inventory',
    @Inject(APPLICATION_USER_ROLE) public applicationUserRole : ApplicationUserRole,
  ) {
    this.activeApplication = activeApp;
  }

  ngOnInit() {
    combineLatest([this.permissionsService.permissions$, this.customerPk$]).subscribe(([permissions, pk]) => {
      const subHeaderLinks = handleSubMenuPermissions(permissions, pk,this.applicationUserRole === ApplicationUserRole.Employee)[this.activeApplication];
      this.subHeaderLinks = subHeaderLinks;
      this.displayManageMenu.next(!!subHeaderLinks);
      this.setActiveTab(this.router.url);
    });
    this.isCustomerUnitSelectionPage =
      this.router.url === InventoryConstants.CUSTOMER_UNIT_SELECTION_PATH;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.setActiveTab(event.urlAfterRedirects);
      });
  }

  setActiveTab(url: string): void {
    this.activeUrl = url;

    if (this.subHeaderLinks) {
      const activeLinkIndex = this.subHeaderLinks.indexOf(
        this.subHeaderLinks.find(tab =>
          this.activeUrl.includes(tab.link)
        )
      );

      this.subHeaderLinks.forEach((link, index: number) => {
        link.isActive = index === activeLinkIndex;
      });
    }
    this.changeDetector.markForCheck();
  }

}
