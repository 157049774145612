<div class="modal-container" [ngStyle]="{height : type==='delete' ? '230px' : '250px',width : type==='delete' ? '100%' : 'auto' }">
  <button class="close-button" (click)="closeModal()"
    attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}">
    <img class="close-button-img" src="assets/images/close-icon.svg"
      alt="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}" />
  </button>
  <div class="confirmation-modal-content">
    <div class="modal-warning-text">
      {{ data.title | translate : titleData }}
    </div>
    <div class="modal-warning-subtext">
      {{subtitle | translate}}
    </div>
    <div class="Group-delete-warning-text" *ngIf="type === 'GROUP_DELETE'">
      <mat-icon matSuffix class="warning-icon" svgIcon="warning-icon">
      </mat-icon>
      <span class="warning-text">
        {{ 'MODALS.WARNING_TEXT_GROUP_DELETE' | translate }}
      </span>
    </div>
    <div class="modal-button-bar">
      <button [id]="cancelButtonId" type="button" class="secondary-destructive-button large-button"
        *ngIf="cancelButtonAriaLabel | translate as cancelButtonAriaLabel" (click)="closeModal()"
        [attr.aria-label]="cancelButtonAriaLabel">
        {{cancelButtonLabel | translate}}
      </button>
      <button [id]="submitButtonId" class="primary-destructive-button large-button" type="submit" (click)="submit()"
        *ngIf="submitButtonAriaLabel | translate as submitButtonAriaLabel" [attr.aria-label]="submitButtonAriaLabel">
        {{submitButtonLabel | translate}}
      </button>
    </div>
  </div>
</div>