import { Injectable } from '@angular/core';
import { CustomerUtilService } from '@gfs/shared-services/services/customer-util.service';
import { DataLayerService } from '@gfs/shared-services/services/google-tag-manager/data-layer.service';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
 customer = JSON.parse((localStorage.getItem('customer')))
 customer_unit = this.customer?.customerId;
 entity_type =  this.customer?.entityType;
 event_timestamp = new Date().toString();
 isUsCustomer$ = this.store.select(state => state.auth).pipe(map(auth => this.customerUtilService.isUsCustomer(auth.pk)));

constructor(
  private dataLayer: DataLayerService,
  public store: Store<AppState>,
  public customerUtilService: CustomerUtilService,
  ) {}

  GA4EventLogger(eventName : string , params?:any){
    this.dataLayer.push({
      event: eventName,
      ...this.createEventParams(eventName , params)
    })
  }

  createEventParams(eventName , additionalParameters){
    let params;
    switch(eventName){
      case('close_inventory') : {
        params = {
          customer : {
            customer_unit: this.customer_unit,
            entity_type: this.entity_type
          },
          interaction:{
            event_timestamp : this.event_timestamp
          }
        }
        return params
      }
      case('inventory_add_to_cart') :
      case('recipe_add_to_cart') : 
      {
        params = {
          customer : {
            customer_unit: this.customer_unit,
            entity_type: this.entity_type
          },
          interaction:{
            event_timestamp : this.event_timestamp,
            add_to_cart_itemId : additionalParameters
          }
        }
        return params
      }
      case('recipe_reports_view'): {
        params = {
          customer : {
            customer_unit: this.customer_unit,
            entity_type: this.entity_type
          },
        }
        return params

      }
      case('download'):
      {
        params = {
          customer : {
            customer_unit: this.customer_unit,
            entity_type: this.entity_type
          },
          interaction: {
            file_type : additionalParameters?.file_type,
            file_name : additionalParameters?.file_name
          }
          
        }
        return params
      }
    }
  }
}
