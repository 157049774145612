import { Recipe } from './recipe.model';
import { RecipeConstants } from '@gfs/constants';
import { CustomerPK } from './user.model';
import { IDictionary } from './IDictionary';

export type IngredientItem = GfsItem
  | GeneralItem
  | CustomItem
  | Recipe
  | { missingItem: string }
  ;

export interface GfsItem {
  id: string;
  description: LocalizedValue[];
  brand: LocalizedValue[];
  image: LocalizedValue[];
  innerPackSize: string;
  qtyPerMasterSellUnit: number;
  price: ItemPrice;
  category?: LocalizedValue[];
  baseUom?: string;
  baseWeightUom?: string;
  isCatchWeight?: boolean;
  netWeightKg?: number;
  netVolumeMl?: number;
  portionQty?: string;
  portionUom?: string;
  units?: MeasurementUnit[];
}

export interface GeneralItem {
  id: string;
  description: string;
  customerPK: CustomerPK;
  productList: Product[];
  categoryId: string;
  deleted?: boolean;
}

export interface CustomItem {
  id: string;
  customerPK: CustomerPK;
  supplierItemCode: string;
  supplierId: string;
  description: string;
  purchaseUnit: PurchaseUnit;
  deleted?: boolean;
  addToWorksheet: boolean;
  storageAreaId?: string;
}

export interface LocalizedValue {
  value: string;
  languageCode: string;
}

interface ItemPrice {
  casePrice: number;
  unitPrice: number;
  caseCatchWeightPrice: number;
  unitCatchWeightPrice: number;
  catchWeightUom: string;
}

export interface Ingredient {
  gfsItem: GfsItem;
  recipeItem: Recipe;
  customItem: CustomItem;
  generalItem: GeneralItem;
}

export interface RecipeIngredient {
  id: string;
  customerPK: CustomerPK;
  recipeId: string;
  itemId: string;
  itemType: string;
  subType: string;
  unit: string;
  quantity: string;
  isDeleted: boolean;
  ordinal: number; // Not returned from api. Mapped in RecipeService
  price? : IngredientPrice
  configuredCountingUnits: string[];
  showVolumeInformation?: boolean
}

export interface IngredientPrice {
  casePrice?:number
}


export type ItemReference = FullItemReference & ItemTypeKeyReference;

export type FullItemReference = {
  type: string,
  key: string,
  parent?: ItemReference,
  child?: ItemReference
};

export type ItemTypeKeyReference = {
  type: string,
};

export type UnitOfMeasureConfiguration = {
  hasWeightConfig: boolean;
  hasVolumeConfig: boolean;
};
export type MeasurementUnitGroup = {
  units: Array<MeasurementUnit>,
  group: string
};
export type ItemUnits = {
  units: Array<ResolvedMeasurementUnitGroup>,
  hasVolumeUnitConfiguration: boolean;
  hasWeightUnitConfiguration: boolean;
};

export type ResolvedMeasurementUnitGroup = {
  unitSource: Array<MeasurementUnit>,
  units: Array<ResolvedUnitOfMeasure>,
  group: string
};

export type ResolvedUnitOfMeasure = {
  key: string,
  localized: LocalizedValue[],
  localizedSymbol: LocalizedValue[],
  subType?: string
};
export class GroupedItemReference {
  type: string;
  items: Array<ItemReference>;
}
export class ResolvedItem {
  itemReference: ItemReference;

  // private mPresentationItemType?: string;
  // public get presentationItemType(): string {
  //   return this.mPresentationItemType;
  // }
  // public set presentationItemType(value: string) {
  //   this.mPresentationItemType = value;
  // }

  // presentationItemId?: string;
  units?: ItemUnits;

  gfsItem: GfsItem;
  recipeItem: Recipe;
  customItem: CustomItem;
  generalItem: GeneralItem;
  customItemData: CustomItemData;


}

export class CountableItem {
  sourceType?: string;
  sourceItemId?: string;
  gfsItem: GfsItem;
  recipeItem: Recipe;
  customItem: CustomItem;
  generalItem: GeneralItem;
  customItemData: CustomItemData;
  public static ingredientData = (
    item: CountableItem,
    language: string
  ): {
    itemType: string;
    itemId: string;
    itemDescription: string;
  } => {
    const itemData = [
      {
        itemType: 'GFS',
        itemId: item.gfsItem?.id,
        itemDescription:
          item.gfsItem?.description.find((x) => x.languageCode === language)
            .value ??
          item.gfsItem?.description.find(
            (x) => x.languageCode === RecipeConstants.LANGUAGES.DEFAULT
          ).value ??
          '',
      },
      {
        itemType: 'RECIPE',
        itemId: item.recipeItem?.id,
        itemDescription: 'Recipe Item',
      },
      {
        itemType: 'GENERAL',
        itemId: item.generalItem?.id,
        // TODO: When general items can have more than just a single custom item in the productList,
        // this code will need to determine what description to use
        itemDescription: item.customItem?.description,
      },
      {
        itemType: 'CUSTOM',
        itemId: item.customItem?.id,
        itemDescription: item.customItem?.description,
      },
      { itemId: '', itemType: '', itemDescription: '' },
    ];

    // If there is a general item, choose it every time. Otherwise get the first valid item in the list.
    if (itemData.some(x => x.itemType === 'GENERAL' && !!x.itemId)) {
      return itemData.find((x) => x.itemType === 'GENERAL');
    } else {
      const result = itemData.filter((x) => x.itemId != null);
      return result[0];
    }
  };
  public static repackCountableItem = (
    item: GfsItem | GeneralItem | CustomItem
  ): CountableItem => {
    const tbl = {
      GFS: 'gfsItem',
      RECIPE: 'recipeItem',
      CUSTOM: 'customItem',
      GENERAL: 'generalItem',
    };
    const result = {} as CountableItem;
    result[tbl[(item as any).itemType]] = item;
    return result;
  };
}

export interface Product {
  id: string;
  type: string;
  primaryProduct: boolean;
}

export interface ItemCategory {
  id: string;
  name: LocalizedValue[];
  editable: boolean;
  customerPK: CustomerPK;
}

export interface PurchaseUnit {
  custom?: CustomPurchaseUnit;
  gfs?: GfsPurchaseUnit;
}

export interface CustomPurchaseUnit {
  name: string;
  price: string;
  catchWeight: boolean;
  netWeight: string;
  weightUnitId: string;
  netVolume?: string;
  volumeUnitId?: string;
}

export interface GfsPurchaseUnit {
  netWeight?: string;
  weightUnitId?: string;
  netVolume?: string;
  volumeUnitId?: string;
}

export interface MeasurementUnit {
  id: string;
  customerPK: CustomerPK;
  measurementSystem: string;
  measurementType: string;
  name: LocalizedValue[];
  parentUnitId: string;
  qtyInParent: string;
  standardUnitId: string;
  symbol: LocalizedValue[];
  size: number;
  displayCategories?: string[];
  displayCode?: string;
  parentUom?: string;
}

export interface CustomItemData {
  id: string;
  itemId: string;
  itemType: string;
  customerPK: CustomerPK;
  countingUnits: PortioningUnit[];
  categoryId: string;
  purchasedByUnit: boolean;
  purchaseUnit: PurchaseUnit;
  recipeUnits: PortioningUnit[];
}

export interface PortioningUnit {
  custom: CustomPortioningUnit;
  standard: StandardPortioningUnit;
}

export interface CustomPortioningUnit {
  id: string;
  qtyInParent: string;
  parentUnitId: string;
  name: string;
  deleted?: boolean;
}

export interface StandardPortioningUnit {
  unitId: string;
}

export interface InventoryItemValue {
  id: string;
  primaryItemValue: number;
  secondaryItemValue: number;
}

export interface LiteralUnit {
  type: string;
  name: LocalizedValue[];
}

export interface ConversionUnit {
  unitType: string;
  unitId: string;
  parentUnitId: string;
  qtyInParent: string;
}

export interface CountingUnit {
  itemId: string;
  unit: ConversionUnit;
  literalUnit: LiteralUnit;
  standardUnit: MeasurementUnit;
  // sapUnit: SapUnit;
  customUnit: PortioningUnit;
}

export interface GfsMaterialUnit {
  'displayCode': string;
  'descriptions': {
    single: IDictionary<string>,
    plural: IDictionary<string>,
  };
}

export interface GfsMaterialUnitsResult {
  displayCodes: GfsMaterialUnit[];
}
