import { DOCUMENT, registerLocaleData } from '@angular/common';
import locale_fr_CA from '@angular/common/locales/fr-CA';
import { AfterViewChecked, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { AppConfigService, WINDOW } from '@gfs/shared-services';
import { LogInAttempt, SetLanguage, SetMobile, SetTitle, StartOnlineOfflineCheck } from '@gfs/store/common';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import LogRocket from 'logrocket';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { DeviceIdentifierService } from './services/shared/device-identifier/device-identifier.service';
import { AuthenticationService } from '@gfs/shared-services/auth';

declare const require: any;
const matSelectArrowIcon = require('!!raw-loader!../assets/images/down-arrow.svg').default;
// declare global {
//   interface Object extends Lettable<Object> {}
// }
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {

  isMobile$: Observable<boolean> = this.store.select((state: AppState) => state.layout.isMobile);
  customerPk$ = this.store.select(state => state.auth.pk);
  userClaims$ = this.store.select((state: AppState) => state.auth.user ? state.auth.user.claims : null);
  isCustomerEntity$: Observable<boolean>;

  // local state because it's very simple. Can be pulled into the store if needed in other components
  showHeader = false;
  showHeaderElements = false;
  showSubHeader = false;
  notifier = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private documentService: Document,
    private deviceIdentifierService: DeviceIdentifierService,
    private configService: AppConfigService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private router: Router,
    public authService: AuthenticationService,
    @Inject(WINDOW) public window: Window,
  ) {
    this.initializeLogRocket();
    this.setAppDeviceType();

    this.store.select((state: AppState) => state.layout.language)
      .pipe(takeUntil(this.notifier)).subscribe(language => this.translate.setDefaultLang(language));

    // TODO: Set the initial language to the user's default language instead of the application default language.
    this.store.dispatch(new SetLanguage(InventoryConstants.LANGUAGES.DEFAULT));

    const noHeaderRoutes = [InventoryConstants.MOBILE_HAMBURGER_MENU_PATH];
    this.router.events.pipe(takeUntil(this.notifier)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader = noHeaderRoutes.indexOf(event.url?.split('?')[0]) < 0;
        this.showHeaderElements = this.router.url !== InventoryConstants.CUSTOMER_UNIT_SELECTION_PATH;
        this.showSubHeader = this.router.url !== InventoryConstants.CUSTOMER_UNIT_SELECTION_PATH;
      }
    });

    if (this.configService.getSettings().FF_OFFLINE_MODE) {
      this.store.dispatch(new StartOnlineOfflineCheck());
    }
  }

  async ngOnInit() {
    this.userClaims$.pipe(takeUntil(this.notifier)).subscribe(claims => {
      this.identifyLogRocket(claims);
    });

    // needed for datetime locales in DatePipe
    registerLocaleData(locale_fr_CA);

    this.appendGoogleAnalytics();

    // TODO: analyze this potentially blocking event
    // there may be scenarios where this never resolves because of an unstable app/session state,
    // we need to handle it better with a timeout
    this.authService.authenticationState$()
      .pipe(filter(authState => authState.isAuthenticated))
      .subscribe(() => this.getCustomerInfoFromLocalStorage());

    const title = this.translate.instant('TITLE');
    this.store.dispatch(new SetTitle(title));

    this.translate.onLangChange.pipe(takeUntil(this.notifier)).subscribe((newLang: LangChangeEvent) => {
      this.setPageTitle();
    });

    this.isCustomerEntity$ = this.customerPk$.pipe(
      filter(pk => !!pk),
      map(pk => InventoryConstants.CUSTOMER_ENTITIES.includes(pk.entityType))
    );
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  private setPageTitle() {
    this.translate.get('TITLE').pipe(takeUntil(this.notifier)).subscribe(title => {
      this.store.dispatch(new SetTitle(title));
    });
  }

  private setAppDeviceType(): void {
    this.deviceIdentifierService.observeDeviceType()
      .pipe(
        debounceTime(500),
        takeUntil(this.notifier)
      ).subscribe(isMobile => {
        this.store.dispatch(new SetMobile(isMobile));
      });
  }

  private appendGoogleAnalytics() {
    const config = this.configService.getSettings();

    // append GA minified script to head
    const scriptElement = document.createElement('script');
    const scriptText = document.createTextNode(config ? config.GOOGLE_TAG_MANAGER_SNIPPET : '');
    scriptElement.appendChild(scriptText);
    this.documentService.head.appendChild(scriptElement);
  }

  getCustomerInfoFromLocalStorage(): void {
    const storedCustomer = JSON.parse(this.window.localStorage.getItem('customer'));
    this.store.dispatch(new LogInAttempt({ storedCustomerId: storedCustomer?.customerId }));
  }

  ngAfterViewChecked() {
    this.overrideMatSelectArrow();
  }

  overrideMatSelectArrow() {
    const matSelectArrows = Array.from(window.document.getElementsByClassName('mat-select-arrow'));
    for (const arrow of matSelectArrows) {
      arrow.outerHTML = matSelectArrowIcon;
    }
  }

  initializeLogRocket() {
    const config = this.configService.getSettings();
    const logRocketProject = config ? config.LOGROCKET_PROJECT : '';

    if (logRocketProject) {
      console.log('Initialize LogRocket');
      LogRocket.init(logRocketProject, {
        console: {
          shouldAggregateConsoleErrors: true,
        },
      });
    }
  }

  identifyLogRocket(claims) {
    if (claims) {
      console.log('Identifying LogRocket');
      LogRocket.identify(claims?.guid, {
        name: claims?.firstName + ' ' + claims?.lastName,
        email: claims?.email,
      });
    }
  }
}
