import {
  AfterViewChecked,
  ChangeDetectorRef, Component,
  ElementRef, Inject,
  OnDestroy, OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenavContent } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { IAppContext, IDictionary, Worksheet } from '@gfs/shared-models';
import { AppConfigService, InjectionTokens, WorksheetHttpService } from '@gfs/shared-services';
import { isTruthy, retryWhenOnline } from '@gfs/shared-services/extensions/rxjs';
import {
  PatchWorksheetAttempt,
  SetGroupOperationToggleState,
  SetInfiniteScrollingToggleState,
  SetIsEditingName,
  SetPricingPreference
} from '@gfs/store/inventory/actions/worksheets.actions';
import { WorksheetEffects } from '@gfs/store/inventory/effects/worksheets.effects';
import { AppState } from '@gfs/store/inventory/reducers';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { BatchProductCountRequest, ProductCountElement, WorksheetDTO, WorksheetItemDTO, WorksheetValuationStatusFlags } from '@gfs/v2/shared-models';

import { ComponentCanDeactivate } from '@inventory-ui/services/guards/pending-changes.guard';
import { ComponentIsSaving } from '@inventory-ui/v2/common/guards/save-in-progress.guard';
import { WorksheetDataService } from '@inventory-ui/v2/common/services/worksheet-data.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ItemSearchBarComponent } from 'libs/shared-components/src/lib/item-search-bar/item-search-bar.component';
import {
  BehaviorSubject,
  combineLatest, firstValueFrom, from, interval, merge, Observable,
  of, Subject
} from 'rxjs';
import {
  catchError,
  concatMap, debounceTime,
  filter, first,
  map, mergeAll, mergeMap, scan, switchMap, takeUntil, tap, withLatestFrom
} from 'rxjs/operators';
import { StorageAreaItemPanelV2Component } from '../storage-area-item-panel/storage-area-item-panel.component';
import { StorageAreaListV2Component } from '../storage-area-list/storage-area-list.component';

const maxFailCount = 4;
const onlineCheckDelayMS = 60000;
const productCountDebounceTimeMs = 3000;
const EMPTY_VALUATION_PATCH = {} as IDictionary<number[]>;

type InventoryCountPatchLedger = IDictionary<{
  sequence: number;
  value: ProductCountElement;
}>;

@Component({
  selector: 'app-inventory-worksheet',
  templateUrl: './inventory-worksheet.component.html',
  styleUrls: ['./inventory-worksheet.component.scss'],
})
export class InventoryWorksheetV2Component implements
  OnInit,
  OnDestroy,
  AfterViewChecked,
  ComponentCanDeactivate,
  ComponentIsSaving {
  supportedLocales = ['en_CA', 'fr_CA'];

  currentBatchUpdateSequence = +new Date();

  worksheetModel: WorksheetDTO;
  worksheetProductIdFilter: string[] = [];
  isMobile = false;
  isOnline = true;
  storageAreaExpansionState: IDictionary<boolean> = {};
  storageAreaExpansionRestore: IDictionary<boolean> = {};

  get confirmMessage(): string {
    return this.translateService.instant(
      'INVENTORY_WORKSHEET.LEAVE_CONFIRMATION_MODAL'
    );
  }
  destroy$ = new Subject<void>();
  productCountIngress = new Subject<ProductCountElement>();

  uiConfig = {
    showRefreshWorksheetItemsButton: false,
    OfflineModeEnabled: false,
    doneButtonLocalizationData: { value: 0 }

  };
  filteredStorageAreaItemOrder: IDictionary<string[]> = null;
  blurStatusMatches = [
    WorksheetValuationStatusFlags.Pending,
  ];

  editWorksheetNameForm: UntypedFormGroup;
  pricingPermissions = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.pricing;
  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet;

  scrollWidth: string;

  worksheetItemErrors: IDictionary<string[]> = {};
  isSaving$ = new BehaviorSubject(false);

  productValuationStatus: IDictionary<WorksheetValuationStatusFlags> = {};
  worksheetCountLedger: IDictionary<{
    sequence: number,
    value: ProductCountElement
  }> = {};

  useInfiniteScrolling$: Observable<boolean> = this.store.select(
    (state) => state.worksheets.useInfiniteScrolling
  ).pipe(filter(v => v !== undefined));
  useGroupOperations$: Observable<boolean> = this.store.select((state) => state.worksheets?.useGroupOperations
  ).pipe(
    filter(v => v !== undefined),
    map((value) => this.isActiveInventory() ? value : false)
  );
  toggleButtonState: boolean;
  isEditingName$: Observable<boolean> = this.store.select((state) => state.worksheets.isEditingName);

  @ViewChild('editNameInput')
  editNameInput: ElementRef;

  @ViewChild(ItemSearchBarComponent)
  itemSearch: ItemSearchBarComponent;

  @ViewChild(MatSidenavContent)
  storageAreaContent: MatSidenavContent;

  @ViewChild(StorageAreaListV2Component)
  storageAreaList: StorageAreaListV2Component;

  @ViewChild(StorageAreaItemPanelV2Component)
  storageAreasContainer: StorageAreaItemPanelV2Component;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private configService: AppConfigService,
    private overlay: LoadingSpinnerOverlayService,
    private worksheetDataService: WorksheetDataService,
    private worksheetHttp: WorksheetHttpService,
    public cdr: ChangeDetectorRef,
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
  ) {
  }


  isActiveInventory() {
    // if the closed date is populated it should be in a closed state
    return !this.worksheetModel?.header?.closedDate;
  }

  toggleInfiniteScrolling(state) {
    this.store.dispatch(new SetInfiniteScrollingToggleState(state.checked));
  }

  toggleEditMode() {
    this.storageAreasContainer.checkedWorksheetItemsList = [];
    this.storageAreasContainer.storageAreasSelectedAllList = [];
    this.toggleButtonState = !this.toggleButtonState;
    this.store.dispatch(new SetGroupOperationToggleState(this.toggleButtonState));
  }

  createBehaviorDebounceProductCountChange$(
    ingress$: Observable<ProductCountElement>,
    debounceTimeMs: number
  ) {

    return ingress$
      .pipe(
        tap(v => {
          // immediately set the worksheet item, storage area, and worksheet total value
          // to pending since were debouncing the downstream operation
          this.isSaving$.next(true);
          const { storageAreaId, worksheetId } = this.worksheetModel.worksheetItems[v.worksheetItemId];
          this.productValuationStatus[v.worksheetItemId] = WorksheetValuationStatusFlags.Pending;
          this.productValuationStatus[storageAreaId] = WorksheetValuationStatusFlags.Pending;
          this.productValuationStatus[worksheetId] = WorksheetValuationStatusFlags.Pending;

        }),
        scan(
          (
            acc: InventoryCountPatchLedger,
            value: ProductCountElement
          ) => ({
            ...acc,
            [value.worksheetItemId]: {
              sequence: this.currentBatchUpdateSequence,
              value
            }
          } as InventoryCountPatchLedger), {}),
        tap(ledgerState => {
          // because we can potentially have a worksheet item in multiple batches
          // we need to ensure that it gets updated with the expected batch and valuation
          // this will let downstream consumers be aware of the change in expected sequence
          this.worksheetCountLedger = ledgerState;
        }),
        debounceTime(debounceTimeMs),
        mergeMap(ledgerState => {
          this.isSaving$.next(true);
          // /* advance the sequence so we can accumulate the next batch*/
          const currentSequence = this.currentBatchUpdateSequence++;
          console.log(`exec commit product count change: ${currentSequence}`);
          const countBatch = this.createBatchProductCountRequestForSequence(
            ledgerState,
            currentSequence
          );
          return from(this.createBatchUpdateRequest(countBatch));
        }),
        tap(({
          worksheetItemValuations_Patch,
          worksheetItemValuationStatus_Patch,
          worksheetItemErrors,
          valuationTimestamp
        }) => {
          this.applyWorksheetItemValuationState(
            worksheetItemValuations_Patch,
            worksheetItemValuationStatus_Patch,
            worksheetItemErrors,
            valuationTimestamp
          );
        }),
        catchError(
          (err, caught) =>
            caught.pipe(
              map(x => { return true; })
            )
        )
      );
  }

  async createBatchUpdateRequest(
    countBatch: BatchProductCountRequest
  ):
    Promise<{
      worksheetItemValuations_Patch: IDictionary<number[]>;
      worksheetItemValuationStatus_Patch: IDictionary<WorksheetValuationStatusFlags>;
      worksheetItemErrors: IDictionary<string>;
      valuationTimestamp: any
    }> {

    const itemsAwaitingThisSequence: string[] = [];

    try {
      await firstValueFrom(this.appContext.isOnline$.pipe(isTruthy()));
      const {
        worksheetValuations,
        sequence,
        errors,
        valuationTimestamp
      } = await firstValueFrom(
        this.worksheetHttp
          .createUpdateCountRequest(countBatch)
          .pipe(
            retryWhenOnline(
              onlineCheckDelayMS,
              maxFailCount,
              this.appContext.isOnline$
            )
          )
      );

      // items awaiting this batched response
      const itemsAwaitingThisSequence =
        this.getItemsAwaitingSequence(
          sequence,
          this.worksheetCountLedger
        );

      // if there are no items waiting in the next sequence,
      // then we can assume the storage area and worksheet totals are final
      // we'll push the worksheetId and storage areas into the update list
      if (this.getItemsAwaitingSequence(sequence + 1, this.worksheetCountLedger).length === 0) {
        itemsAwaitingThisSequence.push(countBatch.worksheetId);
        itemsAwaitingThisSequence.push(...this.worksheetModel.storageAreaOrder);
      }

      // create the state patch for the sequence
      const worksheetItemValuations_Patch =
        this.createWorksheetItemProductValuationPatch(
          itemsAwaitingThisSequence,
          worksheetValuations
        );


      const worksheetItemValuationStatus_Patch =
        this.createWorksheetValuationProgressPatch(
          itemsAwaitingThisSequence,
          WorksheetValuationStatusFlags.Complete
        );
      return {
        worksheetItemValuations_Patch,
        worksheetItemValuationStatus_Patch,
        worksheetItemErrors: errors,
        valuationTimestamp
      };

    }
    catch (ex) {
      return {
        worksheetItemValuations_Patch: EMPTY_VALUATION_PATCH,
        worksheetItemValuationStatus_Patch: this.createWorksheetValuationProgressPatch(
          itemsAwaitingThisSequence,
          WorksheetValuationStatusFlags.Error
        ),
        worksheetItemErrors: {},
        valuationTimestamp: ''
      };

    }
  }

  async printWorksheet(): Promise<boolean> {
    // ensure that there are no saves in progress
    const savingNotifier = this.getSavingNotifier();

    return firstValueFrom(
      savingNotifier.saveInProgressNotifier$
        .pipe(
          concatMap((isSaveInProgress) => {
            if (isSaveInProgress) {
              this.overlay.show();
            }
            return savingNotifier.onSaveComplete;
          }),
          concatMap(v => {
            return this.router.navigateByUrl(
              `/worksheet/${this.worksheetModel.worksheetId}/(modal:print)`,
              { replaceUrl: true }
            );
          })
        )
    );
  }

  getItemsAwaitingSequence(
    sequenceId: number,
    countResponse: IDictionary<{
      sequence: number;
      value: ProductCountElement;
    }>
  ): string[] {
    const updatedKeys = Object.keys(countResponse);

    const itemsAwaitingThisSequence = updatedKeys
      .filter(
        worksheetItemId =>
          /* there may be another update on the way for this item */
          sequenceId === this.worksheetCountLedger[worksheetItemId].sequence
      );
    return itemsAwaitingThisSequence;
  }

  createBatchProductCountRequestForSequence(
    ledgerState: InventoryCountPatchLedger,
    currentSequence: number
  ) {
    const worksheetItemIds = Object.keys(ledgerState);
    const worksheetItems = worksheetItemIds.map(key => ledgerState[key]);
    const worksheetItemBatch = worksheetItems
      .filter(
        count => count.sequence === currentSequence
      ).map(v => v.value);

    return {
      worksheetId: this.worksheetModel.worksheetId,
      batch: worksheetItemBatch,
      sequence: currentSequence
    } as BatchProductCountRequest;
  }

  applyWorksheetItemValuationState(
    worksheetItemValuations_Patch: IDictionary<number[]>,
    worksheetItemValuationStatus_Patch: IDictionary<WorksheetValuationStatusFlags>,
    worksheetItemErrors: IDictionary<string>,
    valuationTimestamp: any
  ) {

    this.worksheetModel.worksheetItemErrors = worksheetItemErrors;
    this.worksheetModel.worksheetValuations = {
      ...this.worksheetModel.worksheetValuations,
      ...worksheetItemValuations_Patch
    };

    this.worksheetModel.header.lastModifiedDate = valuationTimestamp;
    this.isSaving$.next(false);

    this.productValuationStatus = {
      ...this.productValuationStatus,
      ...worksheetItemValuationStatus_Patch
    } as IDictionary<WorksheetValuationStatusFlags>;


    this.cdr.markForCheck();
  }

  private createWorksheetItemProductValuationPatch(
    itemsAwaitingThisSequence: string[],
    newValuations: IDictionary<number>,
  ): IDictionary<number[]> {
    return itemsAwaitingThisSequence
      .reduce(
        (acc, worksheetItemId) => ({
          ...acc,
          [worksheetItemId]: newValuations[worksheetItemId]
        }),
        {}
      );
  }

  private createWorksheetValuationProgressPatch(
    worksheetItemKeys: string[],
    newStatus: WorksheetValuationStatusFlags
  ): IDictionary<WorksheetValuationStatusFlags> {
    return worksheetItemKeys
      .reduce(
        (acc, worksheetItemId) => ({
          ...acc,
          [worksheetItemId]: newStatus
        }),
        {}
      );
  }

  getValuation(
    key: string,
    index: number = 0,
  ) {
    try {
      const status = this.productValuationStatus[key]
        ?? WorksheetValuationStatusFlags.Complete;
      const valuationSource = this.worksheetModel.worksheetValuations;
      const valuations = valuationSource[key] ?? [null, null];
      const valuation = valuations[index];

      return {
        status,
        value: valuation
      };
    } catch (ex) {
      return {
        status: WorksheetValuationStatusFlags.Error,
        value: null
      };
    }
  }

  isValueBlurred(
    status: WorksheetValuationStatusFlags
  ) {
    return this.blurStatusMatches.indexOf(status) > -1;
  }

  createWorksheetLastModifiedDate$(
    worksheet$: Observable<Worksheet>
  ) {
    return combineLatest([
      worksheet$.pipe(isTruthy(), map(w => w.lastModified)),
      this.store.select(
        (state: any) => state.worksheets.activeWorksheetLastModified as string
      )
    ]).pipe(
      map(r => r.filter(x => !!x).pop()));
  }

  // notify the
  getSavingNotifier() {
    console.log('created saving notifier');
    return {
      // returns a single value of the current state true or false
      saveInProgressNotifier$:
        of(false)
          .pipe(
            map(_ =>
              Object.keys(this.productValuationStatus)
                .map(k => this.productValuationStatus[k])
                .filter(f => f !== WorksheetValuationStatusFlags.Complete),
            ),
            map(v => v.length > 0),
            first()
          ),
      // will poll the app and fire once when the save is completed
      onSaveComplete:
        interval(1000)
          .pipe(
            map(_ =>
              Object.keys(this.productValuationStatus)
                .map(k => this.productValuationStatus[k])
                .filter(f => f !== WorksheetValuationStatusFlags.Complete),
            ),
            filter(v => v.length === 0),
            map(_ => true),
            first(),
          ),
      // the number of pending operations that were waiting for (HOT Observable)
      remainingOperationsNotifier$:
        interval(500)
          .pipe(
            map(_ =>
              Object.keys(this.productValuationStatus)
                .map(k => this.productValuationStatus[k])
                .filter(f => f !== WorksheetValuationStatusFlags.Complete)
                .length
            ),
          )

    };

  }


  flattenLocalizedTextForSearch(localizedObj: Map<string, string>) {
    return this.supportedLocales
      .reduce((prev, localeKey, idx) =>
        `${prev} ${localizedObj[localeKey]}`,
        ''
      );
  }

  async refreshFilteredData() {
    const searchTerm = await firstValueFrom(this.itemSearch.searchTermSubject.asObservable());
    this.onExpandFilter(searchTerm);
  }

  onExpandFilter(
    searchTerm: string,
  ) {
    searchTerm = searchTerm.toLocaleLowerCase();

    if (searchTerm.length === 0) {
      this.filteredStorageAreaItemOrder = this.worksheetModel?.storageAreaItemOrder;
      this.worksheetProductIdFilter = [];
      return;
    }

    // onExpandFilter
    const itemKeys = Object.keys(this.worksheetModel?.inventoryItems);
    if (itemKeys.length > 0) {

      const searchableProducts = itemKeys.map((currItemKey) => {

        const queryableItem = this.worksheetModel?.inventoryItems[currItemKey];
        let searchableText = '';
        if (queryableItem) {

          searchableText = [
            queryableItem?.descriptionLine1,
            queryableItem?.descriptionLine2,
          ]
            .map(v => this.flattenLocalizedTextForSearch(v))
            .reduce((prev, cur) => `${prev}▲${cur}`)
            .toLowerCase();

        }

        return {
          key: currItemKey,
          value: searchableText
        };

      });

      const matchingProductKeys = searchableProducts
        .filter(({ value }) => value.includes(searchTerm))
        .map(({ key }) => key);


      const inclusiveWorksheetItems = this.worksheetModel?.storageAreaOrder
        .map((storageAreaId) => {
          const filteredWorksheetIds = this.worksheetModel?.storageAreaItemOrder[storageAreaId]?.filter(x => {
            return matchingProductKeys.indexOf(this.worksheetModel?.worksheetItems[x].itemId) > -1;
          });

          return { storageAreaId, filteredWorksheetIds };
        });


      this.filteredStorageAreaItemOrder =
        inclusiveWorksheetItems
          .reduce((prev, curr) => {
            return {
              ...prev,
              [curr.storageAreaId]: curr.filteredWorksheetIds
            };
          }, {} as IDictionary<string[]>);

      this.storageAreaExpansionState =
        inclusiveWorksheetItems.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.storageAreaId]: curr.filteredWorksheetIds?.length > 0
          };
        }, {} as IDictionary<boolean>);
    }

    setTimeout(() => {
      this.cdr.markForCheck();
    }, 1);

  }

  async ngOnInit() {
    this.useGroupOperations$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.toggleButtonState = data
    })
    // await the worksheet model to continue loading the component
    this.worksheetModel = await firstValueFrom(
      this.route.data.pipe(
        map(
          ({
            // this sequence is dictated by the order of resolvers for the configured route
            0: worksheet
          }) => {
           this.store.dispatch(new SetPricingPreference(worksheet?.pricingOption ?? ''))
           return worksheet as WorksheetDTO;
          }
        ),
        first(),
      )
    );

    const infiniteScrollSetting = this.configService.getLocalStorageValue(InventoryConstants.infiniteScrollSettingPath, false);
    this.toggleInfiniteScrolling({ checked: infiniteScrollSetting });

    this.uiConfig.showRefreshWorksheetItemsButton =
      this.configService.getSettings().FF_REFRESH_WORKSHEET_ITEMS;

    this.uiConfig.OfflineModeEnabled =
      this.configService.getSettings().FF_OFFLINE_MODE;


    this.initStorageAreaExpansionState();

    combineLatest([
      // this will capture all newly added items and push them into the ui state
      this.createBehaviorOnItemChanged$(WorksheetEffects.itemChangedNotifier$),
      this.createBehaviorGroupItemsChanged$(WorksheetEffects.groupItemMovedNotifier$),

      // refresh searchResults
      this.createBehaviorAutoRefreshSearchResults$(WorksheetEffects.itemDeletedNotifier$),

      // this will handle sync of storage area
      this.createBehaviorOnWorksheetStructureChange$(WorksheetEffects.storageAreaSchemaChange$),

      this.createBehaviorDebounceProductCountChange$(
        this.productCountIngress,
        productCountDebounceTimeMs
      ),
      this.createBehaviorTogglMobileView$(
        this.store.select(v => v.layout.isMobile)
      )
    ])
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe();

    // begin wasteland
    this.ensureResetWorksheetModalIsClosed();

    this.editWorksheetNameForm = this.formBuilder.group({
      name: this.translateName(this.worksheetModel.header.name),
    });

  }

  createBehaviorTogglMobileView$(
    isMobileSouce: Observable<boolean>
  ) {
    return isMobileSouce
      .pipe(
        debounceTime(500),
        tap(newValue => {
          // its trying to toggle the view style
          this.toggleExpandsionStateByDisplayType(this.isMobile, newValue);
          this.isMobile = newValue;
        })
      );
  }

  toggleExpandsionStateByDisplayType(
    isMobileActive: boolean,
    activateMobileView: boolean
  ) {
    if (!isMobileActive && activateMobileView) {
      const expandAll = Object.keys(this.storageAreaExpansionState)
        .reduce((prev, cur) => ({
          ...prev,
          [cur]: true
        }), ({}));
      // from desktop to mobile
      // take a backup of the current exapnsion state
      this.storageAreaExpansionRestore = this.storageAreaExpansionState;
      this.storageAreaExpansionState = expandAll;
    }
    if (this.storageAreaExpansionRestore && isMobileActive && !activateMobileView) {
      // from mobile to desktop and we have a state to restore
      this.storageAreaExpansionState = this.storageAreaExpansionRestore;
    }
  }

  ensureResetWorksheetModalIsClosed() {
    const importGate = this.dialog.getDialogById(InventoryConstants.LOADING_MODAL_ID + '_RESET_WORKSHEET');
    importGate?.close();
  }

  initStorageAreaExpansionState() {
    // expand the first storage area on the first load
    const expandedStorageAreaId = this.getFirstExpandedStorageAreaId(
      this.worksheetModel.storageAreaOrder
    );

    this.storageAreaExpansionState =
      this.worksheetModel.storageAreaOrder
        .reduce((prev, curr, idx, all) => {
          return {
            ...prev,
            [curr]: idx === all.indexOf(expandedStorageAreaId)
          };
        }, {} as IDictionary<boolean>);
  }

  createBehaviorOnWorksheetStructureChange$(
    notifier$: Subject<void>
  ) {
    return notifier$
      .pipe(
        debounceTime(1000),
        tap(v => { 
          this.isSaving$.next(true);
          console.log("OnWorksheetStructureChange:", v) }
          ),
        concatMap(() => {
          this.overlay.show();
          return this.worksheetHttp
            .createWorksheetGetRequest(this.worksheetModel.worksheetId, true)
            .pipe(
              tap(
                updatedWorksheet => {
                  const currentWorksheet = Object.keys(this.worksheetModel.worksheetItems)

                  const worksheetResultKeys = Object.keys(updatedWorksheet.worksheetItems)

                  worksheetResultKeys
                    .forEach(key => {

                      // if the item already exists on teh sheet, preserve the item count
                      if (currentWorksheet.indexOf(key) !== -1) {
                        updatedWorksheet.worksheetItems[key].itemCounts =
                          this.worksheetModel.worksheetItems[key].itemCounts
                      }

                      this.worksheetModel.worksheetItems[key] = updatedWorksheet.worksheetItems[key]
                    })

                  this.worksheetModel.inventoryItems = updatedWorksheet.inventoryItems;
                  this.worksheetModel.storageAreaItemOrder = updatedWorksheet.storageAreaItemOrder;
                  this.worksheetModel.storageAreas = updatedWorksheet.storageAreas;
                  this.worksheetModel.storageAreaOrder = updatedWorksheet.storageAreaOrder;
                  this.worksheetModel.units = updatedWorksheet.units;
                  this.worksheetModel.inventoryValuationModel = updatedWorksheet.inventoryValuationModel;
                  this.worksheetModel.header.lastModifiedDate = updatedWorksheet.header.lastModifiedDate;
                  this.isSaving$.next(false);
                  const updatedValuations = [
                    this.worksheetModel.worksheetId,
                    ...updatedWorksheet.storageAreaOrder
                  ].reduce((prev, currKey) => ({
                    ...prev,
                    [currKey]: updatedWorksheet.worksheetValuations[currKey]
                  }), {});

                  this.worksheetModel.worksheetValuations = {
                    ...this.worksheetModel.worksheetValuations,
                    ...updatedValuations
                  };
                }),
              concatMap(() => from(this.refreshFilteredData())),
              tap(() => {
                this.overlay.hide();
                this.storageAreasContainer.cdr.detectChanges();
              })
            );
        })
      );
  }
  createBehaviorGroupItemsChanged$(
    notifier$: Subject<{
      storageAreaId: string;
      worksheetItemIds: string[];
    }>) {
    return notifier$
      .pipe(
        tap(v => {
          this.isSaving$.next(true);
           console.log("OnGroupItemChanged:", v) 
          }),
        concatMap((event) => {
          this.overlay.show();
          this.cdr.markForCheck();
          return this.worksheetHttp
            .createWorksheetGetRequest(this.worksheetModel.worksheetId, true)
            .pipe(
              withLatestFrom(this.itemSearch.searchTermSubject),
              tap(([
                updatedWorksheet,
                searchTerm
              ]) => {
                const hasWorksheetItem = event.worksheetItemIds.some((item) => !!this.worksheetModel.worksheetItems[item]);
                const currentWorksheetItemStateList: IDictionary<WorksheetItemDTO> = {}
                const updatedWorksheetItemList: IDictionary<WorksheetItemDTO> = {}
                event.worksheetItemIds.forEach((items) => {
                  currentWorksheetItemStateList[items] = this.worksheetModel.worksheetItems[items]
                  updatedWorksheetItemList[items] = updatedWorksheet.worksheetItems[items]
                })
                if (hasWorksheetItem) {
                  event.worksheetItemIds.forEach((items) => {
                    updatedWorksheetItemList[items].itemCounts = currentWorksheetItemStateList[items].itemCounts
                  })
                }

                // merge units for the updated item
                event.worksheetItemIds.forEach((item) => {
                  updatedWorksheetItemList[item].configuredCountingUnits
                    .map(unitKey => ({ unitKey, value: updatedWorksheet.units[unitKey] }))
                    .forEach(v => {
                      this.worksheetModel.units[v.unitKey] = v.value
                    })
                  // append the worksheet info to the collection
                  this.worksheetModel.inventoryItems[updatedWorksheetItemList[item].itemId] =
                    updatedWorksheet.inventoryItems[updatedWorksheetItemList[item].itemId];

                  // append the worksheet info to the collection
                  this.worksheetModel.worksheetItems[item] = updatedWorksheetItemList[item];
                })
                // link the worksheet item to the storage area
                this.worksheetModel.storageAreaItemOrder = updatedWorksheet.storageAreaItemOrder;
                this.worksheetModel.inventoryValuationModel = updatedWorksheet.inventoryValuationModel;
                this.worksheetModel.header.lastModifiedDate = updatedWorksheet.header.lastModifiedDate;
                this.isSaving$.next(false);

                this.onExpandFilter(searchTerm);

                this.overlay.hide();

                this.storageAreasContainer
                  .scrollContainers
                  .forEach(v => {
                    v.resetData();
                  });


              }));
        })

      );
  }

  createBehaviorAutoRefreshSearchResults$(
    notifier$: Subject<{
      storageAreaId: string;
      worksheetItemId: string;
    }>
  ) {
    // this will cause the item result index to get refreshed
    // automatically when and item is moved or deleted
    return merge([
      notifier$
    ])
      .pipe(
        mergeAll(),
        map(async (_) => {
          const searchTerm = await firstValueFrom(this.itemSearch.searchTermSubject);
          this.onExpandFilter(searchTerm);
          setTimeout(() => {
            this.cdr.markForCheck();
            this.overlay.hide();
          }, 1);
          return true;
        })
      );
  }

  createBehaviorOnItemChanged$(
    notifier$: Subject<{
      storageAreaId: string;
      worksheetItemId: string;
    }>) {
    // this is required in order for some of the old code base (add/edit items)
    // to notify the new ui to refresh
    return notifier$
      .pipe(
        tap(v => { 
          this.isSaving$.next(true);
          console.log("OnItemChanged:", v) 
        }),
        concatMap((event) => {
          this.overlay.show();
          this.cdr.markForCheck();
          return this.worksheetHttp
            .createWorksheetGetRequest(this.worksheetModel.worksheetId, true)
            .pipe(
              withLatestFrom(this.itemSearch.searchTermSubject),
              tap(([
                updatedWorksheet,
                searchTerm
              ]) => {

                const hasWorksheetItem = !!this.worksheetModel.worksheetItems[event.worksheetItemId];

                const currentWorksheetItemState = this.worksheetModel.worksheetItems[event.worksheetItemId];
                const updatedWorksheetItem = updatedWorksheet.worksheetItems[event.worksheetItemId];

                if (hasWorksheetItem) {
                  // apply item counts to update so state isn't lost
                  updatedWorksheetItem.itemCounts = currentWorksheetItemState.itemCounts;
                }

                // merge units for the updated item
                updatedWorksheetItem.configuredCountingUnits
                  .map(unitKey => ({ unitKey, value: updatedWorksheet.units[unitKey] }))
                  .forEach(v => {
                    this.worksheetModel.units[v.unitKey] = v.value
                  })

                // append the product info to the collection
                this.worksheetModel.inventoryItems[updatedWorksheetItem.itemId] =
                  updatedWorksheet.inventoryItems[updatedWorksheetItem.itemId];

                // append the worksheet info to the collection
                this.worksheetModel.worksheetItems[event.worksheetItemId] = updatedWorksheetItem;

                // link the worksheet item to the storage area
                this.worksheetModel.storageAreaItemOrder = updatedWorksheet.storageAreaItemOrder;
                this.worksheetModel.inventoryValuationModel = updatedWorksheet.inventoryValuationModel;
                this.worksheetModel.header.lastModifiedDate = updatedWorksheet.header.lastModifiedDate;
                this.isSaving$.next(false);

                this.onExpandFilter(searchTerm);

                this.overlay.hide();

                this.storageAreasContainer
                  .scrollContainers
                  .forEach(v => {
                    v.resetData();
                  });


              }));
        })

      );
  }

  getFirstExpandedStorageAreaId(
    orderedStorageAreas: string[]
  ) {

    const rr = orderedStorageAreas
      // try to expand the first storage area containing items
      .filter((storageAreaId) => {
        return this.worksheetModel.storageAreaItemOrder[storageAreaId]?.length > 0;
      });


    return rr.find((_, idx, all) => { return idx === 0; });
  }

  ngAfterViewChecked() {
    if (!!this.storageAreaContent && !this.scrollWidth) {
      const nativeElement = this.storageAreaContent.getElementRef()
        .nativeElement;
      if (nativeElement.scrollHeight > nativeElement.offsetHeight) {
        const normalw = nativeElement.offsetWidth;
        const scrollw = normalw - nativeElement.clientWidth;
        setTimeout(() => {
          this.scrollWidth = 32 - scrollw + 'px';
        }, 100);
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.store.dispatch(new SetGroupOperationToggleState(false));
  }

  canDeactivate(): Observable<boolean> {
    if (this.uiConfig.OfflineModeEnabled) {
      return this.appContext.isOnline$
        .pipe(
          first(),
          concatMap((isOnline) => {
            if (isOnline) {
              return this.getSavingNotifier().onSaveComplete;
            }
            return of(false);
          })
        );
    }
    return of(true);
  }

  // if using the default name, translate it between english and french
  translateName(name: string): string {
    if (
      name.toLowerCase() === 'Untitled Inventory'.toLowerCase() ||
      name.toLowerCase() === `Inventaire sans titre`.toLowerCase()
    ) {
      return this.translateService.instant(
        'INVENTORY_WORKSHEET.UNTITLED_INVENTORY'
      );
    }
    return name;
  }

  editName() {
    const name = this.translateName(this.worksheetModel.header.name);

    this.editWorksheetNameForm.setValue({ ['name']: name });
    this.store.dispatch(new SetIsEditingName(true));

    setTimeout(() => {
      this.editNameInput.nativeElement.focus();
    }, 1);

  }

  saveName() {

    const sheetId = this.worksheetModel.header.worksheetId;
    const name = this.translateName(this.editWorksheetNameForm.get('name')?.value ?? 'INVENTORY_WORKSHEET.UNTITLED_INVENTORY');

    this.store.dispatch(
      new PatchWorksheetAttempt({
        worksheetId: sheetId,
        fields: { name },
      })
    );
    this.worksheetModel.header.lastModifiedDate = new Date();
    this.worksheetModel.header.name = name;
  }

  async refreshWorksheetItems(): Promise<void> {
    this.worksheetDataService.refreshWorksheetItems();
  }

  canShowTotals(): boolean {
    return this.configService.getSettings().FF_WORKSHEET_TOTALS
  }

}