import { RecipeIngredient } from './product.model';
import { CustomerPK } from './user.model';

export type LocalizedMeasurementUnitOptionGroupx = {
  name: string;
  groupType: string;
  createPromptTexti18n?: string;
  options: {
    name: string;
    key: string;
  }[];
};

export interface RecipeCategory {
  id: string;
  name: string;
  customerPK: CustomerPK;
  expandStatus: boolean;
  ordinal?: number;
  deleted?:boolean;
}

export interface CategoryOrder {
  id: string;
  customerPK: CustomerPK;
  order: string[];
}

export interface Recipe {
  id?: string;
  name?: string;
  customerPK: CustomerPK;
  description?: string;
  categoryId?: string;
  ingredients?: RecipeIngredient[];
  ingredientOrder?: string[];
  details: {
    menu: any;
    batch: BatchRecipeDetails;
  };
  recipePrice?: RecipePrice;
  calculationConfig?: {
    constantType: RecipePriceType;
    constantValue: string;
    calculationInProgress?: boolean;
  };
  preparationsDetails?: {
    cookTime?: Duration; // time required to cook recipe
    prepTime?: Duration; // time required to prepare ingredients
    prepInstructions?: string; // preparation instructions
  };
  recipeImageSignedUrls?: ImageData[];
  images?: {
    [k: string]: UIRecipeImage;
  };
  preventAutomatedPricingCalculation?: boolean;
  subType?: 'menuItem' | 'batch';
  itemType?: 'RECIPE' | 'BATCHRECIPE' | 'MENUITEMRECIPE';
  deleted?: boolean;
}

export interface ImageData {
  resourceKey?: string;
  resourceURI?: string;
  imageDataBase64?: string;
}

export interface RecipeImagePostModel {
  key?: string;
  content: string;
  ordinal: number;
  isNew?: boolean;
  isDeleted?: boolean;
  file?: File;
}



export interface UIRecipeImage {
  key?: string;
  content: string;
  isNew?: boolean;
  isUpdated?: boolean;
  isDeleted?: boolean;
  ordinal: number;
  index: number;
  file?: File;
}

export interface Duration {
  hours: string;
  minutes: string;
}

interface BatchRecipeDetails {
  totalYieldUnit?: string;
  totalYieldQty?: string;
}

export interface RecipePrice {
  recipeId: string;
  customerPK: CustomerPK;
  foodCostPercent: number;
  menuPrice: number;
  totalCost: number;
  margin: number ;
  ingredientPrices: RecipeIngredientPrice[];
  lastUpdated: string;
  calculationConfig?: {
    constantType: RecipePriceType;
    constantValue: string;
    calculationInProgress?: boolean;
  };
}

export interface RecipeIngredientPrice {
  ingredientType: IngredientType;
  ingredientId: string;
  purchasePrice: number;
  portionPrice: number;
}

export interface RecipePriceRequest {
  customerPK: CustomerPK;
  ingredients: RecipeIngredientPriceRequest[];
  calculationConstantType: RecipePriceType;
  calculationConstantValue: string;
}

export interface RecipeIngredientPriceRequest {
  ingredientType: IngredientType;
  ingredientId: string;
  unit: string;
  quantity: number;
  yieldPercent: string;
}

export enum RecipePriceType {
  FOOD_COST_PERCENT = 'FOOD_COST_PERCENT',
  MARGIN = 'MARGIN',
  MENU_PRICE = 'MENU_PRICE',
  DEFAULT = 'MENU_PRICE',
}

export enum IngredientType {
  GFS = 'GFS',
  GENERAL = 'GENERAL',
  RECIPE = 'RECIPE',
}

export interface RecipePriceRequest {
  customerPK: CustomerPK;
  ingredients: RecipeIngredientPriceRequest[];
  calculationConstantType: RecipePriceType;
  calculationConstantValue: string;
}
