import { Action } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { AddToCartPayload, Cart, CustomerPK } from '@gfs/shared-models';

export enum ActionTypes {
  GetCartDataAttempt = '[Inventory] Get Cart Data Attempt',
  GetCartDataSuccess = '[Inventory] Get Cart Data Success',
  GetCartDataError = '[Inventory] Get Cart Data Error',
  AddToCartAttempt = '[Recipe] Add indredient to cart',

}

export class GetCartDataAttempt implements Action {
  readonly type = ActionTypes.GetCartDataAttempt;
  constructor(public payload: { customerPK: CustomerPK, includePrice: boolean }) { }
}

export class GetCartDataSuccess implements Action {
  readonly type = ActionTypes.GetCartDataSuccess;
  constructor(public payload: { cart: Cart, customerId: string ,itemId :string}) { }
}

export class GetCartDataError implements Action {
  readonly type = ActionTypes.GetCartDataError;
  constructor(public payload: HttpErrorResponse) { }
}
export class AddToCartAttempt implements Action {
  readonly type = ActionTypes.AddToCartAttempt;
  constructor(public payload : {addToCartData : AddToCartPayload}) { }

}

export type ActionUnion =
  | GetCartDataAttempt
  | GetCartDataSuccess
  | GetCartDataError 
  | AddToCartAttempt
