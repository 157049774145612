import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { InventoryConstants } from '@gfs/constants';
import { CountableItem, CustomItemData, Entitlement, IDictionary, MeasurementUnit, PortioningUnit } from '@gfs/shared-models';
import { CustomUnitUtilsService, InjectionTokens, LocalizedValueUtilsService, MeasurementUnitService, UnitConversionUtilService } from '@gfs/shared-services';
import { WorksheetItemService } from '@gfs/shared-services/services/worksheet-item.service';
import { ClearCustomItemData, IAddItemsFeatureBridge, IFeatureStateFacade } from '@gfs/store/feature/add-items';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UnitsHttpService } from 'libs/shared-services/src/v2/services/unit/unit-http-service.service';
import { get } from 'lodash-es';
import { Observable, Subject, combineLatest, firstValueFrom, forkJoin, of } from 'rxjs';
import { concatMap, filter, first, map, takeUntil, tap } from 'rxjs/operators';
import * as uuid from 'uuid';

interface MappedCountingUnit {
  id: string;
  name: string;
  type: string;
  qtyInParent: string;
  parentUnitId?: string;
  deleted?: boolean;
  isUserManagable?: boolean;
  displayCode?: string;
}
const reflow = {
  "KG": InventoryConstants.STANDARD_WEIGHT_UNIT_KG,
  "LB": InventoryConstants.STANDARD_WEIGHT_UNIT_LB,
  "UN": "UNIT",
}
@Component({
  selector: 'gfs-counting-unit-form',
  templateUrl: './counting-unit-form.component.html',
  styleUrls: ['./counting-unit-form.component.scss']
})
export class CountingUnitFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isEdit: boolean;
  @Input() item: CountableItem;
  @Input() isAllowedToEdit = true;
  @Input() isgfsItem = false;
  @Output() addOrEditItem = new EventEmitter<any>();
  @Output() purchasedByUnitCheck = new EventEmitter<boolean>();
  currentLang$ = this.addItemsCtx.currentLang$;
  allEntitlements$ = this.appStore.select(appStore => appStore.auth.entitlements);
  isInventoryRole$: Observable<boolean>;
  customItemData$ = this.addItemsCtx.customItemData$.pipe(map(customItemData => {
    const data = customItemData?.find(d => {
      if (this.item?.gfsItem) {
        return d.itemId === this.item?.gfsItem?.id && d.itemType === 'GFS';
      } else if (this.item?.customItem) {
        return d.itemId === this.item?.customItem?.id && d.itemType === 'CUSTOM';
      }
      return null;
    });
    return data ? [data] : [];
  }));

  measurementUnits$ = this.store.select((state: any) => state.addItemsFeature.measurementUnits).pipe(
    map(units => (units ?? []).filter(unit => {
      if (unit.displayCategories && unit.displayCategories.length > 0) {
        const countingUnit = unit.displayCategories.find(category => category === 'inventoryWorksheetCountingUnit');
        if (countingUnit) {
          return true;
        }
      }
      return false;
    }))
  );
  countingUnitForm: UntypedFormGroup;
  countingUnitList: MappedCountingUnit[] = [];
  numberOfNonCustomUnits: number;
  purchaseUnit: string = this.translate.instant('ADD_ITEMS.CASE');
  isPurchaseByUnit = false;
  qtyRegex = InventoryConstants.ITEM_QTY_REGEX;
  addDefaultUnit = true;

  @ViewChildren('nameInput') countingUnitNames: QueryList<ElementRef>;
  @ViewChildren('qtyInput') countingUnitQty: QueryList<ElementRef>;

  destructionNotifier = new Subject<void>();
  unitTranslations: IDictionary<IDictionary<string>>;
  lang: string;
  parentPurchaseUnit: string;
  isMobile$: Observable<boolean> = this.store.select((state: AppState) => state.layout.isMobile);

  constructor(
    private translate: TranslateService,
    private localizedValueUtils: LocalizedValueUtilsService,
    private store: Store<IFeatureStateFacade>,
    private formBuilder: UntypedFormBuilder,
    private unitConversionUtilService: UnitConversionUtilService,
    private customUnitUtils: CustomUnitUtilsService,
    private appStore: Store<AppState>,
    public worksheetItemService: WorksheetItemService,
    public worksheetItemService2: MeasurementUnitService,
    public unitsHttpService: UnitsHttpService,
    @Inject(InjectionTokens.IAddItemsFeatureBridge) private addItemsCtx: IAddItemsFeatureBridge,

  ) {


  }

  async ngOnInit(): Promise<void> {

    this.lang = await firstValueFrom(this.addItemsCtx.currentLang$);
    const sapUnits = (await firstValueFrom(this.unitsHttpService.getCurrentEntitlementUOMs$()))
      .single.reduce((p, c) => ({
        ...p,
        [c.type]: c.name.reduce((p1, c1) => ({
          ...p1,
          [c1.languageCode]: c1.value
        }), {})
      }), {})

    const literalCountingUnits =
      (await firstValueFrom(this.appStore.select(v => v.worksheets.countingUnits)))
        .filter(v => !!v.literalUnit)
        .reduce((prev, j) => ({
          ...prev,
          [j.unit.unitId]: j.literalUnit.name.reduce((p, c) => ({ ...p, [c.languageCode]: c.value }), {})
        }), {});

    this.unitTranslations =
      await firstValueFrom(
        this.addItemsCtx.pk$
          .pipe(
            concatMap(customerPk =>
              this.worksheetItemService2.getMeasurementUnits(customerPk)
            ),
            map(v => v.reduce((prev, curr) => ({
              ...prev,
              [curr.id]: curr.name.reduce((p, c) => ({ ...p, [c.languageCode]: c.value }), {})
            }), {})),
            map(standardUnits => ({
              ...literalCountingUnits,
              ...standardUnits,
              ...sapUnits,
            })),
          )
      )

    this.isInventoryRole$ = this.allEntitlements$.pipe(
      filter((perms) => !!perms),
      first(),
      map(perms => this.hasRole(perms, InventoryConstants.INVENTORY_OKTA_ROLES)));
    this.countingUnitForm = this.formBuilder.group({
      countingUnits: this.formBuilder.array([])
    });
    this.createCountingUnitList();
    this.isInventoryRole$
      .pipe(
        first(),
        takeUntil(this.destructionNotifier)
      )
      .subscribe((role) => {
        if (role && this.addDefaultUnit && this.countingUnitFormArray.length === 0) {
          this.addCountingUnitGroup(this.purchaseUnit, '1', false, false);
        }
      });
  }

  hasRole(perms: Entitlement[], role: string): boolean {
    return !!perms.find(entitlement => {
      if (entitlement.roleName.indexOf(role) > -1) {
        return true;
      }
      return false;
    });
  }

  ngAfterViewInit() {
    this.countingUnitNames.changes
      .pipe(takeUntil(this.destructionNotifier))
      .subscribe(elements => {
        if (elements.length > this.countingUnitList.length) {
          elements.last.nativeElement.focus();
        }
      });

    this.countingUnitQty.changes
      .pipe(takeUntil(this.destructionNotifier))
      .subscribe(elements => {
        if (elements.length > this.countingUnitList.length) {
        }
      });
  }

  ngOnDestroy() {
    this.destructionNotifier.next();
    this.store.dispatch(new ClearCustomItemData());
  }

  getCountingUnitIsDeleted(formGroup: number): boolean {
    return this.customUnitUtils.getCountingUnitIsDeleted(formGroup, this.countingUnitForm).value;
  }

  onCountingUnitNameBlur(formGroup: number) {
    this.customUnitUtils.trimCountingUnitName(formGroup, this.countingUnitForm);
    this.customUnitUtils.validateCountingUnitFormArray(this.countingUnitForm);
  }

  get countingUnitFormArray(): UntypedFormArray {
    return this.customUnitUtils.getCountingUnitFormArray(this.countingUnitForm);
  }

  getCountingUnitName(formGroup: number): AbstractControl {
    return this.customUnitUtils.getCountingUnitName(formGroup, this.countingUnitForm);
  }

  getCountingUnitQty(formGroup: number): AbstractControl {
    return this.customUnitUtils.getCountingUnitQty(formGroup, this.countingUnitForm);
  }

  isUserManagableUnit(formGroup: number): boolean {
    return this.customUnitUtils.isUserManagable(formGroup, this.countingUnitForm);
  }

  getCountingUnitParent(formGroup): AbstractControl {
    return this.customUnitUtils.getCountingUnitParent(formGroup, this.countingUnitForm);
  }

  addCountingUnitGroup(
    name: string,
    qty: string,
    isDeleted: boolean,
    isUserManagable: boolean,
    overridePurchaseUnitDisplayText?: string
  ) {
    this.customUnitUtils.addCountingUnitGroup(
      this.countingUnitForm,
      name,
      qty,
      overridePurchaseUnitDisplayText ?? this.parentPurchaseUnit ?? this.purchaseUnit,
      isUserManagable && this.isAllowedToEdit,
      isDeleted
    );
  }

  deleteCountingUnitGroup(formGroup: number) {
    this.customUnitUtils.deleteCountingUnitGroup(formGroup, this.countingUnitForm);
    this.customUnitUtils.validateCountingUnitFormArray(this.countingUnitForm);
  }

  getUnitQtyPlaceholder(formGroup: number) {
    return this.customUnitUtils.getCustomUnitQtyPlaceholder(formGroup, this.countingUnitForm);
  }

  shouldShowPurchaseByUnit(unitIndex: number) {
    if (!this.isUserManagableUnit(unitIndex)) { return false; }
    const isGfsItemWithUnitUom = [
      this.item?.gfsItem,
      this.countingUnitList[unitIndex]?.id === InventoryConstants.LITERAL_UOM_UNIT.toUpperCase()
    ].every(isTrue => isTrue);
    if (isGfsItemWithUnitUom) {
      const casePrice = +this.item.gfsItem.price.casePrice;
      const unitPrice = +this.item.gfsItem.price.unitPrice;
      if (unitPrice && casePrice) {
        const unitQty = +this.item.gfsItem.qtyPerMasterSellUnit;
        const unitPricePerCase = +(casePrice / unitQty).toFixed(2);
        return unitPrice !== 0 && unitPricePerCase !== unitPrice;
      }
    }
    return false;
  }

  selectPurchaseByUnit(event: MatCheckboxChange) {
    this.isPurchaseByUnit = event.checked;
    this.purchasedByUnitCheck.emit(event.checked);
  }

  mapPortioningUnitsToCountingUnits(portioningUnits: PortioningUnit[]): MappedCountingUnit[] {
    return (portioningUnits ?? []).map(unit => {
      if (unit.custom) {
        return {
          id: unit.custom.id,
          name: unit.custom.name,
          type: InventoryConstants.COUNTING_UNIT_TYPES.CUSTOM,
          qtyInParent: unit.custom.qtyInParent,
          parentUnitId: unit.custom.parentUnitId,
          deleted: unit.custom.deleted,
          isUserManagable: true,
        };
      }
      // we shouldn't need to check if unit.standard exists because we do not currently support adding standard units to CustomItemData
    });
  }

  getStandardUnitDisplayName(unit: MeasurementUnit, lang: string) {
    return this.localizedValueUtils.getLocalizedMeasurementUnitName(unit, lang);
  }

  hasEditedCountingUnit(): Observable<boolean> {
    return this.customItemData$.pipe(
      map(itemData => {
        if (!itemData) {
          return false;
        }
        if (itemData.length === 0) {
          return this.countingUnitFormArray.length - this.numberOfNonCustomUnits > 0 || this.isPurchaseByUnit;
        }
        if (itemData[0].countingUnits.length !== this.countingUnitFormArray.length - this.numberOfNonCustomUnits ||
          itemData[0].purchasedByUnit !== this.isPurchaseByUnit) {
          return true;
        } else {
          for (let i = 0; i < itemData[0].countingUnits.length; i++) {
            if (itemData[0].countingUnits[i].custom.name !== this.getCountingUnitName(this.numberOfNonCustomUnits + i).value ||
              itemData[0].countingUnits[i].custom.qtyInParent !== this.getCountingUnitQty(this.numberOfNonCustomUnits + i).value ||
              itemData[0].countingUnits[i].custom.deleted !== this.getCountingUnitIsDeleted(this.numberOfNonCustomUnits + i)) {
              return true;
            }
          }
          return false;
        }
      })
    );
  }

  createCountingUnitList() {
    combineLatest([
      this.customItemData$,
      this.measurementUnits$,
      this.currentLang$,
      of(this.item)
    ])
      .pipe(
        filter(([itemData, measUnits, lang, item]) => !!lang && !!itemData && measUnits.length > 0 && !!item),
        first(),
        tap(([itemData, measUnits, lang]) => {
          const isPrimaryUnitEach = this.worksheetItemService.isPrimaryUnitEach(this.item.gfsItem);
          this.countingUnitList = [];
          
          if (this.item.gfsItem) {
            const hasEachLikeUnit = this.hasEachLikeUnit(this.item.gfsItem.units)
            const caselikeUnit =
              this.item.gfsItem.units
                .find(v => ["CASE", "CS"]
                  .indexOf(v.standardUnitId.toUpperCase()) > -1)
            const caseLikeTransform = { [caselikeUnit.standardUnitId]: caselikeUnit.displayCode }

            this.parentPurchaseUnit = this.unitTranslations[this.item.gfsItem.units
              .find(v => v.parentUom?.length === 0).displayCode]?.[lang] ??
              this.item.gfsItem.units
              .find(v => v.parentUom?.length === 0).displayCode

            this.item.gfsItem.units
              .filter(v => v.parentUom?.length > 0)

              .map<MappedCountingUnit>(x => ({
                id: x.standardUnitId,
                name: x.displayCode,
                type: InventoryConstants.COUNTING_UNIT_TYPES.STANDARD,
                qtyInParent: get(x, 'qtyInParent', '').toString(),
                deleted: false,
                isUserManagable: false,
                parentUnitId:
                  caseLikeTransform[x.parentUnitId] ??
                  this.unitTranslations[this.item.gfsItem.units.find(v => v.standardUnitId == x.parentUnitId).displayCode]?.[lang] ??
                  this.item.gfsItem.units.find(v => v.parentUom?.length === 0).displayCode ??
                  x.parentUnitId
              }))
              .map<MappedCountingUnit>(v => ({
                ...v,
                name: reflow[v.name] ?? v.name
              }))
              .map<MappedCountingUnit>(x => {
                return ({
                  ...x,
                  name: (this.unitTranslations[x.name]?.[lang] ?? x.name),
                  parentUnitId: this.unitTranslations[x.parentUnitId]?.[lang] ?? x.parentUnitId,
                });
              })
              .map<MappedCountingUnit>(v => ({
                ...v,
                name: capitalizeFirstChar(v.name)
              }))
              .forEach(v => this.countingUnitList.push(v))

            const ignoreStandardSecondaryUnit = this.worksheetItemService.ignoreSmallestUnit(this.item.gfsItem);

            measUnits.forEach(mUnit => {
              const qty = mUnit.id === 'metric_weight_kg' ?
                this.item.gfsItem.netWeightKg.toString() :
                this.convertToPounds(this.item).toString();

              this.countingUnitList.forEach((unit, index) => {
                if (unit.name === this.getStandardUnitDisplayName(mUnit, lang)) this.countingUnitList.splice(index, 1);
              });

              this.countingUnitList.push({
                id: mUnit.id,
                name: this.getStandardUnitDisplayName(mUnit, lang),
                type: InventoryConstants.COUNTING_UNIT_TYPES.STANDARD,
                qtyInParent: qty,
                deleted: false,
                isUserManagable: false

              });
            });
          }

          this.numberOfNonCustomUnits = this.countingUnitList.length;

          if (itemData.length > 0) {
            // map units to work better with autocomplete
            this.countingUnitList.push(
              ...this.mapPortioningUnitsToCountingUnits(itemData[0].countingUnits)
            );
            this.isPurchaseByUnit = !!itemData[0].purchasedByUnit;
          }

          this.countingUnitList.forEach((countingUnit) => {
            this.addCountingUnitGroup(
              countingUnit.name,
              countingUnit.qtyInParent,
              countingUnit.deleted,
              countingUnit.isUserManagable,
              countingUnit.parentUnitId ?? this.parentPurchaseUnit

            );
          });
        }),
        takeUntil(this.destructionNotifier)
      ).subscribe();
  }

  hasEachLikeUnit(units: MeasurementUnit[]): Boolean {
    return !!units?.find(v => ["EA", "EACH"].indexOf(v.standardUnitId?.toUpperCase()) > -1)
  }

  private convertToPounds(itemDetails: CountableItem): number {
    const fromQty = itemDetails.gfsItem.netWeightKg;
    const fromUnit = this.unitConversionUtilService
      .getConversionUnit(InventoryConstants.UNIT_TYPE_STANDARD, InventoryConstants.STANDARD_WEIGHT_UNIT_KG, null);
    const toUnit = this.unitConversionUtilService
      .getConversionUnit(InventoryConstants.UNIT_TYPE_STANDARD, InventoryConstants.STANDARD_WEIGHT_UNIT_LB, null);
    const toQty = this.unitConversionUtilService.convertMeasurementUnit(fromQty, fromUnit, toUnit);
    return Number(toQty.toFixed(2));
  }

  createOrUpdateCustomUnits(isEditItem: boolean) {
    forkJoin([
      this.customItemData$.pipe(first()),
      this.hasEditedCountingUnit().pipe(first())
    ])
      .pipe(
        tap(([itemData, hasEdited]) => {
          const nonCustomUnitsCount = this.numberOfNonCustomUnits;
          const controls = this.countingUnitFormArray.controls.slice(nonCustomUnitsCount);

          const portioningUnits: PortioningUnit[] = controls
            .map((control, index) => ({
              custom: {
                id: this.countingUnitList[nonCustomUnitsCount + index]?.id ?? uuid.v4(),
                name: control.get('unitName').value,
                parentUnitId: null,
                qtyInParent: control.get('unitQty').value,
                deleted: control.get('unitDeleted').value
              },
              standard: null
            }));

          const hasCustomUnits = this.hasCustomUnits(itemData, portioningUnits, 
            this.isPurchaseByUnit, hasEdited, isEditItem);
          const customUnits = hasCustomUnits ? portioningUnits : null;
          this.addOrEditItem.emit({
            customUnits,
            purchasedByUnit: this.isPurchaseByUnit
          });
        }),
        takeUntil(this.destructionNotifier)
      )
      .subscribe();
  }


  public hasCustomUnits(
    itemData: CustomItemData[],
    portioningUnits: PortioningUnit[],
    isPurchaseByUnit: boolean,
    hasEdited: boolean,
    isEditItem: boolean
  ): boolean {
    return (itemData.length === 0 && (portioningUnits.length > 0 || isPurchaseByUnit)) 
    || hasEdited
    || (itemData.length !== 0 && !isEditItem);
  }
}

export function capitalizeFirstChar(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
