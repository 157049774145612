import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { CountingUnit, CustomItemData, LiteralUnit, StorageArea, Worksheet, WorksheetItem, WorksheetItemChange, WorksheetItemLastModified, WorksheetLastModified } from '@gfs/shared-models';
import { AppConfigService, IGlobalDialogsService, InjectionTokens, WINDOW } from '@gfs/shared-services';
import { isTruthy, toEntityArray } from '@gfs/shared-services/extensions/rxjs';
import { IdPAuthorize, SetCustomerPK } from '@gfs/store/common';
import { ActionTypes as AddItemsActionTypes } from '@gfs/store/feature/add-items';
import { UpdateCustomItemPriceSuccess, ActionTypes as customerItemActionTypes } from '@gfs/store/inventory/actions/customerItems.actions';
import { UpdateGeneralItemsSuccess, ActionTypes as inventoryItemActionTypes } from '@gfs/store/inventory/actions/inventoryItems.actions';
import { Actions, act, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OKTA_CONFIG, OktaConfig } from '@okta/okta-angular';
import { Observable, Subject, forkJoin, from, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CustomItemDataService } from '../../../lib/services/custom-item-data.service';
import { InventoryValueService } from '../../../lib/services/inventory-value.service';
import { InventoryService } from '../../../lib/services/inventory.service';
import { MessageService } from '../../../lib/services/message.service';
import { WorksheetService } from '../../../lib/services/worksheet.service';
import { NavigateHome } from '../actions/inventory.actions';
import { GetGeneralItemsAttempt, GetInventoryItemsAttempt } from '../actions/inventoryItems.actions';
import {
  ActionTypes,
  ActionUnion,
  CloseWorksheetError,
  CopyWorksheetError,
  CopyWorksheetSuccess,
  CreateBlankWorksheetError,
  CreateBlankWorksheetSuccess,
  CreateGroupWorksheetItemsError,
  CreateGroupWorksheetItemsSuccess,
  CreateStorageAreaNameSuccess,
  CreateWorksheetItemError,
  CreateWorksheetItemSuccess,
  DeleteInventoryItemAttempt,
  DeleteInventoryItemError,
  DeleteInventoryItemSuccess,
  DeleteStorageAreaError,
  DeleteStorageAreaSuccess,
  DeleteWorksheetError,
  DeleteWorksheetSuccess,
  GetAllCustomItemDataError,
  GetAllCustomItemDataSuccess,
  GetAllInventoryItemsAttempt,
  GetCountingUnitsSuccess,
  GetCustomGuidesError,
  GetCustomGuidesSuccess,
  GetWorkSheetFromSummaryAttempt,
  GetWorkSheetFromSummarySuccess,
  GetWorksheetAttempt,
  GetWorksheetError,
  GetWorksheetSuccess,
  GetWorksheetSummaryAttempt,
  GetWorksheetSummaryError,
  GetWorksheetSummarySuccess,
  ImportWorksheetFromCustomGuideError,
  ImportWorksheetFromCustomGuideSuccess,
  ImportWorksheetFromOrderGuideError,
  ImportWorksheetFromOrderGuideSuccess,
  MoveWorksheetItemToStorageArea,
  PatchStorageAreaSuccess, PatchWorksheetError,
  PatchWorksheetSuccess,
  RefreshWorksheetItemsComplete,
  RefreshWorksheetItemsError,
  ResetWorksheetError,
  ResetWorksheetSuccess,
  SetIsEditingName,
  SetStorageAreaExpandStatus, SetWorksheetLastModifiedDate, UpdateWorksheetItem, UpdateWorksheetItemError,
  UpdateWorksheetItemSuccess
} from '../actions/worksheets.actions';
import { AppState } from '../reducers';

@Injectable()
export class WorksheetEffects {

  static itemChangedNotifier$ = new Subject<{ storageAreaId: string, worksheetItemId: string }>();
  static itemDeletedNotifier$ = new Subject<{ storageAreaId: string, worksheetItemId: string }>();
  static groupItemMovedNotifier$ = new Subject<{ storageAreaId: string, worksheetItemIds: string[] }>();
  static itemMovedNotifier$ = new Subject<{ storageAreaId: string, worksheetItemId: string }>();
  static itemUpdateInProgress$= new Subject<void>();
  static storageAreaSchemaChange$ = new Subject<void>();
  static itemSearchFilterText$ = new Subject<string>();


  worksheetUpdateCooldown = 1000;
  worksheetUpdateMaxRetryCount = 4;


  constructor(
    private actions$: Actions<ActionUnion>,
    private store$: Store<AppState>,
    private worksheetService: WorksheetService,
    private inventoryService: InventoryService,
    private messageService: MessageService,
    private router: Router,
    private translate: TranslateService,
    @Inject(InjectionTokens.IGlobalDialogService) private globalDialogsService: IGlobalDialogsService,
    private inventoryValueService: InventoryValueService,
    private customItemDataService: CustomItemDataService,
    @Inject(WINDOW) private windowRef: Window,
    @Inject(OKTA_CONFIG) public oktaConfig: OktaConfig,
    private appConfig: AppConfigService
  ) { }

  latestCustomerPk$ = this.store$
    .select(state => state.auth.pk)
    .pipe(
      filter(pk => !!pk),
      first(),
    );


  getWorksheetAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetWorksheetAttempt),
    switchMap(action => {
      return this.latestCustomerPk$.pipe(
        concatMap(pk => this.inventoryService.getWorksheetSummaries(pk)),
        concatMap((worksheets: Worksheet[]) => {
          const isActiveInventory = worksheets.find((sheet: Worksheet) => sheet.id === action.worksheetId).isActiveInventory;
          return this.worksheetService.getWorkSheet(action.worksheetId).pipe(
            map((worksheet: Worksheet) => {
              worksheet.isActiveInventory = isActiveInventory;
              return worksheet;
            }),
            map(ws => new GetWorksheetSuccess(ws)),
            catchError(err => of(new GetWorksheetError(err)))
          );
        }),
        catchError(err => of(new GetWorksheetError(err)))
      );
    })
  ));

  getWorksheetSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetWorksheetSuccess),
    map(action => {
      return new SetCustomerPK({ customerPK: action.worksheet.customerPK, shouldReroute: false });
    })
  ));


  createStorageArea$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateStorageAreaName),
    concatMap(action => this.worksheetService.createStorageArea(action.payload.worksheetId, action.payload.storageAreaName)
      .pipe(
        map(storageAreaResponse => {
          WorksheetEffects.storageAreaSchemaChange$.next();
          return new CreateStorageAreaNameSuccess(storageAreaResponse);

        }
        )
      ))
  ));


  createStorageAreaSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateStorageAreaNameSuccess),
    tap(action => {
      const message = this.translate.instant('MESSAGES.STORAGE_AREA_CREATION_MESSAGE', { value: action.storageAreaLastModified.storageArea.name });
      this.messageService.queue(message);
      WorksheetEffects.storageAreaSchemaChange$.next();
    })
  ), { dispatch: false });


  getAllInventoryItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetAllInventoryItemsAttempt),
    withLatestFrom(this.store$),
    filter(([action, state]) => (state.worksheets.worksheets[action.worksheetId] !== undefined)),
    map(([action, state]) => {

      const areaArray: StorageArea[] = state.worksheets.worksheets[action.worksheetId].storageAreas;
      const itemArray: WorksheetItem[] = ([] as WorksheetItem[])
        .concat(...areaArray.map(area => area.worksheetItems));

      const gfsItemIds = itemArray
        .filter(item => item.itemType === 'GFS')
        .map((item: WorksheetItem) => item.itemId)
        .filter(id => !!id)
        .filter(idToRetrieve =>
          Object.keys(state.inventoryItems.inventoryItems)
            .every(existingId => idToRetrieve !== existingId)
        );

      const generalItemIds = itemArray
        .filter(item => item.itemType === 'GENERAL')
        .map((item: WorksheetItem) => item.itemId)
        .filter(id => !!id)
        .filter(idToRetrieve =>
          Object.keys(state.inventoryItems.inventoryItems)
            .every(existingId => idToRetrieve !== existingId)
        );
      return [
        gfsItemIds,
        generalItemIds
      ];
    }),
    map(([inventoryItemKeys, generalItemKeys]) => {
      const actions = [];
      if (inventoryItemKeys.length > 0) {
        actions.push(new GetInventoryItemsAttempt(inventoryItemKeys));
      }
      if (generalItemKeys.length > 0) {
        actions.push(new GetGeneralItemsAttempt({ ids: generalItemKeys, includeDeleted: true }));
      }

      return actions;
    }),
    filter(actions => actions.length > 0),
    concatMap(actions => actions)
  ));


  getInventoryItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SetStorageAreaExpandStatus),
    withLatestFrom(this.store$),
    filter(([action, state]) => action.payload.status
      && (state.worksheets.worksheets[state.worksheets.selected] !== undefined)),
    map(([action, state]) => new GetAllInventoryItemsAttempt(state.worksheets.selected))
  ));


  getAllCustomItemData$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetAllCustomItemDataAttempt),
    concatMap(() => {
      return this.latestCustomerPk$.pipe(
        concatMap((pk) => this.customItemDataService.getCustomItemData(pk, null, null)),
        map((data: CustomItemData[]) => new GetAllCustomItemDataSuccess(data)),
        catchError(err => of(new GetAllCustomItemDataError(err)))
      );
    })));


  updateStorageAreaName$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchStorageArea),
    concatMap(action =>
      this.worksheetService.updateStorageAreaName(action.payload.worksheetId, action.payload.storageAreaId, action.payload.fields)
        .pipe(
          map(lastModified => {
            return new PatchStorageAreaSuccess(
              {
                worksheetLastModified: lastModified,
                storageAreaId: action.payload.storageAreaId,
                fields: action.payload.fields
              }
            );
          })
        ))
  ));


  updateStorageAreaSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchStorageAreaSuccess),
    tap(action => {
      if (action.payload.fields.name) {
        const message = this.translate.instant('MESSAGES.STORAGE_AREA_UPDATE_MESSAGE', { value: action.payload.fields.name });
        this.messageService.queue(message);
        WorksheetEffects.storageAreaSchemaChange$.next();

      }
    })
  ), { dispatch: false });


  deleteStorageArea$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteStorageArea),
    concatMap(action => this.worksheetService.deleteStorageArea(action.payload.worksheetId, action.payload.storageArea)
      .pipe(
        map(lastModifed => {
          WorksheetEffects.storageAreaSchemaChange$.next();
          return new DeleteStorageAreaSuccess({
            worksheetLastModified: lastModifed,
            storageArea: action.payload.storageArea
          });
        }),
        catchError((err) => of(new DeleteStorageAreaError(err)))
      ))
  ));


  deleteStorageAreaSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteStorageAreaSuccess),
    tap(action => {
      const message = this.translate.instant('MESSAGES.DELETION_MESSAGE', { value: action.payload.storageArea.name });
      this.messageService.queue(message)
    })
  ), { dispatch: false });

  updateWorksheetItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.UpdateWorksheetItem),
    mergeMap(action => {
      // Let Okta know you are active
      this.store$.dispatch(new IdPAuthorize());

      console.log('worksheet-mux:updateWorksheetItem$:effect:begin');
      return this.worksheetService.updateWorksheetItem(
        action.payload.worksheetId,
        action.payload.storageAreaId,
        action.payload.worksheetItem
      ).pipe(
        tap(_ => {
          WorksheetEffects.storageAreaSchemaChange$.next()
        }),
        catchError(err => {
          console.log('worksheet-mux:updateWorksheetItem$:effect:err', err);
          return of({
            id: action.payload.worksheetId,
            lastModified: new Date('2030-12-31T00:00:01').toString()
          } as WorksheetLastModified);
        }),
        concatMap(lastModified => ([
          new UpdateWorksheetItemSuccess({
            worksheetLastModified: lastModified,
            storageAreaId: action.payload.storageAreaId,
            worksheetItem: action.payload.worksheetItem
          }),
          new SetWorksheetLastModifiedDate({
            worksheetLastModifiedDate: lastModified.lastModified,
          })
        ])
        ),
        catchError(err => {
          console.log('worksheet-mux:updateWorksheetItem$:effect:err');
          return of(new UpdateWorksheetItemError(err, { worksheetItem: action.payload.worksheetItem }));
        }),
        tap(() => {
          console.log('worksheet-mux:worksheetService.updateWorksheetItem:complete');
        })
      );
    }),
    catchError(err => {
      console.log('catchError on updateWorksheetItem$ effect');
      return of(new UpdateWorksheetItemError(err, { worksheetItem: null }));
    }),
  ));

  createBlankWorksheet$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateBlankWorksheetAttempt),
    concatMap((action) => {
      return this.latestCustomerPk$.pipe(
        concatMap((pk) => this.worksheetService.createBlankWorksheet(pk , action.worksheetPricingOptions)),
        map(worksheet => new CreateBlankWorksheetSuccess(worksheet)),
        catchError((err) => of(new CreateBlankWorksheetError(err)))
      )
    })
  ));


  copyWorksheet$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CopyWorksheetAttempt),
    concatMap(action => {
      return this.latestCustomerPk$.pipe(
        concatMap((pk) => this.worksheetService.copyWorksheet(action.worksheetId, pk)),
        map(worksheet => new CopyWorksheetSuccess(worksheet)),
        catchError((err) => of(new CopyWorksheetError(err)))
      )
    })
  ));


  routeToNewWorksheet$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.CreateBlankWorksheetSuccess,
      ActionTypes.ImportWorksheetFromOrderGuideSuccess,
      ActionTypes.ImportWorksheetFromCustomGuideSuccess,
      ActionTypes.CopyWorksheetSuccess
    ),
    tap(action => {
      this.globalDialogsService.closeLoadingModal();
      this.router.navigateByUrl(`/worksheet/${action.worksheet.id}`);
    })
  ), { dispatch: false });


  routeToExistentWorksheet$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SelectWorksheet),
    tap(action => {
      if (action.payload.shouldNavigate) {
        this.router.navigateByUrl(`/worksheet/${action.payload.worksheetId}`);
      }
    })
  ), { dispatch: false });


  importFromOrderGuide$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ImportWorksheetFromOrderGuideAttempt),
    concatMap(action => {
      return this.latestCustomerPk$.pipe(
        concatMap(pk => this.worksheetService.importWorksheetFromOrderGuid(pk, action.sorting ,action.worksheetPricingOptions)),
        map(worksheet => new ImportWorksheetFromOrderGuideSuccess(worksheet)),
        catchError((err) => of(new ImportWorksheetFromOrderGuideError(err)))
      );
    })
  ));


  importFromCustomGuide$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ImportWorksheetFromCustomGuideAttempt),
    concatMap(action => {
      return this.latestCustomerPk$.pipe(
        concatMap(pk => this.worksheetService.importWorksheetFromCustomGuide(pk, action.customGuideIds, action.sorting ,action.worksheetPricingOptions)),
        map(worksheet => new ImportWorksheetFromCustomGuideSuccess(worksheet)),
        catchError((err) => of(new ImportWorksheetFromCustomGuideError(err)))
      )
    }),
  ));


  deleteInventoryItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteInventoryItem),
    concatMap(action => {
      return this.worksheetService.deleteInventoryItem(
        action.payload.worksheetId,
        action.payload.storageAreaId,
        action.payload.inventoryItem)
        .pipe(
          map(lastModified => {

            WorksheetEffects.itemDeletedNotifier$.next({
              worksheetItemId: action.payload.inventoryItem.id,
              storageAreaId: action.payload.worksheetId
            });

            return new DeleteInventoryItemSuccess({
              worksheetLastModified: lastModified,
              inventoryItem: action.payload.inventoryItem
            });
          })
        );
    })
  ));




  getWorkSheetSummaries$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetWorksheetSummaryAttempt),
    concatMap(action => {
      return this.latestCustomerPk$.pipe(
        concatMap(pk => this.inventoryService.getWorksheetSummaries(pk)),
        map(ws => {
          if (ws.length > 0) {
            return new GetWorkSheetFromSummaryAttempt(ws, action.routeToInProgress);
          } else {
            return new GetWorksheetSummarySuccess(ws, null, action.routeToInProgress);
          }
        }),
        catchError(err => of(new GetWorksheetSummaryError(err))))
    }))
  );


  getWorksheetFromSummaryAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetWorkSheetFromSummaryAttempt),
    concatMap(action => {
      const wsId = action.worksheets.reduce((latestWorkSheet, worksheet) =>
        latestWorkSheet.created > worksheet.created ? latestWorkSheet : worksheet).id;
      return this.worksheetService.getWorkSheet(wsId)
        .pipe(
          map(ws => new GetWorkSheetFromSummarySuccess(action.worksheets, ws, action.routeToInProgress)),
          catchError(err => of(new GetWorksheetError(err)))
        );
    })
  ));


  getWorksheetFromSummarySuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetWorkSheetFromSummarySuccess),
    withLatestFrom(this.store$),
    map(([action, state]) => {
      const modifiedWorksheet = action.openWorkSheet;
      const recalculateInventoryTotalValue = [
        !!action.openWorkSheet,
        !!Object.keys(state.inventoryItems?.inventoryItems || {}).length
      ].every(isTrue => isTrue);

      if (recalculateInventoryTotalValue) {
        const updatedTotalValue = this.inventoryValueService.getTotalWorksheetValue(
          action.openWorkSheet,
          state.inventoryItems.inventoryItems,
          state.addItemsFeature.measurementUnits,
          state.worksheets.customItemData
        );
        const valueComparison = {
          ...{},
          initialValue: modifiedWorksheet.totalValue,
          updatedValue: updatedTotalValue
        };
        modifiedWorksheet.totalValue = valueComparison.updatedValue;
      }
      return new GetWorksheetSummarySuccess(action.worksheets, modifiedWorksheet, action.routeToInProgress);
    })
  ));


  routeToInProgress$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetWorksheetSummarySuccess),
    filter(action => !!action.routeToInProgress),
    tap(action => {
      const inProgressSheet = action.worksheets.find(ws => ws.status === 'open');
      if (inProgressSheet) {
        this.router.navigateByUrl(`/worksheet/${inProgressSheet.id}`);
      }
    })
  ), { dispatch: false });


  getCustomGuides$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetCustomGuidesAttempt),
    concatMap(() => {
      return this.latestCustomerPk$.pipe(
        concatMap(pk => this.inventoryService.getCustomGuides(pk)),
        map(ws => new GetCustomGuidesSuccess(ws)),
        catchError(err => of(new GetCustomGuidesError(err)))
      )
    })
  ));


  patchWorksheet$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchWorksheetAttempt),
    concatMap(action => this.worksheetService.patchWorksheet(action.payload.worksheetId, action.payload.fields)
      .pipe(
        map(worksheetLastModified => {
          return (new PatchWorksheetSuccess({
            worksheetLastModified,
            fields: action.payload.fields,
          }));
        }),
        catchError((err): Observable<Action> => {
          if (action.payload.fields.status === 'closed') {
            return of(new CloseWorksheetError(err));
          }
          return of(new PatchWorksheetError(err));
        })
      ))
  ));


  deleteWorksheetAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteWorksheetAttempt),
    concatMap(action => {
      const deleteCall = this.worksheetService.deleteWorksheet(action.payload.id)
        .pipe(
          map(lastModifed => {
            return new DeleteWorksheetSuccess({
              worksheetLastModified: lastModifed,
              worksheet: action.payload
            });
          }),
          catchError((err) => of(new DeleteWorksheetError(err)))
        );
      if (action.payload.status !== 'closed') {
        return this.worksheetService.patchWorksheet(action.payload.id, { status: 'closed' })
          .pipe(
            concatMap(() => deleteCall),
            catchError((err) => of(new DeleteWorksheetError(err)))
          );
      }

      return deleteCall;
    }),
  ));


  deleteWorksheetSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteWorksheetSuccess),
    tap(action => {
      const message = this.translate.instant('MESSAGES.DELETION_MESSAGE', { value: action.payload.worksheet.name });
      this.messageService.queue(message);
    }),
    map(() => new GetWorksheetSummaryAttempt())
  ));


  setIsEditingNameFalse$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchWorksheetSuccess),
    filter(action => !!action.payload.fields.name),
    map(action => new SetIsEditingName(false))
  ));


  createWorksheetItem$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateWorksheetItemAttempt),
    concatMap(action =>
      forkJoin([
        from(this.oktaConfig.oktaAuth.session.get()),
        this.worksheetService.createWorksheetItem(action.payload.worksheetId, action.payload.storageAreaId, action.payload.worksheetItemPost)
      ]).pipe(
        tap(([session]) => {
          return console.log('SESSION after update is : %O', session);
        }),
        map(([_, worksheetItemLastModified]) => {
          return new CreateWorksheetItemSuccess({
            worksheetItemLastModified,
            storageAreaId: action.payload.storageAreaId,
            areaExpandStatus: action.payload.areaExpandStatus,
            itemDescription: action.payload.itemDescription,
            successMessage: action.payload.successMessage,
            duplicate: action.payload.duplicate
          });
        }),
        catchError(err => of(new CreateWorksheetItemError(err)))
      ))));


  createWorksheetItemSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateWorksheetItemSuccess),
    tap(action => {
      WorksheetEffects.storageAreaSchemaChange$.next();
      const message = this.translate.instant(action.payload.successMessage, { value: action.payload.itemDescription });
      this.messageService.queue(message);
    }),
    filter(action => action.payload.areaExpandStatus !== undefined && action.payload.areaExpandStatus !== null),
    map((action) => new SetStorageAreaExpandStatus({ status: action.payload.areaExpandStatus, areaId: action.payload.storageAreaId })),
    catchError(err => of(new CreateWorksheetItemError(err)))
  ));


  createGroupWorksheetItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateGroupWorksheetItemsAttempt),
    concatMap(action => forkJoin([
      from(this.oktaConfig.oktaAuth.session.get()),
      this.worksheetService.createGroupWorksheetItems(action.payload.worksheetItemGroupPost)
    ]).pipe(
      tap(([session]) => {
        return console.log('SESSION after update is : %O', session);
      }),
      map(([_, worksheetItemsLastModified]) => new CreateGroupWorksheetItemsSuccess({
        worksheetItemsLastModified,
        itemDescription: action.payload.itemDescription,
        successMessage: action.payload.successMessage,
      })),
      catchError(err => of(new CreateGroupWorksheetItemsError(err)))
    ))));


  createGroupWorksheetItemsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateGroupWorksheetItemsSuccess),
    tap(action => {
      const message = this.translate.instant(action.payload.successMessage, { value: action.payload.itemDescription });
      this.messageService.queue(message);
    })
  ), { dispatch: false });


  resetWorksheet$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ResetWorksheetAttempt),
    concatMap(action => this.worksheetService.patchWorksheet(action.payload.worksheetToCloseId, { status: 'closed' })
      .pipe(
        map(_ => new ResetWorksheetSuccess(action.payload.worksheetImportAction)),
        catchError((err) => of(new ResetWorksheetError(err)))
      ))
  ));


  importAfterReset$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.ResetWorksheetSuccess),
    map(action => action.worksheetImportAction)
  ));


  worksheetActionError$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.CreateWorksheetItemError,
      ActionTypes.DeleteStorageAreaError,
      ActionTypes.DeleteWorksheetError,
      ActionTypes.PatchWorksheetError,
      ActionTypes.DeleteInventoryItemError,
    ),
    tap(action => {
      this.globalDialogsService.closeLoadingModal();
      this.messageService.queue(action.error.message);
    })
  ), { dispatch: false });


  worksheetNavigationError$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.CreateBlankWorksheetError,
      ActionTypes.ImportWorksheetFromOrderGuideError,
      ActionTypes.ImportWorksheetFromCustomGuideError,
      ActionTypes.GetWorksheetError,
      ActionTypes.CloseWorksheetError,
      ActionTypes.CopyWorksheetError,
      ActionTypes.ResetWorksheetError
    ),
    filter((action: any) => {
      // filter out worksheet already open errors, these should just route to the open worksheet
      const httpError = action.error as HttpErrorResponse;
      return httpError.status !== 400
        && (httpError.error === null || httpError.error.code !== '103');
    }),
    tap(_ => {
      this.globalDialogsService.closeLoadingModal();
      this.router.navigateByUrl('/error');
    })
  ), { dispatch: false });


  alreadyInProgressCreateWorksheetError$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.CreateBlankWorksheetError,
      ActionTypes.ImportWorksheetFromOrderGuideError,
      ActionTypes.ImportWorksheetFromCustomGuideError
    ),
    filter((action: any) => {
      const httpError = action.error as HttpErrorResponse;
      return httpError.status === 400
        && (httpError.error !== null && httpError.error.code === '103');
    }),
    tap(_ => {
      this.globalDialogsService.closeLoadingModal();
      // TODO: when we parameterize our error modals we will need this translate line here
      // const errorMessage = this.translate.instant('MESSAGES.OPEN_WORKSHEET_EXISTS');
      const dialogRef = this.globalDialogsService.openResetErrorModal();

      const dialogSize$ = this.store$.select(state => state.layout.isMobile)
        .subscribe(isMobile => {
          if (isMobile) {
            dialogRef.updateSize('90%', 'auto');
          } else {
            dialogRef.updateSize('610px', 'auto');
          }
        });

      dialogRef.afterClosed().subscribe(() => {
        dialogSize$.unsubscribe();
      });
    }),
    map(_ => new GetWorksheetSummaryAttempt())
  ));


  alreadyInProgressCopyWorksheetError$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.CopyWorksheetError
    ),
    filter((action: any) => {
      const httpError = action.error as HttpErrorResponse;
      return httpError.status === 400
        && httpError.error.code === '103';
    }),
    map(_ => new GetWorksheetSummaryAttempt(true))
  ));




  setWorksheetDone$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SetWorksheetDone),
    concatMap((action) => this.worksheetService.patchWorksheet(action.payload.worksheetId, action.payload.fields)
      .pipe(
        map(worksheetLastModified => ([
          new PatchWorksheetSuccess({
            worksheetLastModified,
            fields: action.payload.fields,
          }),
          new NavigateHome()
        ])),
        concatMap(x => x),
        catchError((err): Observable<Action> => {
          if (action.payload.fields.status === 'closed') {
            return of(new CloseWorksheetError(err));
          }
          return of(new PatchWorksheetError(err));
        })
      )
    )
  ));

  getCountingUnitsAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetCountingUnitsAttempt, AddItemsActionTypes.GetMeasurementUnitsSuccess),
    withLatestFrom(this.store$),
    map(([actions, state]) => {

      // Hardcoded literal units
      const literalUnits = [
        {
          type: 'CASE',
          name: [
            {
              languageCode: 'en_CA',
              value: 'Case'
            },
            {
              languageCode: 'fr_CA',
              value: 'Caisse'
            }
          ]
        },
        {
          type: 'UNIT',
          name: [
            {
              languageCode: 'en_CA',
              value: 'Unit'
            },
            {
              languageCode: 'fr_CA',
              value: 'Unité'
            }
          ]
        },
        {
          type: InventoryConstants.SAP_UOM_EACH,
          name: [
            {
              languageCode: 'en_CA',
              value: 'Each'
            },
            {
              languageCode: 'fr_CA',
              value: 'Chaque'
            }
          ]
        }
      ] as LiteralUnit[];

      const lcUnits = literalUnits

        .map((literalUnit: LiteralUnit): CountingUnit => ({
          unit: {
            unitType: 'LITERAL',
            unitId: literalUnit.type
          },
          literalUnit,
          standardUnit: null,
          customUnit: null
        } as CountingUnit));

      const units = (state.addItemsFeature?.measurementUnits ?? [])
        .filter(unit => unit.displayCategories.find(cat => cat === 'inventoryWorksheetCountingUnit'))
        .map((standardUnit): CountingUnit => ({
          unit: {
            unitType: 'STANDARD',
            unitId: standardUnit.id
          },
          standardUnit
        } as CountingUnit))
        .concat(lcUnits);

      return new GetCountingUnitsSuccess(units);
    })
  ));

  /** when the users changes the item price we need to recalc the worksheet
   */
  UpdateCustomItemPriceSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(customerItemActionTypes.UpdateCustomItemPriceSuccess, inventoryItemActionTypes.UpdateGeneralItemsSuccess),
    // gather worksheet, storage area and countable items
    concatMap((action: UpdateCustomItemPriceSuccess | UpdateGeneralItemsSuccess) => {
      return forkJoin([
        of(action),
        this.store$.select((state) => state.worksheets.worksheets[state.worksheets.selected])
          .pipe(first()),
        this.store$.select((state) => state.worksheets.worksheets[state.worksheets.selected])
          .pipe(
            first(),
            isTruthy(),
            map((worksheet: Worksheet) => worksheet.storageAreas.reduce(
              (acc, storageArea) => [...acc, ...storageArea.worksheetItems], []
            ) as WorksheetItem[]),
          ),
        this.store$.select((state) => state.inventoryItems.inventoryItems)
          .pipe(first(), toEntityArray())
      ]);
    }),
    filter(([, worksheet]) => !!worksheet),
    map(([action, worksheet, worksheetItems, countableItems]) => {
      let generalItemId = null;
      switch (action.type) {
        case customerItemActionTypes.UpdateCustomItemPriceSuccess:
          generalItemId = countableItems
            .find(x => x.customItem?.id === action.payload.id).generalItem.id;
          break;
        case inventoryItemActionTypes.UpdateGeneralItemsSuccess:
          generalItemId = action.payload.generalItem.id;
          break;
      }

      const worksheetItem = worksheetItems.find(x => x.itemId === generalItemId);
      const storageArea = worksheet.storageAreas.find(x => x.worksheetItems.find(y => y.id === worksheetItem.id) != null);

      return new UpdateWorksheetItem({
        worksheetId: worksheet.id,
        storageAreaId: storageArea.id,
        worksheetItem
      } as WorksheetItemChange);
    })
  ));


  RefreshWorksheetItems$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RefreshWorksheetItems),
    withLatestFrom(this.store$),
    exhaustMap(([, state]) => this.worksheetService.refreshWorksheetItems(state.worksheets.selected)
      .pipe(
        map(() => new RefreshWorksheetItemsComplete()),
        catchError(error => of(new RefreshWorksheetItemsError(error)))
      ))
  ));


  RefreshWorksheetItemsComplete$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RefreshWorksheetItemsComplete),
    tap(() => this.windowRef.location.reload())
  ), { dispatch: false });


  RefreshWorksheetItemsError$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.RefreshWorksheetItemsError),
    tap(action => this.messageService.queue(action.error.message))
  ), { dispatch: false });


  SetInfiniteScrollingToggleState$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.SetInfiniteScrollingToggleState),
    tap(action => this.appConfig.setLocalStorageValue(InventoryConstants.infiniteScrollSettingPath, action.toggleState))
  ), { dispatch: false });


  MoveWorksheetItemToStorageArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.MoveWorksheetItemToStorageArea),
      concatMap(action => of(action)
        .pipe(
          concatMap(action => {
            return this.worksheetService.createWorksheetItem(
              action.payload.worksheetId,
              action.payload.destinationStorageAreaId,
              action.payload.worksheetItemPost)
              .pipe(
                map((worksheetItemLastModified: WorksheetItemLastModified) => ({
                  action,
                  createWorksheetItemLastModified: worksheetItemLastModified
                })),
                concatMap(({ action, createWorksheetItemLastModified }) => {
                  return this.worksheetService.deleteInventoryItem(
                    action.payload.worksheetId,
                    action.payload.initialStorageAreaId,
                    action.payload.inventoryItem)
                    .pipe(
                      map((worksheetItemLastModified: WorksheetItemLastModified) => ({
                        action,
                        createWorksheetItemLastModified,
                        deleteWorksheetItemLastModified: worksheetItemLastModified
                      })),
                      concatMap(({ action, createWorksheetItemLastModified }) => {
                        return [
                          new CreateWorksheetItemSuccess({
                            worksheetItemLastModified: createWorksheetItemLastModified,
                            storageAreaId: action.payload.destinationStorageAreaId,
                            areaExpandStatus: action.payload.areaExpandStatus,
                            itemDescription: action.payload.itemDescription,
                            successMessage: action.payload.successMessage,
                            duplicate: action.payload.duplicate
                          }),
                          new DeleteInventoryItemAttempt({
                            worksheetId: action.payload.worksheetId,
                            storageAreaId: action.payload.initialStorageAreaId,
                            inventoryItem: action.payload.inventoryItem
                          })
                        ];
                      }),
                      catchError((error: HttpErrorResponse) => {
                        return this.handleMoveWorksheetError(action, error, MoveWorksheetItemSteps.Delete);
                      }),
                    );
                }),
                catchError((error: HttpErrorResponse) => {
                  return this.handleMoveWorksheetError(action, error, MoveWorksheetItemSteps.Create);
                }),
              )
          }),
        ))
    ));



  moveWorksheetItemErrorMessages = {
    [MoveWorksheetItemSteps.Create]: (error: HttpErrorResponse, storageAreaName: string): CreateWorksheetItemError =>
      new CreateWorksheetItemError({
        ...error, message: this.localizeMessage('STORAGE_AREA.STORAGE_AREA_ITEM_MOVE_CREATE_STEP_FAILURE', storageAreaName)
      }),
    [MoveWorksheetItemSteps.Delete]: (error: HttpErrorResponse, storageAreaName: string): DeleteInventoryItemError =>
      new DeleteInventoryItemError({
        ...error, message: this.localizeMessage('STORAGE_AREA.STORAGE_AREA_ITEM_MOVE_DELETE_STEP_FAILURE', storageAreaName)
      }),
  }

  localizeMessage(messageKey: string, storageAreaName: string) {
    return this.translate.instant(messageKey, { value: storageAreaName });
  }

  handleMoveWorksheetError(
    action: MoveWorksheetItemToStorageArea,
    error: HttpErrorResponse,
    stage: MoveWorksheetItemSteps.Create | MoveWorksheetItemSteps.Delete
  ): Observable<CreateWorksheetItemError | DeleteInventoryItemError | GetWorksheetAttempt> {
    return of(error)
      .pipe(
        withLatestFrom(this.store$),
        concatMap(([error, state]) => {
          const { worksheetId, destinationStorageAreaId: storageAreaId } = action.payload;
          const destinationStorageAreaName = state.worksheets.worksheets[worksheetId]
            .storageAreas.find(storageArea => storageArea.id === storageAreaId).name;
          return [
            new GetWorksheetAttempt(action.payload.worksheetId),
            this.moveWorksheetItemErrorMessages[stage](error, destinationStorageAreaName)
          ];
        }));
  }
}

export enum MoveWorksheetItemSteps {
  Create = 'create',
  Delete = 'delete',
}