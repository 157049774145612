<div class="worksheet-wizard-container">
  <div class="worksheet-wizard-header">
    <div class="worksheet-wizard-header-title-wrapper">
      <div class="worksheet-back-button-chevron step-{{importStep}}" *ngIf="importStep > 1" (click)="backButton()"></div>
      <div class="worksheet-wizard-header-title">{{ 'INVENTORY_WORKSHEET_WIZARD.GET_STARTED' | translate }}</div>
      <div class="worksheet-wizard-header-step" *ngIf="importStep === 1">{{ 'INVENTORY_WORKSHEET_WIZARD.STEP' | translate }} 1</div>
      <div class="worksheet-wizard-header-step" *ngIf="importStep === 2">{{ 'INVENTORY_WORKSHEET_WIZARD.STEP' | translate }} 2</div>
      <div class="worksheet-wizard-header-step" *ngIf="importStep === 3">{{ 'INVENTORY_WORKSHEET_WIZARD.STEP' | translate }} 3</div>
    </div>

    <button
      mat-icon-button
      class="worksheet-wizard-header-close"
      attr.aria-label="{{ 'MODALS.CLOSE' | translate }}"
      (click)="dialogRef.close()"
      (keyup.enter)="dialogRef.close()">
      <mat-icon svgIcon="close-icon"></mat-icon>
    </button>
  </div>

  <div
    *ngIf="importStep === 1">
    <div class="worksheet-wizard-content">
      <div class="wizard-content-header">
        {{ 'INVENTORY_WORKSHEET_WIZARD.IMPORT_TYPE_QUESTION' | translate }}
      </div>
      <div class="wizard-option-list">
        <mat-radio-group (change)="onImportTypeChange($event)" [value]="importType">
          <div class="wizard-option-container">
            <div class="wizard-option">
              <mat-radio-button value="order-guide" id="import-type-order-guide">
                <div class="wizard-option-label">
                  {{ 'INVENTORY_WORKSHEET_WIZARD.ORDER_GUIDE' | translate }}
                </div>
                <div class="wizard-option-description">
                  {{ 'INVENTORY_WORKSHEET_WIZARD.ORDER_GUIDE_DESCRIPTION' | translate }}
                </div>
              </mat-radio-button>
            </div>
          </div>
          <mat-expansion-panel [expanded]="isCustomGuide" *ngIf="(customGuides$ | async).length" class="wizard-option-container" (afterExpand)="onCustomGuideSectionClicked()">
            <mat-expansion-panel-header class="custom-guide-expansion-panel-header" collapsedHeight="auto" expandedHeight="auto">
              <mat-panel-title class="wizard-option">
                <mat-radio-button #customGuideRadio value="custom-guide" id="import-type-custom-guide">
                  <div class="wizard-option-label">
                    {{ 'INVENTORY_WORKSHEET_WIZARD.CUSTOM_GUIDE' | translate }}
                  </div>
                  <div class="wizard-option-description">
                    {{ 'INVENTORY_WORKSHEET_WIZARD.CUSTOM_GUIDE_DESCRIPTION' | translate }}
                  </div>
                </mat-radio-button>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <section class="wizard-option-content">
              <mat-grid-list gutterSize="0px" rowHeight="48px" [cols]="(isMobile$ | async) ? 1 : 2">
                <mat-grid-tile *ngFor="let customGuide of (customGuides$ | async)">
                  <mat-checkbox class="custom-guide-option" [value]="customGuide" [checked]="selectedCustomGuides.indexOf(customGuide) > -1" (change)="onCustomGuideSelected($event)">{{customGuide.name}}</mat-checkbox>
                </mat-grid-tile>
              </mat-grid-list>
            </section>
          </mat-expansion-panel>
          <div class="wizard-option-container">
            <div class="wizard-option">
              <mat-radio-button value="blank-worksheet" id="import-type-order-guide">
                <div class="wizard-option-label">
                  {{ 'INVENTORY_WORKSHEET_WIZARD.CREATE_BLANK' | translate }}
                </div>
                <div class="wizard-option-description">
                  {{ 'INVENTORY_WORKSHEET_WIZARD.CREATE_BLANK_WORKSHEET_DESCRIPTION' | translate }}
                </div>
              </mat-radio-button>
            </div>
            </div>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div
    *ngIf="importStep === 3 || blankWorkheetFlow && importStep !== 1">
    <div class="worksheet-wizard-content">
      <div class="wizard-content-header">
        {{ 'INVENTORY_WORKSHEET_WIZARD.STEP' | translate }}
      </div>
      <div class="wizard-option-list">
        <mat-radio-group (change)="onPriceTypeChange($event)" [value]="worksheetPricingOptions">
          <div class="wizard-option-container">
            <div class="wizard-option">
              <mat-radio-button value="current-price" id="import-type-order-guide">
                <div class="wizard-option-label">
                  {{ 'INVENTORY_WORKSHEET_WIZARD.CURRENT_PRICE' | translate }}
                </div>
                <div class="wizard-option-description">
                  {{ 'INVENTORY_WORKSHEET_WIZARD.CURRENT_PRICE_DESCRIPTION' | translate }}
                </div>
              </mat-radio-button>
            </div>
          </div>
          <div class="wizard-option-container">
          <div class="wizard-option">
            <mat-radio-button value="last-purchase-price" id="import-type-order-guide">
              <div class="wizard-option-label">
                {{ 'INVENTORY_WORKSHEET_WIZARD.LAST_PURCHASE_PRICE' | translate }}
              </div>
              <div class="wizard-option-description">
                {{ 'INVENTORY_WORKSHEET_WIZARD.LAST_PURCHASE_PRICE_DESCRIPTION' | translate }}
              </div>
            </mat-radio-button>
          </div>
          </div>
        
        </mat-radio-group>
      </div>
    </div>
  </div>

  <div
    *ngIf="importStep === 2 && !blankWorkheetFlow">
    <div class="worksheet-wizard-content">
      <div class="wizard-content-header">
        {{ 'INVENTORY_WORKSHEET_WIZARD.ORDER_GUIDE_ORGANIZE_QUESTION' | translate }}
      </div>
      <div class="wizard-option-list">
        <mat-radio-group (change)="onOrganizationChange($event)" [value]="organizationType">
          <div class="wizard-option-container">
            <div class="wizard-option">
              <mat-radio-button value="use-storage-areas" id="order-guide-use-storage-areas">
                <div class="wizard-option-label">
                  {{ 'INVENTORY_WORKSHEET_WIZARD.USE_STORAGE_AREAS' | translate }}
                </div>
                <div class="wizard-option-description">
                  {{ 'INVENTORY_WORKSHEET_WIZARD.USE_STORAGE_AREAS_DESCRIPTION' | translate }}
                </div>
              </mat-radio-button>
            </div>
          </div>
          <mat-expansion-panel *ngIf="isCustomGuide" [expanded]="organizationType === 'custom-guide-organization'" class="wizard-option-container" (afterExpand)="onCustomGuideOrganizationSectionClicked()">
            <mat-expansion-panel-header class="custom-guide-expansion-panel-header" collapsedHeight="auto" expandedHeight="auto">
              <mat-panel-title class="wizard-option">
                <mat-radio-button #customGuideOrganizationRadio value="custom-guide-organization" id="organization-type-custom-guide-organization">
                  <div class="wizard-option-label">
                    {{ 'INVENTORY_WORKSHEET_WIZARD.CUSTOM_GUIDE_ORGANIZATION' | translate }}
                  </div>
                  <div class="wizard-option-description">
                    {{ 'INVENTORY_WORKSHEET_WIZARD.CUSTOM_GUIDE_ORGANIZATION_DESCRIPTION' | translate }}
                  </div>
                </mat-radio-button>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="wizard-option-content">
              <mat-grid-list gutterSize="0px" rowHeight="48px" [cols]="(isMobile$ | async) ? 1 : 2">
                <mat-grid-tile *ngFor="let cg of getCustomGuidesCategories()">
                  <div class="custom-guide-option custom-guide-category">{{formatCategory(cg) | truncated: 40}}</div>
                </mat-grid-tile>
              </mat-grid-list>
            </section>
          </mat-expansion-panel>
          <div class="wizard-option-container">
            <div class="wizard-option">
              <mat-radio-button value="do-not-organize" id="order-guide-do-not-organize">
                <div class="wizard-option-label">
                  {{ 'INVENTORY_WORKSHEET_WIZARD.DO_NOT_ORGANIZE' | translate }}
                </div>
                <div class="wizard-option-description">
                  {{ 'INVENTORY_WORKSHEET_WIZARD.DO_NOT_ORGANIZE_DESCRIPTION' | translate }}
                </div>
              </mat-radio-button>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </div>

  <div class="worksheet-wizard-button-bar button-step-2">
    <div
      *ngIf="importStep === 1"
      class="worksheet-wizard-button-wrapper step-1-next">
      <button
        id="worksheet-wizard-next-step"
        [disabled]="isDisabled"
        (click)="onNextClicked()"
        class="primary-button large-button"
        type="button">
        {{ 'INVENTORY_WORKSHEET_WIZARD.NEXT' | translate }}
      </button>
    </div>
    <div
      *ngIf="importStep === 3 || blankWorkheetFlow && importStep !== 1"
      class="worksheet-wizard-button-wrapper step-2-next">
      <button
      (click)="backButton()"
      class="secondary-button large-button "
      type="button">
        {{ 'INVENTORY_WORKSHEET_WIZARD.BACK' | translate }}
      </button>
      <button
        id="worksheet-wizard-done"
        (click)="fireImport()"
        [disabled]="blankWorkheetFlow ? !organizationType && !worksheetPricingOptions : !worksheetPricingOptions"
        class="primary-button large-button"
        type="button">
        {{ 'INVENTORY_WORKSHEET_WIZARD.DONE' | translate }}
      </button>
    </div>
    <div
      *ngIf="importStep === 2 && !blankWorkheetFlow"
      class="step-2-next">
      <button
      (click)="backButton()"
      class="secondary-button large-button "
      type="button">
        {{ 'INVENTORY_WORKSHEET_WIZARD.BACK' | translate }}
      </button>
      <button
        [disabled]="isOrganiseNextDisabled"
        (click)="onNextClicked()"
        class="primary-button large-button "
        type="button">
        {{ 'INVENTORY_WORKSHEET_WIZARD.NEXT' | translate }}
      </button>
    </div>
  </div>
</div>
