import { ResolvedItem } from './product.model';

export interface PrintableRecipeVM {
  id: string;
  isLoading: boolean;
  isReady: boolean;
  recipeName: string;
  formattedPrepTime: string;
  formattedCookTime: string;
  margin?:string;
  menuPrice?:number;
  recipeTotalCost?:number;
  foodCostPercentage?:string;
  type?:string;
  batchYieldQty?:string;
  batchYieldUnit?:string;
  batchCostUnit?:string;
  recipeBatchPortionCost?:number;
  ingredients: PrintableIngredientEntry[];
  prepInstructions: string;
  orderedImageUriList: string[];
  errorMessage?: string;
}
export interface PrintableIngredientEntry {
  formattedMeasurement: string;
  ingredientName: string;
  displayItem?:ResolvedItem
}
