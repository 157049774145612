import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InventoryConstants } from '@gfs/constants';



export interface ErrorDialogData {
  title: string;
  subtitle?: string;
  type?:string;
  cancelButtonLabel?: string;
}

@Component({
  selector: 'gfs-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  get title() {
    return this.data.title || 'MESSAGES.OPEN_WORKSHEET_EXISTS_TITLE';
  }

  get subtitle() {
    return this.data.subtitle || 'MESSAGES.OPEN_WORKSHEET_EXISTS';
  }

  get cancelButtonId() {
    return this.data.cancelButtonLabel || 'MESSAGES.OKAY';
  }

  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ErrorDialogData
  ) { }

  ngOnInit(): void {
    const importGate = this.dialog.getDialogById(InventoryConstants.LOADING_MODAL_ID + '_RESET_WORKSHEET');
    importGate?.close();
  }

  closeModal() {
    this.dialogRef.close();
  }
}
