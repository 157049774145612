import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/inventory/reducers';
import { tap } from 'rxjs/operators';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {

  constructor(
    private translate: TranslateService,
    private store: Store<AppState>,
  ) {
    super();
    this.store.select((state) => state.layout.language).pipe(
      tap(language => this.setCustomField(language))
    ).subscribe();
  }

  private setCustomField(language: string) {
    this.translate.getTranslation(language).subscribe(translations => {
      this.firstPageLabel = translations.PAGINATOR_TRANSLATE.FIRST_PAGE
      this.previousPageLabel = translations.PAGINATOR_TRANSLATE.PREVIOUS_PAGE
      this.nextPageLabel = translations.PAGINATOR_TRANSLATE.NEXT_PAGE
      this.lastPageLabel = translations.PAGINATOR_TRANSLATE.LAST_PAGE
      this.changes.next(); // Notify the paginator to update the labels
    })
  }
}