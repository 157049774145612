import {
    Inject,
    Injectable,
    InjectionToken
} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { TokenResponse } from '@gfs/shared-services/auth/oauth2/models/TokenResponse';
import { WINDOW } from '@gfs/shared-services/services/window.service';
import {
    BehaviorSubject,
    Observable,
    catchError,
    concatMap,
    filter,
    finalize,
    first,
    forkJoin,
    from,
    map,
    mergeMap,
    of,
    retry,
    switchMap,
} from 'rxjs';
import {
    AuthenticationService,
    blockFromRecipeProfitCalculator as blockFromRecipeProfitCalculatorFn,
    defaultAuthState
} from '../../authentication-state.service';
import { AuthenticationState } from '../../models/authentication-state.model';
import { OIDCConfig } from '../../oauth2/models/oidc-config.model';
import { OpenIDConnectService } from '../../oauth2/service/open-id-connect.service';
import { Store } from '@ngrx/store';
import { AuthState, SetNamAccessTokenRefresh } from '@gfs/store/common';

export const NAM_OIDC_CONFIG = new InjectionToken<OIDCConfig>('nam-oidc-config');

// https://www.microfocus.com/documentation/access-manager/developer-documentation-5.0/pdfdoc/oauth-application-developer-guide/oauth-application-developer-guide.pdf
@Injectable()
export class NamAuthenticationService implements
    AuthenticationService {

    blockFromRecipeProfitCalculator = blockFromRecipeProfitCalculatorFn;

    storageKeys = {
        namAuthenticationCodeVerifier: 'authentication-code-verifier',
        namAccessToken: 'token-storage',
        namRefreshToken: 'token-storage-refresh',
    };

    constructor(
        public openIDConnectService: OpenIDConnectService,
        @Inject(WINDOW) public window: Window,
        @Inject(NAM_OIDC_CONFIG) public namOIDCConfig: OIDCConfig,
        private readonly store: Store<AuthState>
    ) { }

    private authStateSubject = new BehaviorSubject<AuthenticationState>(this.getAuthState());

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.loginWithRedirect$()
            .pipe(map(() => {
                return false;
            }));
    }

    public reauthorizeWithoutPrompt$(): Observable<void> {
        const accessToken = this.window.localStorage.getItem(this.storageKeys.namAccessToken);
        const refreshToken = this.window.localStorage.getItem(this.storageKeys.namRefreshToken);
        return forkJoin([
            this.namOIDCConfig
        ]).pipe(
            filter(() => this.isTokenAboutToExpire(accessToken)),
            concatMap(([oidc]) => {
                const headers = new Headers();
                headers.set('content-type', 'application/x-www-form-urlencoded');
                const fetchBody = new URLSearchParams();
                fetchBody.set('grant_type', 'refresh_token');
                fetchBody.set('refresh_token', refreshToken);
                fetchBody.set('client_id', oidc.clientId);

                const tokenUrl = new URL(oidc.authorizationServer.token_endpoint);
                const request = this.window.fetch(tokenUrl.href,
                    {
                        body: fetchBody,
                        headers,
                        method: 'POST',
                        redirect: 'manual',
                    });

                const tokenResponse = from(request);
                
                return this.openIDConnectService.validateTokenResponse$(oidc, tokenResponse);
            }),
            map((response: TokenResponse) => {
                this.window.localStorage.setItem(this.storageKeys.namRefreshToken, response.refreshToken);
                this.window.localStorage.setItem(this.storageKeys.namAccessToken, response.accessToken);
                this.store.dispatch(new SetNamAccessTokenRefresh(response.accessToken));
            }),
            retry(5),
            catchError((error: Error) => {
                this.removeAccessToken();
                return of({ ...defaultAuthState, error });
            })
        ).pipe(
            switchMap(() => {
                return of(void 0);
            }),
        );
    }

    public authenticationState$(): Observable<AuthenticationState> {
        return this.authStateSubject.asObservable();
    }

    // Consider nonce and state parameters for improved security to PKCE auth flow
    public loginWithRedirect$(): Observable<void> {

        // Generate code verifier and code challenge. Store code verifier in session
        const pkceCredential = this.openIDConnectService.generatePKCECredential$();
        return forkJoin([
            this.namOIDCConfig,
            pkceCredential,
        ])
            .pipe(
                concatMap(([oidc, credential]) => {
                    // Persist code verifier
                    this.window.localStorage.setItem(this.storageKeys.namAuthenticationCodeVerifier, credential.codeVerifier);

                    const authorizationUrl = new URL(oidc.authorizationServer.authorization_endpoint);
                    authorizationUrl.searchParams.set('code_challenge', credential.codeChallenge);
                    authorizationUrl.searchParams.set('code_challenge_method', 'S256');
                    authorizationUrl.searchParams.set('scope', 'profile');
                    authorizationUrl.searchParams.set('response_type', 'code');
                    authorizationUrl.searchParams.set('redirect_uri', oidc.redirectUri);
                    authorizationUrl.searchParams.set('client_id', oidc.clientId);

                    const url = authorizationUrl.toString();
                    this.window.location.href = url;
                    return of(void 0);
                })
            );
    }

    public handleRedirect$(): Observable<AuthenticationState> {
        const currentUrl = new URL(this.window.location.href);
        const codeVerifier = this.window.localStorage.getItem(this.storageKeys.namAuthenticationCodeVerifier);

        return forkJoin([
            this.namOIDCConfig,
            of(currentUrl),
            of(codeVerifier),
        ]).pipe(
            concatMap(([oidc, url, verifier]) => {
                const headers = new Headers();
                headers.set('content-type', 'application/x-www-form-urlencoded');

                const fetchBody = new URLSearchParams();
                fetchBody.set('grant_type', 'authorization_code');
                fetchBody.set('code_verifier', verifier);
                fetchBody.set('code_challenge_method', 'S256');
                fetchBody.set('client_id', oidc.clientId);
                fetchBody.set('redirect_uri', oidc.redirectUri);
                const callbackParams = this.openIDConnectService.parseCallbackParameters(oidc, url);
                const authCode = callbackParams.get('code');
                fetchBody.set('code', authCode);

                const tokenUrl = new URL(oidc.authorizationServer.token_endpoint);
                const request = this.window.fetch(tokenUrl.href,
                    {
                        body: fetchBody,
                        headers,
                        method: 'POST',
                        redirect: 'manual',
                    });

                const tokenResponse = from(request);
                return this.openIDConnectService.validateTokenResponse$(oidc, tokenResponse);
            }),
            map((response: TokenResponse) => {
                const tokenInfo = JSON.parse(atob(response.accessToken.split('.')[1]))
                const claims = {
                    email: tokenInfo?.website,
                    guid: tokenInfo?.employeenumber,
                    firstName:'Employee',
                    lastName:'login'
                }
                const authenticationState = {
                    ...defaultAuthState,
                    accessToken: response.accessToken,
                    claims:claims,
                    isAuthenticated: true,
                }
                this.window.localStorage.setItem(this.storageKeys.namRefreshToken, response.refreshToken);
                this.saveAccessToken(authenticationState);
                return authenticationState;
            }),
            retry(5),
            catchError((error: Error) => {
                this.removeAccessToken();
                return of({ ...defaultAuthState, error });
            }),
            finalize(
                () => {
                this.removeVerifierCode();
            })
        );
    }

    public logout$(): Observable<boolean> {
        return forkJoin([
            this.namOIDCConfig.pipe(first()),
            this.authenticationState$().pipe(first()),
        ])
            .pipe(
                switchMap(([oidc, authState]) => {
                    const tokenUrl = new URL(oidc.authorizationServer.end_session_endpoint);

                    // ToDo: https://jira.gfs.com/jira/browse/WTRM-1655
                    //      NAM client app needs to be configured to provide the id token
                    //      (OAuth & OpenID Connect -> Client Application -> Token Types -> ID Token).
                    //      Federation team encountered an error, need to follow up
                    const url = [
                        tokenUrl.href,
                        `?id_token_hint=${authState.idToken}`
                    ].join('');

                    // Post body found in NAM documentation
                    // const fetchBody = new URLSearchParams();
                    // fetchBody.set('consentStatus', 'Accept');
                    // fetchBody.set('multiBrowserSession', 'no');
                    // const opts = { method: 'POST', body: fetchBody, };

                    const opts = { method: 'GET', };
                    const request = this.window.fetch(url, opts);

                    // NAM should redirect browser to its logout success page
                    this.removeAccessToken();
                    return from(request);
                }),
                map(response => {
                    if (!response.ok) {
                        throw Error(`Http ${response.status} from NAM end session endpoint`);
                    }

                    return true;
                }),
            );
    }

    public removeVerifierCode(): void {
        this.window.localStorage.removeItem(this.storageKeys.namAuthenticationCodeVerifier);
    }

    public saveAccessToken(authState: AuthenticationState): void {
        this.window.localStorage.setItem(this.storageKeys.namAccessToken, authState.accessToken);
        this.authStateSubject.next(authState);
    }

    public removeAccessToken(): void {
        this.window.localStorage.removeItem(this.storageKeys.namAccessToken);
        this.authStateSubject.next(defaultAuthState);
    }

    public getAuthState(): AuthenticationState {
        const token: string | null = this.window.localStorage.getItem(this.storageKeys.namAccessToken);

        if (!token) {
            return defaultAuthState;
        }

        const isExpired = this.isTokenExpired(token);
        if (isExpired) {
            this.removeAccessToken();
            return defaultAuthState;
        }

        return {
            ...defaultAuthState,
            accessToken: token,
            isAuthenticated: true,
        };
    }

    isTokenExpired(token: string): boolean {
        const payloadBase64 = token.split('.')[1]
        const payload = this.window.atob(payloadBase64);
        const expiration = JSON.parse(payload).exp * 1000
        const now = new Date().getTime();
        const isExpired = expiration < now;
        if (isExpired) {
            this.loginWithRedirect$();
        }
        return isExpired;
    }

    isTokenAboutToExpire(token: string): boolean {
        const payloadBase64 = token.split('.')[1]
        const payload = this.window.atob(payloadBase64);
        const expirationTime = JSON.parse(payload).exp * 1000;
        const timeNow = new Date().getTime();
        const isAboutToExpire = expirationTime - timeNow < 15 * 60000; //If less than 15 minutes for token to expire
        return isAboutToExpire;
    }
}

