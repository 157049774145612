import { HttpErrorResponse } from '@angular/common/http';
import {
  CountingUnit,
  CustomItemData, StorageArea, StorageAreaLastModified,
  StorageAreaUpdate, Worksheet, WorksheetItem, WorksheetItemGroupPost,
  WorksheetItemLastModified, WorksheetItemPost, WorksheetLastModified, WorksheetUpdate
} from '@gfs/shared-models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  SetItemFilterText = '[Worksheet] Set Item Filter Text',
  SetIsEditingName = '[Worksheet] Is Editing Name',
  SelectWorksheet = '[Worksheet] Select Worksheet',
  DeleteWorksheetAttempt = '[Worksheet] Delete Worksheet Attempt',
  DeleteWorksheetSuccess = '[Worksheet] Delete Worksheet Success',
  DeleteWorksheetError = '[Worksheet] Delete Worksheet Error',
  GetWorksheetAttempt = '[Worksheet] Get Worksheet Attempt',
  GetWorksheetSuccess = '[Worksheet] Get Worksheet Success',
  GetWorksheetError = '[Worksheet] Get Worksheet Error',
  SetStorageAreaExpandStatus = '[Worksheet] Set Storage Area Expand Status',
  CreateStorageAreaName = '[Worksheet] Create Storage Area Name',
  CreateStorageAreaNameSuccess = '[Worksheet] Create Storage Area Success',
  PatchStorageArea = '[Worksheet] Update Storage Area Name',
  PatchStorageAreaSuccess = '[Worksheet] Update Storage Area Name Success',
  DeleteStorageArea = '[Worksheet] Delete Storage Area',
  DeleteStorageAreaSuccess = '[Worksheet] Delete Storage Area Success',
  DeleteStorageAreasSuccessV2 = '[Worksheet] Delete Storage Area Success v2',
  UpdateWorksheetItem = '[Worksheet] Edit Worksheet Item Save',
  UpdateWorksheetItemSuccess = '[Worksheet] Edit Worksheet Item Save Success',
  UpdateWorksheetItemError = '[Worksheet] Edit Worksheet Item Save Error',
  DeleteStorageAreaError = '[Worksheet] Delete Storage Area Error',
  CreateBlankWorksheetAttempt = '[Worksheet] Create Blank Worksheet Attempt',
  CreateBlankWorksheetSuccess = '[Worksheet] Create Blank Worksheet Success',
  CreateBlankWorksheetError = '[Worksheet] Create Blank Worksheet Error',
  ImportWorksheetFromOrderGuideAttempt = '[Worksheet] Import Worksheet From Order Guide Attempt',
  ImportWorksheetFromOrderGuideSuccess = '[Worksheet] Import Worksheet From Order Guide Success',
  ImportWorksheetFromOrderGuideError = '[Worksheet] Import Worksheet From Order Guide Error',
  ImportWorksheetFromCustomGuideAttempt = '[Worksheet] Import Worksheet From Custom Guide Attempt',
  ImportWorksheetFromCustomGuideSuccess = '[Worksheet] Import Worksheet From Custom Guide Success',
  ImportWorksheetFromCustomGuideError = '[Worksheet] Import Worksheet From Custom Guide Error',
  DeleteInventoryItemAttempt = '[Worksheet] Inventory Item deleted locally',
  DeleteInventoryItem = '[Worksheet] Delete Inventory Item',
  DeleteInventoryItemSuccess = '[Worksheet] Delete Inventory Item Success',
  DeleteInventoryItemError = '[Worksheet] Delete Inventory Item Error',
  GetWorksheetSummaryAttempt = '[Inventory] Get Worksheet Summary Attempt',
  GetWorksheetSummarySuccess = '[Inventory] Get Worksheet Summary Success',
  GetWorksheetSummaryError = '[Inventory], Get Worksheet Summary Error',
  GetCustomGuidesAttempt = '[Inventory] Get Custom Guides Attempt',
  GetCustomGuidesSuccess = '[Inventory] Get Custom Guides Success',
  GetCustomGuidesError = '[Inventory] Get Custom Guides Error',
  PatchWorksheetAttempt = '[Worksheet] Patch Worksheet Attempt',
  PatchWorksheetSuccess = '[Worksheet] Patch Worksheet Success',
  PatchWorksheetError = '[Worksheet] Patch Worksheet Error',
  CloseWorksheetError = '[Worksheet] Close Worksheet Error',
  CopyWorksheetAttempt = '[Worksheet] Copy Worksheet Attempt',
  CopyWorksheetSuccess = '[Worksheet] Copy Worksheet Success',
  CopyWorksheetError = '[Worksheet] Copy Worksheet Error',
  CreateWorksheetItemAttempt = '[Worksheet] Create Worksheet Item Attempt',
  CreateWorksheetItemSuccess = '[Worksheet] Create Worksheet Item Success',
  CreateWorksheetItemError = '[Worksheet] Create Worksheet Item Error',
  CreateGroupWorksheetItemsAttempt = '[Worksheet] Create Group Worksheet Item Attempt',
  CreateGroupWorksheetItemsSuccess = '[Worksheet] Create Group Worksheet Item Success',
  CreateGroupWorksheetItemsError = '[Worksheet] Create Group Worksheet Item Error',
  ResetWorksheetAttempt = '[Worksheet] Reset Worksheet Attempt',
  ResetWorksheetSuccess = '[Worksheet] Reset Worksheet Success',
  ResetWorksheetError = '[Worksheet] Reset Worksheet Error',
  GetWorkSheetFromSummaryAttempt = '[Worksheet] Get Open Worksheet from Summary data',
  GetWorkSheetFromSummarySuccess = '[Worksheet] Get Open Worksheet from Summary data success',
  WorksheetSynchronizationAttempt = '[Worksheet] Worksheet Synchronization Attempt',
  GetAllInventoryItemsAttempt = '[Worksheet] Get All Inventory Items Attempt',
  SetWorksheetDone = '[Worksheet] Set Worksheet Done',
  GetCountingUnitsAttempt = '[Worksheet] Get Counting Units Attempt',
  GetCountingUnitsSuccess = '[Worksheet] Get Counting Units Success',
  GetCountingUnitsError = '[Worksheet] Get Counting Units Error',
  GetAllCustomItemDataAttempt = '[Worksheet] Get All Custom Item Data Attempt',
  GetAllCustomItemDataSuccess = '[Worksheet] Get All Custom Item Data Success',
  GetAllCustomItemDataError = '[Worksheet] Get All Custom Item Data Error',
  SetWorksheetLastModifiedDate = '[Worksheet] Set Last Modified Date',
  SetInfiniteScrollingToggleState = '[Worksheet] Set Infinite Scrolling State',
  SetGroupOperationsToggleState = '[Worksheet] Set Group Operation State',
  SetManageNonGfsbulkMode = '[Worksheet] Set Manage Non Gfs Bulk Mode State',
  ClearWorksheetFromState = '[Worksheet] Clear worksheet data from state',
  RefreshWorksheetItems = '[Worksheet] Refresh Worksheet Items',
  RefreshWorksheetItemsComplete = '[Worksheet] Refresh Worksheet Items Complete',
  RefreshWorksheetItemsError = '[Worksheet] Refresh Worksheet Items Error',
  MoveWorksheetItemToStorageArea = '[Inventory Items] Move Worksheet Item To Storage Area',
  SetPricingPreference = '[Worksheet] Set Preferred Pricing Options For User',
}

export class SetItemFilterText implements Action {
  readonly type = ActionTypes.SetItemFilterText;
  constructor(public itemFilterText: string) { }
}

export class SetIsEditingName implements Action {
  readonly type = ActionTypes.SetIsEditingName;
  constructor(public isEditingName: boolean) { }
}

export class SetPricingPreference implements Action {
  readonly type = ActionTypes.SetPricingPreference;
  constructor(public pricingPreference: string) { }
}

export class SelectWorksheet implements Action {
  readonly type = ActionTypes.SelectWorksheet;
  constructor(public payload: { worksheetId: string, shouldNavigate: boolean }) { }
}

export class DeleteWorksheetAttempt implements Action {
  readonly type = ActionTypes.DeleteWorksheetAttempt;
  constructor(public payload: Worksheet) { }
}

export class DeleteWorksheetSuccess implements Action {
  readonly type = ActionTypes.DeleteWorksheetSuccess;
  constructor(public payload: { worksheetLastModified: WorksheetLastModified, worksheet: Worksheet }) { }
}

export class DeleteWorksheetError implements Action {
  readonly type = ActionTypes.DeleteWorksheetError;
  constructor(public error: HttpErrorResponse) { }
}

export class GetWorksheetAttempt implements Action {
  readonly type = ActionTypes.GetWorksheetAttempt;
  constructor(public worksheetId: string) { }
}

export class GetWorksheetSuccess implements Action {
  readonly type = ActionTypes.GetWorksheetSuccess;
  constructor(public worksheet: Worksheet) { }
}

export class GetWorksheetError implements Action {
  readonly type = ActionTypes.GetWorksheetError;
  constructor(public error: HttpErrorResponse) { }
}

export class SetStorageAreaExpandStatus implements Action {
  readonly type = ActionTypes.SetStorageAreaExpandStatus;
  constructor(public payload: { status: boolean, areaId: string }) { }
}

export class CreateStorageAreaName implements Action {
  readonly type = ActionTypes.CreateStorageAreaName;
  constructor(public payload: { worksheetId: string, storageAreaName: string }) { }
}

export class CreateStorageAreaNameSuccess implements Action {
  readonly type = ActionTypes.CreateStorageAreaNameSuccess;
  constructor(public storageAreaLastModified: StorageAreaLastModified) { }
}

export class PatchStorageArea implements Action {
  readonly type = ActionTypes.PatchStorageArea;
  constructor(public payload: { worksheetId: string, storageAreaId: string, fields: StorageAreaUpdate }) { }
}

export class PatchStorageAreaSuccess implements Action {
  readonly type = ActionTypes.PatchStorageAreaSuccess;
  constructor(public payload: { worksheetLastModified: WorksheetLastModified, storageAreaId: string, fields: StorageAreaUpdate }) { }
}

export class DeleteStorageArea implements Action {
  readonly type = ActionTypes.DeleteStorageArea;
  constructor(public payload: { worksheetId: string, storageArea: StorageArea }) { }
}

export class DeleteStorageAreaSuccess implements Action {
  readonly type = ActionTypes.DeleteStorageAreaSuccess;
  constructor(public payload: { worksheetLastModified: WorksheetLastModified, storageArea: StorageArea }) { }
}

export class DeleteStorageAreasSuccessV2 implements Action {
  readonly type = ActionTypes.DeleteStorageAreasSuccessV2;
  constructor(public payload: { worksheetId: string, storageAreaId: string }) { }
}

export class UpdateWorksheetItem implements Action {
  readonly type = ActionTypes.UpdateWorksheetItem;
  constructor(public payload: { worksheetId: string, storageAreaId: string, worksheetItem: WorksheetItem }) { }
}

export class UpdateWorksheetItemSuccess implements Action {
  readonly type = ActionTypes.UpdateWorksheetItemSuccess;
  constructor(public payload: { worksheetLastModified: WorksheetLastModified, storageAreaId: string, worksheetItem: WorksheetItem }) { }
}

export class SetWorksheetLastModifiedDate implements Action {
  readonly type = ActionTypes.SetWorksheetLastModifiedDate;
  constructor(public payload: {
    worksheetLastModifiedDate: string,
  }) { }
}

export class UpdateWorksheetItemError implements Action {
  readonly type = ActionTypes.UpdateWorksheetItemError;
  constructor(public error: HttpErrorResponse, public payload: { worksheetItem: WorksheetItem }) { }
}

export class DeleteStorageAreaError implements Action {
  readonly type = ActionTypes.DeleteStorageAreaError;
  constructor(public error: HttpErrorResponse) { }
}

export class CreateBlankWorksheetAttempt implements Action {
  readonly type = ActionTypes.CreateBlankWorksheetAttempt;
  constructor(public worksheetPricingOptions:string) { }
}

export class CreateBlankWorksheetSuccess implements Action {
  readonly type = ActionTypes.CreateBlankWorksheetSuccess;
  constructor(public worksheet: Worksheet) { }
}

export class CreateBlankWorksheetError implements Action {
  readonly type = ActionTypes.CreateBlankWorksheetError;
  constructor(public error: HttpErrorResponse) { }
}

export class ImportWorksheetFromOrderGuideAttempt implements Action {
  readonly type = ActionTypes.ImportWorksheetFromOrderGuideAttempt;
  constructor(public sorting: boolean , public worksheetPricingOptions:string) { }
}

export class ImportWorksheetFromOrderGuideSuccess implements Action {
  readonly type = ActionTypes.ImportWorksheetFromOrderGuideSuccess;
  constructor(public worksheet: Worksheet) { }
}

export class ImportWorksheetFromOrderGuideError implements Action {
  readonly type = ActionTypes.ImportWorksheetFromOrderGuideError;
  constructor(public error: HttpErrorResponse) { }
}

export class ImportWorksheetFromCustomGuideAttempt implements Action {
  readonly type = ActionTypes.ImportWorksheetFromCustomGuideAttempt;
  constructor(public customGuideIds: string[], public sorting: string , public worksheetPricingOptions: string) { }
}

export class ImportWorksheetFromCustomGuideSuccess implements Action {
  readonly type = ActionTypes.ImportWorksheetFromCustomGuideSuccess;
  constructor(public worksheet: Worksheet) { }
}

export class ImportWorksheetFromCustomGuideError implements Action {
  readonly type = ActionTypes.ImportWorksheetFromCustomGuideError;
  constructor(public error: HttpErrorResponse) { }
}

export class DeleteInventoryItemAttempt implements Action {
  readonly type = ActionTypes.DeleteInventoryItemAttempt;
  constructor(public payload: { worksheetId: string, storageAreaId: string, inventoryItem: WorksheetItem }) { }
}

export class DeleteInventoryItem implements Action {
  readonly type = ActionTypes.DeleteInventoryItem;
  constructor(public payload: { worksheetId: string, storageAreaId: string, inventoryItem: WorksheetItem }) { }
}


export class DeleteInventoryItemSuccess implements Action {
  readonly type = ActionTypes.DeleteInventoryItemSuccess;
  constructor(public payload: { worksheetLastModified: WorksheetLastModified, inventoryItem: WorksheetItem }) { }
}

export class DeleteInventoryItemError implements Action {
  readonly type = ActionTypes.DeleteInventoryItemError;
  constructor(public error: HttpErrorResponse) { }
}

export class GetWorksheetSummaryAttempt implements Action {
  readonly type = ActionTypes.GetWorksheetSummaryAttempt;
  constructor(public routeToInProgress?: boolean) { }
}

export class GetWorksheetSummarySuccess implements Action {
  readonly type = ActionTypes.GetWorksheetSummarySuccess;
  constructor(public worksheets: Worksheet[], public openWorksheet: Worksheet, public routeToInProgress?: boolean) { }
}

export class GetWorksheetSummaryError implements Action {
  readonly type = ActionTypes.GetWorksheetSummaryError;
  constructor(public error: HttpErrorResponse) { }
}

export class GetCustomGuidesAttempt implements Action {
  readonly type = ActionTypes.GetCustomGuidesAttempt;
}

export class GetCustomGuidesSuccess implements Action {
  readonly type = ActionTypes.GetCustomGuidesSuccess;
  constructor(public customGuides: any[]) { }
}

export class GetCustomGuidesError implements Action {
  readonly type = ActionTypes.GetCustomGuidesError;
  constructor(public error: HttpErrorResponse) { }
}

export class PatchWorksheetAttempt implements Action {
  readonly type = ActionTypes.PatchWorksheetAttempt;
  constructor(public payload: { worksheetId: string, fields: WorksheetUpdate }) { }
}

export class PatchWorksheetSuccess implements Action {
  readonly type = ActionTypes.PatchWorksheetSuccess;
  constructor(public payload: {
    worksheetLastModified: WorksheetLastModified,
    fields: WorksheetUpdate,
  }) { }
}

export class PatchWorksheetError implements Action {
  readonly type = ActionTypes.PatchWorksheetError;
  constructor(public error: HttpErrorResponse) { }
}

export class CloseWorksheetError implements Action {
  readonly type = ActionTypes.CloseWorksheetError;
  constructor(public error: HttpErrorResponse) { }
}

export class CopyWorksheetAttempt implements Action {
  readonly type = ActionTypes.CopyWorksheetAttempt;
  constructor(public worksheetId: string) { }
}

export class CopyWorksheetSuccess implements Action {
  readonly type = ActionTypes.CopyWorksheetSuccess;
  constructor(public worksheet: Worksheet) { }
}

export class CopyWorksheetError implements Action {
  readonly type = ActionTypes.CopyWorksheetError;
  constructor(public error: HttpErrorResponse) { }
}

export class CreateWorksheetItemAttempt implements Action {
  readonly type = ActionTypes.CreateWorksheetItemAttempt;
  constructor(public payload: {
    worksheetId: string,
    storageAreaId: string,
    worksheetItemPost: WorksheetItemPost,
    areaExpandStatus: boolean,
    itemDescription: string,
    successMessage: string,
    duplicate: boolean,
  }) { }
}

export class CreateWorksheetItemSuccess implements Action {
  readonly type = ActionTypes.CreateWorksheetItemSuccess;
  constructor(public payload: {
    worksheetItemLastModified: WorksheetItemLastModified,
    storageAreaId: string,
    areaExpandStatus: boolean,
    itemDescription: string,
    successMessage: string,
    duplicate: boolean,
  }) { }
}

export class CreateWorksheetItemError implements Action {
  readonly type = ActionTypes.CreateWorksheetItemError;
  constructor(public error: HttpErrorResponse) { }
}

export class CreateGroupWorksheetItemsAttempt implements Action {
  readonly type = ActionTypes.CreateGroupWorksheetItemsAttempt;
  constructor(public payload: {
    worksheetItemGroupPost: WorksheetItemGroupPost,
    itemDescription: string,
    successMessage: string,
  }) { }
}

export class CreateGroupWorksheetItemsSuccess implements Action {
  readonly type = ActionTypes.CreateGroupWorksheetItemsSuccess;
  constructor(public payload: {
    worksheetItemsLastModified: WorksheetItemLastModified[],
    itemDescription: string,
    successMessage: string,
  }) { }
}

export class CreateGroupWorksheetItemsError implements Action {
  readonly type = ActionTypes.CreateGroupWorksheetItemsError;
  constructor(public error: HttpErrorResponse) { }
}

export class ResetWorksheetAttempt implements Action {
  readonly type = ActionTypes.ResetWorksheetAttempt;
  constructor(public payload: {
    worksheetToCloseId: string,
    worksheetImportAction: Action,
  }) { }
}

export class ResetWorksheetSuccess implements Action {
  readonly type = ActionTypes.ResetWorksheetSuccess;
  constructor(public worksheetImportAction: Action) { }
}

export class ResetWorksheetError implements Action {
  readonly type = ActionTypes.ResetWorksheetError;
  constructor(public error: HttpErrorResponse) { }
}

export class GetWorkSheetFromSummaryAttempt implements Action {
  readonly type = ActionTypes.GetWorkSheetFromSummaryAttempt;
  constructor(public worksheets: Worksheet[], public routeToInProgress?: boolean) { }
}

export class GetWorkSheetFromSummarySuccess implements Action {
  readonly type = ActionTypes.GetWorkSheetFromSummarySuccess;
  constructor(
    public worksheets: Worksheet[],
    public openWorkSheet: Worksheet,
    public routeToInProgress?: boolean
  ) { }
}

export class WorksheetSynchronizationAttempt implements Action {
  readonly type = ActionTypes.WorksheetSynchronizationAttempt;
}

export class GetAllInventoryItemsAttempt implements Action {
  readonly type = ActionTypes.GetAllInventoryItemsAttempt;
  constructor(public worksheetId: string) { }
}

export class SetWorksheetDone implements Action {
  readonly type = ActionTypes.SetWorksheetDone;
  constructor(public payload: { worksheetId: string, fields: WorksheetUpdate }) { }
}

export class GetCountingUnitsAttempt implements Action {
  readonly type = ActionTypes.GetCountingUnitsAttempt;
}

export class GetCountingUnitsSuccess implements Action {
  readonly type = ActionTypes.GetCountingUnitsSuccess;
  constructor(public payload: CountingUnit[]) { }
}

export class GetCountingUnitsError implements Action {
  readonly type = ActionTypes.GetCountingUnitsError;
  constructor(public error: any) { }
}

export class GetAllCustomItemDataAttempt implements Action {
  readonly type = ActionTypes.GetAllCustomItemDataAttempt;
}

export class GetAllCustomItemDataSuccess implements Action {
  readonly type = ActionTypes.GetAllCustomItemDataSuccess;
  constructor(public customItemData: CustomItemData[]) { }
}

export class GetAllCustomItemDataError implements Action {
  readonly type = ActionTypes.GetAllCustomItemDataError;
  constructor(public error: any) { }
}
export class ClearWorksheetFromState implements Action {
  readonly type = ActionTypes.ClearWorksheetFromState;
  constructor(public worksheetId: string) { }
}

export class SetInfiniteScrollingToggleState implements Action {
  readonly type = ActionTypes.SetInfiniteScrollingToggleState;
  constructor(public toggleState: boolean) {
  }
}

export class SetGroupOperationToggleState implements Action {
  readonly type = ActionTypes.SetGroupOperationsToggleState;
  constructor(public toggleState: boolean) {
  }
}

export class SetManageNonGfsbulkMode implements Action {
  readonly type = ActionTypes.SetManageNonGfsbulkMode;
  constructor(public toggleState: boolean) {
  }
}

export class RefreshWorksheetItems implements Action {
  readonly type = ActionTypes.RefreshWorksheetItems;
}

export class RefreshWorksheetItemsComplete implements Action {
  readonly type = ActionTypes.RefreshWorksheetItemsComplete;
}

export class RefreshWorksheetItemsError implements Action {
  readonly type = ActionTypes.RefreshWorksheetItemsError;
  constructor(public error: HttpErrorResponse) { }
}

export class MoveWorksheetItemToStorageArea implements Action {
  readonly type = ActionTypes.MoveWorksheetItemToStorageArea;
  constructor(public payload: {
    worksheetId: string,
    initialStorageAreaId: string,
    destinationStorageAreaId: string,
    worksheetItemPost: WorksheetItemPost,
    areaExpandStatus: boolean,
    itemDescription: string,
    successMessage: string,
    duplicate: boolean,
    inventoryItem: WorksheetItem,
  }) { }
}

export type ActionUnion =
  SetItemFilterText |
  SetIsEditingName |
  SelectWorksheet |
  DeleteWorksheetAttempt |
  DeleteWorksheetSuccess |
  DeleteWorksheetError |
  GetWorksheetAttempt |
  GetWorksheetSuccess |
  GetWorksheetError |
  SetStorageAreaExpandStatus |
  CreateStorageAreaName |
  CreateStorageAreaNameSuccess |
  PatchStorageArea |
  PatchStorageAreaSuccess |
  DeleteStorageArea |
  DeleteStorageAreaSuccess |
  UpdateWorksheetItem |
  UpdateWorksheetItemSuccess |
  UpdateWorksheetItemError |
  DeleteStorageAreaError |
  CreateBlankWorksheetAttempt |
  CreateBlankWorksheetSuccess |
  CreateBlankWorksheetError |
  ImportWorksheetFromOrderGuideAttempt |
  ImportWorksheetFromOrderGuideSuccess |
  ImportWorksheetFromOrderGuideError |
  ImportWorksheetFromCustomGuideAttempt |
  ImportWorksheetFromCustomGuideSuccess |
  ImportWorksheetFromCustomGuideError |
  DeleteInventoryItem |
  DeleteInventoryItemSuccess |
  DeleteInventoryItemError |
  PatchWorksheetAttempt |
  PatchWorksheetSuccess |
  PatchWorksheetError |
  CloseWorksheetError |
  GetWorksheetSummaryAttempt |
  GetWorksheetSummaryError |
  GetWorksheetSummarySuccess |
  CreateWorksheetItemAttempt |
  CreateWorksheetItemSuccess |
  CreateWorksheetItemError |
  CreateGroupWorksheetItemsAttempt |
  CreateGroupWorksheetItemsSuccess |
  CreateGroupWorksheetItemsError |
  DeleteInventoryItemAttempt |
  GetCustomGuidesAttempt |
  GetCustomGuidesError |
  GetCustomGuidesSuccess |
  CopyWorksheetAttempt |
  CopyWorksheetSuccess |
  CopyWorksheetError |
  ResetWorksheetAttempt |
  ResetWorksheetSuccess |
  ResetWorksheetError |
  GetWorkSheetFromSummaryAttempt |
  GetWorkSheetFromSummarySuccess |
  WorksheetSynchronizationAttempt |
  GetAllInventoryItemsAttempt |
  SetWorksheetDone |
  GetCountingUnitsAttempt |
  GetCountingUnitsSuccess |
  GetCountingUnitsError |
  GetAllCustomItemDataAttempt |
  GetAllCustomItemDataSuccess |
  GetAllCustomItemDataError |
  SetWorksheetLastModifiedDate |
  SetInfiniteScrollingToggleState |
  ClearWorksheetFromState |
  RefreshWorksheetItems |
  RefreshWorksheetItemsComplete |
  RefreshWorksheetItemsError |
  MoveWorksheetItemToStorageArea |
  DeleteStorageAreasSuccessV2  | 
  SetGroupOperationToggleState | 
  SetManageNonGfsbulkMode |
  SetIsEditingName | 
  SetPricingPreference
