import { Inject, Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, mergeMap, map, withLatestFrom, switchMap, concatMap } from 'rxjs/operators';
import {
  ActionTypes,
  ActionUnion,
  GetCartDataError,
  GetCartDataSuccess
} from './actions';
import { of } from 'rxjs';
import { FeatureState } from './state';
import { Store } from '@ngrx/store';
import { InventoryConstants } from '@gfs/constants';
import { InventoryService } from '@gfs/shared-services/services/inventory.service';
import { RecipeService } from '@gfs/shared-services/services/recipe.service';
import { AppState } from '@gfs/store/recipe/reducers';
import { CartService } from '../cart-service/cart.service';

@Injectable()
export class CartEffects {


  getCartDataAttempt$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetCartDataAttempt),
    withLatestFrom(this.store),
    mergeMap(([action, state]) => {
      const existentCart =
        state.cartFeature?.cartsQuantities?.[action.payload.customerPK.customerId];
      if (existentCart && !action.payload.includePrice) {
        return of(
          new GetCartDataSuccess({
            cart: existentCart,
            customerId: action.payload.customerPK.customerId,
            itemId:null
          })
        );
      }

      // only call the /carts service if the entity is not in the GROUP_ENTITIES
      if (!InventoryConstants.GROUP_ENTITIES.includes(action.payload.customerPK.entityType)) {
        return this.inventoryService
          .getCartData(action.payload.customerPK, action.payload.includePrice)
          .pipe(map((cart) =>
            new GetCartDataSuccess({
              cart,
              customerId: action.payload.customerPK.customerId,
              itemId:null
            })
          ), catchError((err) => of(new GetCartDataError(err))));
      } else {
        return [];
      }
    })
  ));

  addIngredientToCartAttempt$ = createEffect(()=> this.actions$.pipe(
    ofType(ActionTypes.AddToCartAttempt),
    withLatestFrom(this.store),
    mergeMap(([action, state])=>{
    return this.cartService.addIngredientToCart({
        itemId:action.payload.addToCartData.itemId ,
         quantity:'1' , //Change to original data later
         unitType:'CS',
         customerPK:state.auth.pk,
         includePrice: true
    }).pipe(
        map((cart)=> new GetCartDataSuccess({cart ,customerId : state.auth.pk.customerId,itemId:action.payload.addToCartData.itemId}),
      ),
      )
    }),
    catchError((err) => of(new GetCartDataError(err)))
  ));

  constructor(
    private actions$: Actions<ActionUnion>,
    private inventoryService: InventoryService,
    private cartService : CartService,
    private store: Store<AppState>,
  ){}
}
