import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResolveLocalizationsInObject } from '@gfs/shared-services';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'libs/shared-services/src/features/analytics-service/analytics-service.service'

export interface DialogData {
  title: string;
  titleData: string | {};
  subtitle?: string;
  type?:string;
  cancelButtonId?: string;
  cancelButtonAriaLabel?: string;
  cancelButtonLabel?: string;
  submitButtonId?: string;
  submitButtonAriaLabel?: string;
  submitButtonLabel?: string;
  returnData?: any;
}

@Component({
  selector: 'gfs-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

  get title() {
    return this.data.title || '';
  }
  get titleData() {
    return this.data.titleData ;
  }
  get type() {
    return this.data.type ;
  }
  get subtitle() {
    return this.data.subtitle || null;
  }
  get cancelButtonId() {
    return this.data.cancelButtonId || 'confirmation-cancel-button';
  }
  get cancelButtonAriaLabel() {
    return this.data.cancelButtonAriaLabel || 'MODALS.CANCEL_BUTTON_ARIA_LABEL';
  }
  get cancelButtonLabel() {
    return this.data.cancelButtonLabel || 'MODALS.CANCEL';
  }
  get submitButtonId() {
    return this.data.submitButtonId || 'confirmation-submit-button';
  }
  get submitButtonAriaLabel() {
    return this.data.submitButtonAriaLabel || 'MESSAGES.OKAY';
  }
  get submitButtonLabel() {
    return this.data.submitButtonLabel || 'MESSAGES.OKAY';
  }

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    private translate: TranslateService,
    private AnalyticsService: AnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    if(this.type !== 'GROUP_MOVE' && this.type !== 'GROUP_DELETE'){
      data.titleData = ResolveLocalizationsInObject(this.data.titleData || {}, this.translate.currentLang);
    } else {
      if(this.type == 'GROUP_DELETE'){
      this.data.title = 'STORAGE_AREA.DELETE_STORAGE_AREA_ITEM_MODAL.REMOVE_GROUP_DELETE_ITEMS'
      } else if(this.type == 'GROUP_MOVE'){
        this.data.title = 'STORAGE_AREA.GROUP_MOVE_ITEMS_TO_STORAGE_AREA.MOVE_GROUP_ITEMS'
      }
    }
    this.dialogRef.addPanelClass('confirmation-item-dialog');
  }

  closeModal() {
    this.data.returnData = null;
    this.dialogRef.close();
  }

  submit() {
    if(this.submitButtonId == "close-worksheet-button"){
      this.AnalyticsService.GA4EventLogger('close_inventory','')
    }
    this.dialogRef.close(this.data.returnData);
  }
}