import { Component, Inject, Injectable } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Router } from '@angular/router';
import { InventoryConstants } from '@gfs/constants';
import { CustomGuide, CustomerPK, Worksheet } from '@gfs/shared-models';
import { DataLayerService, InventoryUtilsService } from '@gfs/shared-services';
import {
  CreateBlankWorksheetAttempt,
  GetCustomGuidesAttempt,
  GetWorksheetSummaryAttempt,
  ImportWorksheetFromCustomGuideAttempt,
  ImportWorksheetFromOrderGuideAttempt,
  ResetWorksheetAttempt
} from '@gfs/store/inventory/actions/worksheets.actions';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { concatMap, withLatestFrom, first, map, filter, tap } from 'rxjs/operators';
import { LegacyCustomerMigrationFacade } from '../legacy-customer-migration/facade/legacy-customer-migration.facade';
import { MigrationResult } from '../legacy-customer-migration/models/inventory-migration.model';
import { LoadingDialogData, LoadingModalComponent } from '../loading-modal/loading-modal.component';

@Component({
  selector: 'gfs-inventory-worksheet-wizard',
  templateUrl: './inventory-worksheet-wizard.component.html',
  styleUrls: ['./inventory-worksheet-wizard.component.scss']
})
export class InventoryWorksheetWizardComponent {

  public static get GetLoadingModalId() {
    return InventoryConstants.LOADING_MODAL_ID + '_RESET_WORKSHEET';
  }

  importStep: number;
  importType: string;
  organizationType: string;
  selectedCustomGuides: CustomGuide[] = [];
  categoriesCount = {};
  worksheetPricingOptions: any;
  blankWorkheetFlow: boolean = false
  isMobile$ = this.store.select(state => state.layout.isMobile);
  customGuides$ = this.store.select(state => state.worksheets.customGuides).pipe(
    map(guides => guides.filter(guide =>
      guide.categories.some(cat => cat.materialNumbers?.length > 0)
    ))
  );

  worksheetSummaries$ = this.store
    .select((state) => state.worksheets.worksheets)
    .pipe(map(InventoryUtilsService.sortSummaries));

  constructor(
    private store: Store<AppState>,
    private dataLayerServce: DataLayerService,
    public dialogRef: MatDialogRef<InventoryWorksheetWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private translateService: TranslateService,
  ) {
    this.importStep = 1;
    this.importType = null;
    this.organizationType = null;
  }

  public static openInventoryWorksheetWizard(
    resetCurrentSheet: boolean,
    store: Store<AppState>,
    matDialog: MatDialog,
    migrationFacade: LegacyCustomerMigrationFacade,
    router: Router,
  ): void {

    store.dispatch(new GetWorksheetSummaryAttempt(false));
    const localWorksheetSummaries$ = store.select(state => state.worksheets.worksheets)
      .pipe(map(InventoryUtilsService.sortSummaries));

    store.dispatch(new GetCustomGuidesAttempt());

    localWorksheetSummaries$
      .pipe(
        first(),
        withLatestFrom(store.select(state => ({
          pk: state.auth.pk,
          isMobile: state.layout.isMobile
        }))),
        concatMap(([worksheets, { pk, isMobile }]) => migrationFacade
          .migrateCustomerInventoryData(pk, isMobile)
          .pipe(map(migrationResult => ({ migrationResult, worksheets ,pk })))),
        filter(({ migrationResult }) => this.userContinuedWorkflow(migrationResult)),
        tap(({ migrationResult }) => this.getWorksheetSummary(migrationResult, store)),
        withLatestFrom(store.select(state => state.layout.isMobile)),
        concatMap(([{ migrationResult, worksheets ,pk }, isMobile]) => {
          if (migrationResult.options.migrateWorksheet) {
            router.navigateByUrl('take-inventory');
            return of(false);
          }

          return this.popInventoryResetWizard(
            isMobile,
            matDialog,
            resetCurrentSheet,
            worksheets,
            pk
            );
        })
      )
      .subscribe();
  }

  static getWorksheetSummary(
    migrationResult: MigrationResult,
    store: Store<AppState>
  ): void {
    if (migrationResult.options.migrateWorksheet) {
      store.dispatch(new GetWorksheetSummaryAttempt(false));
    }
  }

  static userContinuedWorkflow(migrationResult: MigrationResult): boolean {
    return !migrationResult.escapeWorkflow;
  }

  static getWorksheetToClose(worksheets: Worksheet[] , pk : CustomerPK): string {
    let worksheetId =''
   if(worksheets[0]?.customerPK.customerId === pk.customerId){
    worksheetId = worksheets[0]?.id
   } else{
    worksheetId = ''
   }
   return worksheetId
  }

  static getResetWorksheetState(worksheets: Worksheet[], resetCurrentSheet: boolean ,pk): boolean {
    return (worksheets.length && (InventoryWorksheetWizardComponent.getWorksheetToClose(worksheets ,pk) !== '')) ? resetCurrentSheet : false;
  }

  static getDialogDimensions(isMobile: boolean): { width: string, height: string } {
    const width = isMobile ? '100vw' : '840px';
    const height = isMobile ? '100vh' : '600px';
    return { width, height };
  }

  static popInventoryResetWizard(
    isMobile: boolean,
    matDialog: MatDialog,
    resetCurrentSheet: boolean,
    worksheets: Worksheet[],
    pk:CustomerPK
  ): Observable<boolean> {

    const wizardDialogRef = matDialog.open(InventoryWorksheetWizardComponent, {
      panelClass: 'worksheet-wizard-dialog-container',
      data: {
        isReset: this.getResetWorksheetState(worksheets, resetCurrentSheet ,pk),
        worksheetToClose: this.getWorksheetToClose(worksheets , pk)
      }
    });

    const dimensions = this.getDialogDimensions(isMobile);
    wizardDialogRef.updateSize(dimensions.width, dimensions.height);

    return wizardDialogRef.afterClosed().pipe(map(() => true));
  }

  public static GetLoadingModalConfig(isMobile: boolean): MatDialogConfig {
    const dimensions = this.getLoadingModalDimensions(isMobile);
    return {
      id: this.GetLoadingModalId,
      panelClass: 'recipe-mat-dialog',
      disableClose: true,
      height: dimensions.height,
      width: dimensions.width
    };
  }

  static getLoadingModalDimensions(isMobile: boolean): { height: string, width: string } {
    return isMobile
      ? { height: '245px', width: '276px' }
      : { height: '288px', width: '380px' };
  }

  static GetLoadingSpinnerConfig(
    isMobile: boolean,
    translateService: TranslateService
  ): MatDialogConfig<any> {
    const modalData: LoadingDialogData = {
      spinnerClass: 'worksheet-import-spinner',
      title: translateService.instant('INVENTORY_WORKSHEET_WIZARD.IMPORT_LOADING_TITLE'),
      description: translateService.instant('INVENTORY_WORKSHEET_WIZARD.IMPORT_LOADING_DESCRIPTION')
    };

    const config = InventoryWorksheetWizardComponent.GetLoadingModalConfig(isMobile);
    config.data = modalData;
    return config;
  }

  createBlankWorksheet() {
    this.openLoadingModal();
    this.dialogRef.afterClosed().pipe(first()).subscribe(_ => {
      if (this.data.isReset) {
        this.store.dispatch(new ResetWorksheetAttempt({
          worksheetToCloseId: this.data.worksheetToClose,
          worksheetImportAction: new CreateBlankWorksheetAttempt(this.worksheetPricingOptions)
        }));
      } else {
        this.store.dispatch(new CreateBlankWorksheetAttempt(this.worksheetPricingOptions));
      }
    });
    this.dialogRef.close();
  }

  onNextClicked() {
    this.importStep = this.importStep + 1
    if (this.importType === 'custom-guide') {
      this.dataLayerServce.push({ event: 'customGuideSelected' });
    }
    if(this.importType === 'blank-worksheet'){
      this.blankWorkheetFlow = true
      this.importStep = 2
    } else {
      this.blankWorkheetFlow = false
    }
  }

  onImportTypeChange(event: MatRadioChange) {
    this.importType = event.value;
  }

  onPriceTypeChange(event: MatRadioChange) {
    this.worksheetPricingOptions =event.value
  }

  onOrganizationChange(event: MatRadioChange) {
    this.organizationType = event.value;
  }

  onCustomGuideSectionClicked() {
    this.importType = 'custom-guide';
  }

  onCustomGuideOrganizationSectionClicked() {
    this.organizationType = 'custom-guide-organization';
  }

  onCustomGuideSelected(event: MatCheckboxChange) {
    const value = event.source.value as any;
    if (event.checked) {
      this.selectedCustomGuides.push(value);
    } else {
      const index = this.selectedCustomGuides.findIndex(cg => value.id === cg.id);
      if (index >= 0) {
        this.selectedCustomGuides.splice(index, 1);
      }
    }
  }

  backButton() {
    this.importStep = this.importStep -1;
  }

  private openLoadingModal() {
    this.isMobile$.subscribe(isMobile => {
      const config = InventoryWorksheetWizardComponent.GetLoadingSpinnerConfig(isMobile, this.translateService);
      this.dialog.open(LoadingModalComponent, config);
    });
  }

  fireImport() {
    this.openLoadingModal();
    let worksheetImportAction;
    switch (this.importType) {
      case 'order-guide': {
        const sorting = this.organizationType !== 'do-not-organize';
        worksheetImportAction = new ImportWorksheetFromOrderGuideAttempt(sorting , this.worksheetPricingOptions);
        this.dialogRef.afterClosed().pipe(first()).subscribe(_ => {
          this.dataLayerServce.push({ event: this.getGADoneEvent() });
        });
        break;
      }
      case 'custom-guide': {
        let sorting;
        switch (this.organizationType) {
          case 'use-storage-areas': {
            sorting = 'storageAreas';
            break;
          } case 'custom-guide-organization': {
            sorting = 'customGuide';
            break;
          } default: {
            sorting = 'none';
          }
        }
        const customGuideIds = this.selectedCustomGuides.map(cg => cg.id);
        worksheetImportAction = new ImportWorksheetFromCustomGuideAttempt(customGuideIds, sorting ,this.worksheetPricingOptions);
        this.dialogRef.afterClosed().pipe(first()).subscribe(_ => {
          this.dataLayerServce.push({ event: this.getGADoneEvent() });
        });
        break;
      }
      case 'blank-worksheet':{
        this.createBlankWorksheet()
      }
    }
    if (this.data.isReset) {
      this.store.dispatch(new ResetWorksheetAttempt({
        worksheetToCloseId: this.data.worksheetToClose,
        worksheetImportAction
      }));
    } else {
      this.store.dispatch(worksheetImportAction);
    }
    this.dialogRef.close();
  }

  getCustomGuidesCategories() {
    this.categoriesCount = {};
    return this.selectedCustomGuides.reduce((prev, cg) => {
      const customGuides = cg.categories.filter(cat => !!cat.name).map(cat => {
        this.categoriesCount[cat.name] = this.categoriesCount[cat.name] || 0;
        this.categoriesCount[cat.name]++;
        return { customGuide: cg.name, category: cat.name };
      });
      return prev.concat(customGuides);
    }, []);
  }

  formatCategory(customGuide) {
    if (this.categoriesCount[customGuide.category] > 1) {
      return `${customGuide.category} - ${customGuide.customGuide}`;
    }

    return customGuide.category;
  }

  getGADoneEvent() {
    let event = '';
    switch (this.importType) {
      case 'order-guide': {
        event = 'orderGuide';
        break;
      }
      case 'custom-guide': {
        event = 'customGuide';
        break;
      }
    }

    switch (this.organizationType) {
      case 'use-storage-areas': {
        event += 'UseStorageAreas';
        break;
      }
      case 'do-not-organize': {
        event += 'DoNotOrganize';
        break;
      }
      case 'custom-guide-organization': {
        event += 'CustomGuideOrganization';
        break;
      }
    }

    return event;
  }

  get isDisabled() {
    switch (this.importType) {
      case 'order-guide':
        return false;
      case 'custom-guide':
        return !this.selectedCustomGuides.length;
      case 'blank-worksheet' :
        return false
      default:
        return true;
    }
  }

  get isOrganiseNextDisabled() {
    return this.organizationType ? false : true
  }

  get isCustomGuide() {
    return this.importType === 'custom-guide';
  }
}


@Injectable({ providedIn: 'root' })
export class InventoryWorksheetWizardModalService {
  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private migrationFacade: LegacyCustomerMigrationFacade,
    private router: Router,
  ) {

  }

  open({ resetCurrentSheet }) {
    return InventoryWorksheetWizardComponent.openInventoryWorksheetWizard(
      resetCurrentSheet,
      this.store,
      this.dialog,
      this.migrationFacade,
      this.router);
  }
}
