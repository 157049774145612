
<div class="CTA-Container">
    <div class="print-container" >
      <button class="primary-button" (click)="downloadAsPDF()">{{ 'RECIPE_SUMMARY_REPORTING.DOWNLOAD_PDF' | translate }}</button>
    </div>
  <div class="no-print close-button">
      <button class="close primary-button" (click)="dialogRef.close()">
        {{ 'RECIPE_SUMMARY_REPORTING.BACK_TO_REPORTS' | translate }}
      </button>
    </div>
  </div>

<div class="dialog-content" #pdfTable>
    <div class="display printable-container"  id="pdfTable">
      <div class="summary-table" id="header">
                <th class="summary-header">
                    <h3> {{ 'RECIPE_SUMMARY_REPORTING.RECIPE_SUMMARY_REPORT' | translate }} </h3>
                    <p class="customer-info">{{ currentCustomer$ | async }} - {{ customerId$ | async}} </p>
                </th>
            </div>
        <div>
            <div  *ngFor="let recipe of recipeWithCategoryList ; index as i">
              <div *ngIf="!(recipe.items.length === 0)" >
                <tr class="category-row" >
                  <td >
                    <h3>{{ 'RECIPE_SUMMARY_REPORTING.CATEGORY' | translate }} </h3>
                  <td class="category-names"><h3>{{ (categoryNames[recipe.categoryid] === undefined ?  ('RECIPE_SUMMARY_REPORTING.UNASSIGNED_CATEGORY_LABEL' | translate) : (categoryUtils.getRecipeSummaryTranslation(categoryNames[recipe.categoryid]) | translate | truncated:17)) }}</h3></td>
                  <td  class="category-headers"> <h3> {{ 'RECIPE_SUMMARY_REPORTING.PORTION_PRICE' | translate }} </h3></td>
                  <td  class="category-headers"><h3>  {{ 'RECIPE_SUMMARY_REPORTING.PORTION_DESCRIPTION' | translate }} </h3></td>
                  <td  class="category-headers"><h3>  {{ 'RECIPE_SUMMARY_REPORTING.SELLING_PRICE' | translate }} </h3></td>
                  <td  class="category-headers"><h3>  {{ 'RECIPE_SUMMARY_REPORTING.GROSS_PROFIT' | translate }} </h3></td>
                  <td  class="category-headers"><h3> {{ 'RECIPE_SUMMARY_REPORTING.FOOD_COST_%' | translate }} </h3></td>
                </tr>
               <tr class="category-value" *ngFor="let recipedata of recipe.items">
                  <td Class="empty-row"></td>
                  <td class="recipe-name"> {{ recipedata.name }} </td>
                  <td class="portion-price table-data"> {{ recipedata.recipePrice?.totalCost?.toFixed(2) | currency:'CAD':'':undefined:(currentLang$ | async)}} </td>
                  <td class="portion-desc"> {{ '(1.0) ' + 'Each' }} </td>
                  <td class="table-data"> {{ recipedata.recipePrice?.menuPrice.toFixed(2) | currency:'CAD':'':undefined:(currentLang$ | async)}}</td>
                  <td class="table-data"> {{ (recipedata.recipePrice?.menuPrice - recipedata.recipePrice?.totalCost).toFixed(2) | currency:'CAD':'':undefined:(currentLang$ | async)}} </td>
                  <td class="table-data"> {{ recipedata.recipePrice?.foodCostPercent.toFixed(2) + '%' }} </td>
              </tr>
              <div class="line-break"></div>
              <tr>
                <td class="average" style="width: 4%"></td>
                  <td class="category-average"><h3> {{ 'RECIPE_SUMMARY_REPORTING.CATEGORY_AVERAGES' | translate }} </h3></td>
                  <td class="portion-cost-avg average">{{ recipe.categoryAverages?.portionCostAvg?.toFixed(2) | currency:'CAD':'':undefined:(currentLang$ | async)}}</td>
                  <td class="average" style="width: 4.6%"></td> 
                  <td class="average">{{ recipe.categoryAverages?.sellingPriceAvg?.toFixed(2) | currency:'CAD':'':undefined:(currentLang$ | async) }}</td>
                  <td class="average" style="width: 3.5%">{{ recipe.categoryAverages?.grossProfitAvg?.toFixed(2) | currency:'CAD':'':undefined:(currentLang$ | async)}}</td>
                  <td class="average">{{ recipe.categoryAverages?.foodCostPercentageAvg?.toFixed(2) + '%'}}</td>
              </tr>
              </div>
            </div> 
            <div class="overall-avg-container">
              <tr class="category-row" >
                <td style="width: 26%;"></td>
                <td  class="category-headers"> <h3> {{ 'RECIPE_SUMMARY_REPORTING.PORTION_PRICE' | translate }} </h3></td>
                <td  class="category-headers"></td>
                <td  class="category-headers"><h3>  {{ 'RECIPE_SUMMARY_REPORTING.SELLING_PRICE' | translate }} </h3></td>
                <td  class="category-headers"><h3>  {{ 'RECIPE_SUMMARY_REPORTING.GROSS_PROFIT' | translate }} </h3></td>
                <td  class="category-headers"><h3> {{ 'RECIPE_SUMMARY_REPORTING.FOOD_COST_%' | translate }} </h3></td>
              </tr>
              <div class="overallAvg-line-break"></div>
              <tr>
                <td class="category-average"><h3>{{ 'RECIPE_SUMMARY_REPORTING.OVERALL_AVERAGES' | translate }}</h3></td>
                <td class="average" 
                style="width: 3.5%"></td>
                <td class="portion-cost-avg average">{{ overallAverages.portionCostOverall.toFixed(2) | currency:'CAD':'':undefined:(currentLang$ | async)}}</td>
                <td class="average" style="width: 3.5%"></td>
                <td class="average">{{ overallAverages.sellingPriceOverall.toFixed(2) | currency:'CAD':'':undefined:(currentLang$ | async)}}</td>
                <td class="average" style="width: 3.5%">{{ overallAverages.grossMarginOverall.toFixed(2) | currency:'CAD':'':undefined:(currentLang$ | async)}}</td>
                <td class="average">{{ overallAverages.foodCostOverall.toFixed(2) }}</td>
            </tr>
            </div>
        </div>
    </div>
</div>