import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { InventoryConstants } from '@gfs/constants';
import { ConfirmationModalComponent } from '@gfs/shared-components';
import { AddToCartPayload, IAppContext, IDictionary } from '@gfs/shared-models';
import { AppConfigService, InjectionTokens, MessageService, ResolveLocalizationsInObject, WorksheetHttpService } from '@gfs/shared-services';
import { clamp, moveKeyToIndex } from '@gfs/shared-services/extensions/primitive';
import { WorksheetEffects } from '@gfs/store/inventory/effects/worksheets.effects';
import { AppState } from '@gfs/store/inventory/reducers';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { DeleteWorksheetItemResponse, InventoryItemDisplayDTO, ProductCountElement, StorageAreaDTO, WorksheetDTO, WorksheetItemDTO, WorksheetValuationStatusFlags, getUnitPrice } from '@gfs/v2/shared-models';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { InfiniteScrollListComponent, InfiniteScrollOptions } from 'libs/shared-components/src/v2/core/infinite-scroll-list/infinite-scroll-list.component';
import { HasPermissionService } from 'libs/shared-components/src/v2/core/security/has-permission.service';
import { indexOf, take } from 'lodash-es';
import { Observable, Subject, combineLatest, firstValueFrom, iif, of } from 'rxjs';
import { catchError, concatMap, delay, filter, finalize, first, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { InventoryCountListComponent } from '../inventory-count-list/inventory-count-list.component';
import { ActionTypes, AddToCartAttempt } from '@gfs/store/feature/cart';

import { CartService } from '@gfs/store/feature/cart-service/cart.service';
import { AnalyticsService } from '@gfs/store/feature/analytics-service/analytics-service.service';


export interface GroupItems {
  item: WorksheetItemDTO,
  product: InventoryItemDisplayDTO,
}

const EmptyValuationSet = [null, null];
@Component({
  selector: 'app-storage-area-item-panel',
  templateUrl: './storage-area-item-panel.component.html',
  styleUrls: ['./storage-area-item-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class StorageAreaItemPanelV2Component implements OnInit, AfterViewInit ,OnChanges{
  uiConfig = {
    pageSize: 10,
    disableUpdateWorksheetItemUnit: true,
    isOnline: true,

    infiniteScrollEnable: false,
    scrollerOptions: {
      autoLoadFirstPage: true,
      scrollPercent: 60,
      containerHeight: 500,
      pageSize: 10,
      enableLog: true,
      loaderStyle: 'single',
      hideScrollbar: false
    } as InfiniteScrollOptions
  };
  isMobile$ = this.store.select((state) => state.layout.isMobile);
  useGroupOperations$: Observable<boolean> = this.store.select((state)=> state.worksheets?.useGroupOperations
    ).pipe(
      filter(v => v !== undefined),
      map((value)=>this.isActiveInventory() ? value : false)
      );
  refreshSeq = 0;
  lastRefreshSeq = -1;
  storageAreaPaging: { [s: string]: number } = {};
  storageAreaWorksheetItemRange: { [s: string]: [number, number, number] } = {};
  updateWorksheetItemUnitPermission = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet.updateWorksheetItemUnit;
  permissionRoles = InventoryConstants.INVENTORY_ROLE_PERMISSIONS.inventoryWorksheet;
  blurStatusMatches = [
    WorksheetValuationStatusFlags.Pending,
  ];

  @Input()
  worksheetModel: WorksheetDTO = null;

  destroy$ = new Subject();

  @ViewChildren(InventoryCountListComponent)
  countingUnitSets!: QueryList<InventoryCountListComponent>;

  @ViewChildren(MatExpansionPanel)
  expansionPanels!: QueryList<MatExpansionPanel>;


  @ViewChildren(InfiniteScrollListComponent)
  public scrollContainers: QueryList<InfiniteScrollListComponent>;

  @Input()
  productFilter: IDictionary<string[]> = null;

  @Input()
  productValuationStatus: IDictionary<WorksheetValuationStatusFlags>;

  @Input()
  worksheetItemErrors: IDictionary<string>;


  @Input()
  storageAreaExpansionState: IDictionary<boolean>;

  @Output() invalidateStorageAreaSum = new EventEmitter<string>();
  @Output() productCountChange = new EventEmitter<Array<ProductCountElement>>();

  @Output() afterExpand = new EventEmitter();

  @ViewChildren('storageAreaPaginator')
  paginators: QueryList<MatPaginator>;

  @ViewChildren('paginatorLocator')
  paginatorsDOM: QueryList<ElementRef>;

  @ViewChildren('expansionPanelLocator')
  expansionPanelsDOM: QueryList<ElementRef>;

  hasItemTripleDot = false;
  checkedWorksheetItemsList = [];
  storageAreasSelectedAllList = [];
  isSelectAllChecked = false;
  disableAddtoCartSubject:any


  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
    private store: Store<AppState>,
    private permissionsService: HasPermissionService,
    public cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private worksheetHttp: WorksheetHttpService,
    private messageService: MessageService,
    private loadingOverlay: LoadingSpinnerOverlayService,
    private configService: AppConfigService,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService,
    public cartService:CartService,
    private analyticsService : AnalyticsService

  

    // private injector: Injector
  ) { }
  ngOnChanges(changes: SimpleChanges): void {
    WorksheetEffects.itemSearchFilterText$.pipe(takeUntil(this.destroy$)).subscribe((text)=>{
      if(text == ''){
        this.storageAreasSelectedAllList = [];
      }
    })
  }

  private getInfiniteScrollingSettingState$(): Observable<boolean> {
    return this.store.select(
      (state) => {
        return state.worksheets.useInfiniteScrolling;
      }
    ).pipe(
      catchError(() => of(false)),
    );
  }

  ngAfterViewInit(): void {
    this.initBehavior$().subscribe();
  }

  async ngOnInit(): Promise<any> {
  
    this.cartService.handleAddToCartSuccess();
    this.checkedWorksheetItemsList = [];
    const hasUpdateWorksheetItemUnitPermission = await this.permissionsService.hasPermission(this.updateWorksheetItemUnitPermission);
    this.uiConfig.disableUpdateWorksheetItemUnit = !hasUpdateWorksheetItemUnitPermission;
    this.hasItemTripleDot = await this.permissionsService.hasPermission(this.permissionRoles.itemTripleDot);
  }

  initBehavior$() {
    return combineLatest([
      this.appContext.isOnline$
        .pipe(
          startWith(true),
          tap(v => {
            this.uiConfig.isOnline = v;
          })
        ),
      this.createBehaviorInfiniteScrollingStorageAreaToggle$(
        this.getInfiniteScrollingSettingState$()
      )
    ])
      .pipe(takeUntil(this.destroy$));
  }

  async expandAndFocusStorageArea(storageAreaId: string) {

    const storageAreaIndex =
      this.expansionPanelsDOM
        .toArray()
        .map(
          htmlElement =>
            htmlElement.nativeElement.value
        ).indexOf(storageAreaId);

    const storageAreaExpansionPanel = this.expansionPanels.get(storageAreaIndex);

    // if the storage area is already expanded, we can just focus it immediately
    if (storageAreaExpansionPanel.expanded) {
      this.scrollToStorageArea(storageAreaId);
    } else {
      return await Promise.any([
        firstValueFrom(
          storageAreaExpansionPanel
            .afterExpand
            .asObservable()
            .pipe(
              first(),
              map(_ => {
                this.scrollToStorageArea(storageAreaId);
                return true;
              })
            )
        ),
        firstValueFrom(of(true)
          .pipe(
            tap(_ => { storageAreaExpansionPanel.toggle() }),
            delay(3000)
          ))
      ])

    }

  }

  scrollToStorageArea(storageAreaId: string) {
    const domElementId = 'storage-area-item-panel-' + storageAreaId;
    const storageAreaPanelDom = this.document.getElementById(domElementId);
    const scrollContainer = this.document.getElementById('mat-sidenav-content');
    scrollContainer.scrollTop = storageAreaPanelDom.offsetTop;
  }

  getTranslatedStorageAreaName(storageArea:StorageAreaDTO){
    return {storageArea :storageArea.name[this.translate.currentLang]}
  }

  getValuation(
    key: string,
    index: number = 0,
  ) {
    try {
      const status = this.productValuationStatus[key]
        ?? WorksheetValuationStatusFlags.Complete;
      const valuationSource = this.worksheetModel.worksheetValuations;
      const valuations = valuationSource[key] ?? EmptyValuationSet;
      const value = this.tryCalculatePrice(key, index) ?? valuations[index];

      return {
        status,
        value
      };
    } catch (ex) {
      return {
        status: WorksheetValuationStatusFlags.Error,
        value: null
      };
    }
  }

  tryCalculatePrice(
    key: string,
    index: number
  ) {

    try {

      const worksheetItem = this.worksheetModel.worksheetItems[key];
      if (worksheetItem) {
        const count = worksheetItem.itemCounts[index];
        const calcDriver = this.worksheetModel
          .inventoryValuationModel[worksheetItem.itemId];

        const selectedUnit = calcDriver[count.unit];
        if (selectedUnit) {
          return getUnitPrice(selectedUnit, count.qty)
        }
      }

    } catch (ex) {
      console.warn(`unable to get price ${key}:${index}`, ex)
    }

    return null;
  }

  isActiveInventory() {
    // if the closed date is populated it should be in a closed state
    return !this.worksheetModel?.header?.closedDate;
  }

  isValueBlurred(
    status: WorksheetValuationStatusFlags
  ) {
    return this.blurStatusMatches.indexOf(status) > -1;
  }

  private createBehaviorInfiniteScrollingStorageAreaToggle$(
    useInfiniteScrolling$: Observable<boolean>
  ): Observable<boolean> {
    return useInfiniteScrolling$
      .pipe(
        tap(enabled => {
          this.uiConfig.infiniteScrollEnable = enabled;

          this.storageAreaWorksheetItemRange = this.worksheetModel.storageAreaOrder
            .reduce((prev, currentStorageAreaKey) => {
              return { ...prev, [currentStorageAreaKey]: [0, this.uiConfig.pageSize, 0] };
            }, {});

          this.scrollContainers.forEach(v => { v.resetData(); });

          this.createDeferredCDRCheck();
        })
      );
  }

  createDeferredCDRCheck() {
    return setTimeout(() => {
      this.cdr.markForCheck();
    }, 1);
  }

  //#region User Actions
  async duplicateClicked(
    item: WorksheetItemDTO,
    product: InventoryItemDisplayDTO,
  ): Promise<Boolean> {
    this.loadingOverlay.show();
    this.cdr.markForCheck();

    return firstValueFrom(
      this.worksheetHttp.createCloneWorksheetItemRequest(
        item.worksheetId,
        item.worksheetItemId,
      ).pipe(
        map(response => {

          // notify ui item was added
          WorksheetEffects.itemChangedNotifier$.next({
            storageAreaId: response.worksheetItem.storageAreaId,
            worksheetItemId: response.worksheetItem.worksheetItemId
          });
          //notify the user that it was successful
          this.messageService.queue(
            'MESSAGES.ITEM_DUPLICATED_SUCCESSFULLY',
            { value: product.descriptionLine1 }
          );
          return true;
        }),
        catchError(() => {
          this.messageService.queue('MESSAGES.SNACKBAR_ERROR_MESSAGE');
          this.loadingOverlay.hide();
          return of(false);
        }),
      ));
  }
 
  // This method checks if the item corrosponding to the checkbox is present in our array containing checked Items.
  isWorksheetItemChecked(item, setIschecked?: boolean): Boolean{
    const index = this.checkedWorksheetItemsList.findIndex(element => element.item?.worksheetItemId === item?.Data?.item?.worksheetItemId);
      if(index > -1){
       return setIschecked ?? true
      }
  }

  //This method maintains an array of all the products that are selected by the user for deletion/move
  checkboxSelectedForWorksheetItem(event){
    if(event.checked){
      if (this.checkedWorksheetItemsList.includes(event.source.value.Data) != true){
        this.checkedWorksheetItemsList.push(event.source.value.Data)
      }
    } else {
      const storageAreaIdOfCurrentItem = event.source.value.Data.item.storageAreaId;
      const index = this.checkedWorksheetItemsList.findIndex(element => element.item.worksheetItemId === event.source.value.Data.item.worksheetItemId);
      if(index > -1){
        this.checkedWorksheetItemsList.splice(index, 1);
      }
      const storageAreaIndex = this.storageAreasSelectedAllList.findIndex(element => element  === storageAreaIdOfCurrentItem)
      if(index > -1){
        this.storageAreasSelectedAllList.splice(storageAreaIndex, 1);
      }
    }
  }

  //On clicking select all , this method will add all items in the storage area to the list of selected worksheetitems 
  onSelectAllClicked(event , storageAreaSelected){ 
     if(event.checked){
      this.storageAreasSelectedAllList.push(storageAreaSelected);
      this.addOrRemoveAllItemsToSelectedArray(event,storageAreaSelected);
     } else {
      this.addOrRemoveAllItemsToSelectedArray(event,storageAreaSelected);
      const index = this.storageAreasSelectedAllList.findIndex(element => element  === storageAreaSelected)
        if(index > -1){
          this.storageAreasSelectedAllList.splice(index, 1);
        }
    }
  }

  //this method adds or removes the item from the list of selected worksheet items based on the select all checkbox click
  addOrRemoveAllItemsToSelectedArray(event,storageAreaSelected){
    const storageAreaItemOrder = this.productFilter[storageAreaSelected];
    storageAreaItemOrder?.forEach((worksheetItemIds)=>{
      const item =  this.worksheetModel.worksheetItems[worksheetItemIds];
      const product = this.worksheetModel.inventoryItems[item.itemId];
      if(event.checked){
        const exists = this.checkedWorksheetItemsList.findIndex(element => element.item.worksheetItemId === item.worksheetItemId) > -1;
        if(!exists){
          this.checkedWorksheetItemsList.push({item : item , product : product}) 
        }
      } else {
        const index = this.checkedWorksheetItemsList.findIndex(element => element.item.worksheetItemId === item.worksheetItemId)
        if(index > -1){
          this.checkedWorksheetItemsList.splice(index, 1);
        }
      }
    })
  }

  //This gives the list of storage areas which from which items are selected for deletion/move
  getUniqueStorageAreaIdsSelected(): Array<string>{
    let uniqueStorageAreasSelected =[]
    const worksheetItemIds = this.checkedWorksheetItemsList.map((items)=>{
      return items.item?.worksheetItemId ;
    })
    const storageAreaIds = []
    worksheetItemIds.forEach((ids)=>{
      storageAreaIds.push(this.worksheetModel?.worksheetItems[ids]?.storageAreaId)
    })
   return uniqueStorageAreasSelected = [...new Set(storageAreaIds)]
  }

  async groupMoveToStorageAreaClicked(
    worksheetId: string ,
    storageAreaId: string
  ): Promise<boolean> {
    return firstValueFrom(
      this.dialog.open(ConfirmationModalComponent, {
        data: {
          returnData: this.checkedWorksheetItemsList,
          type:'GROUP_MOVE',
          title: 'STORAGE_AREA.GROUP_MOVE_ITEMS_TO_STORAGE_AREA.MOVE_GROUP_ITEMS',
          titleData: { itemName: `${this.checkedWorksheetItemsList.length}` , storageAreaName:`${(this.worksheetModel?.storageAreas[storageAreaId]?.name[this.translate.currentLang])}`},
          cancelButtonId: 'cancel-move-item-button',
          submitButtonId: 'move-item-button',
          submitButtonAriaLabel: 'STORAGE_AREA.MOVE_STORAGE_AREA_ITEMS_BUTTON_ARIA_LABEL',
          submitButtonLabel: 'STORAGE_AREA.MOVE_STORAGE_AREA_ITEMS_BUTTON',
        },
        width: '608px',
        height: '237px'
      })
        .afterClosed()
        .pipe(
          concatMap(
            worksheetItemToBeDeleted => iif(
              () => !worksheetItemToBeDeleted,
              of(true),
              of({
                selectedEntries : this.checkedWorksheetItemsList ,
                storageAreaId : storageAreaId
               }).pipe(
                tap(() => { this.loadingOverlay.show(); }),
                concatMap(({
                  selectedEntries,
                  storageAreaId
                }) => this.worksheetHttp.createGroupMoveWorksheetRequest(
                  worksheetId,
                  selectedEntries,
                  this.getUniqueStorageAreaIdsSelected(),
                  storageAreaId,
                )),
                map(() => true),
                tap(() => {

                  //update the storage area of the item on the model
                  this.checkedWorksheetItemsList.forEach((items)=>{
                    this.worksheetModel.worksheetItems[items.item.worksheetItemId].storageAreaId =
                    storageAreaId;
                  })
                  
                  //notify the user that it was successful
                  this.messageService.queue(
                    'MESSAGES.GROUP_ITEM_MOVED_TO_SUCCESSFULLY',
                    { value: `${this.checkedWorksheetItemsList.length}` } ,
                    true
                  );
            
                  //notify the rest of the ui
                  WorksheetEffects.groupItemMovedNotifier$.next({
                    storageAreaId,
                    worksheetItemIds: this.checkedWorksheetItemsList.map((items)=> items.item.worksheetItemId)
                  });
                  this.checkedWorksheetItemsList = [];
                  this.storageAreasSelectedAllList = [];
                }),
                catchError(() => {
                  this.loadingOverlay.hide();
                  return of(false);
                }),
              )
            )
          ),
        )
    );
}

  //handles the group delete operation
  async onGroupDeleteClicked(WorksheetId): Promise<Boolean>{
    return firstValueFrom(
      this.dialog.open(ConfirmationModalComponent, {
        data: {
          returnData: this.checkedWorksheetItemsList,
          type:'GROUP_DELETE',
          title: 'STORAGE_AREA.DELETE_STORAGE_AREA_ITEM_MODAL.REMOVE_STORAGE_AREA_ITEM_DIALOG_TITLE',
          titleData: { itemName: `${this.checkedWorksheetItemsList.length}`},
          cancelButtonId: 'cancel-delete-item-button',
          submitButtonId: 'delete-item-button',
          submitButtonAriaLabel: 'STORAGE_AREA.REMOVE_STORAGE_AREA_BUTTON_ARIA_LABEL',
          submitButtonLabel: 'STORAGE_AREA.REMOVE_STORAGE_AREA_ITEM_BUTTON',
        },
        width: '608px',
        height: '290px'
      })
        .afterClosed()
        .pipe(
          concatMap(
            worksheetItemToBeDeleted => iif(
              () => !worksheetItemToBeDeleted,
              of(true),
              of({
                worksheetId: WorksheetId,
                selectedEntries : this.checkedWorksheetItemsList
              }).pipe(
                tap(() => { this.loadingOverlay.show(); }),
                concatMap(
                  (groupDeleteData) =>
                    this.handleMultiDeleteOperation(groupDeleteData)
                ),
                map(() => true),
                tap(() => {
                  this.messageService.queue('STORAGE_AREA.DELETE_STORAGE_AREA_ITEM_DELETED_SUCCESSFULLY',{value: `${this.checkedWorksheetItemsList.length} Items`}, true); 
                  this.checkedWorksheetItemsList = [];
                  this.storageAreasSelectedAllList = [];
                }),
                catchError(() => {
                  this.loadingOverlay.hide();
                  return of(false);
                }),
              )
            )
          ),
        )
    );
  }
  handleMultiDeleteOperation(groupDeleteData): Observable<any>{
    const storageAreaIds = this.getUniqueStorageAreaIdsSelected()
    return this.worksheetHttp.createGroupDeleteWorksheetItemRequest(groupDeleteData.worksheetId,groupDeleteData.selectedEntries,storageAreaIds).pipe(
      tap((v) => {
        groupDeleteData.selectedEntries.forEach((items)=>{
          this.removeWorksheetItemIdFromStorageAreaOrder(items.item?.worksheetItemId);
        })
        WorksheetEffects.storageAreaSchemaChange$.next();
      })
    );
  }

  async deleteClicked(
    item: WorksheetItemDTO,
    product: InventoryItemDisplayDTO,
  ): Promise<Boolean> {
    return firstValueFrom(
      this.dialog.open(ConfirmationModalComponent, {
        data: {
          returnData: item,
          title: 'STORAGE_AREA.DELETE_STORAGE_AREA_ITEM_MODAL.REMOVE_STORAGE_AREA_ITEM_DIALOG_TITLE',
          titleData: { itemName: product.descriptionLine1 },
          cancelButtonId: 'cancel-delete-item-button',
          submitButtonId: 'delete-item-button',
          submitButtonAriaLabel: 'STORAGE_AREA.REMOVE_STORAGE_AREA_BUTTON_ARIA_LABEL',
          submitButtonLabel: 'STORAGE_AREA.REMOVE_STORAGE_AREA_ITEM_BUTTON',
        },
        width: '608px',
        height: '237px'
      })
        .afterClosed()
        .pipe(
          concatMap(
            worksheetItemToBeDeleted => iif(
              () => !worksheetItemToBeDeleted,
              of(true),
              of({
                worksheetId: item.worksheetId,
                worksheetItemId: item.worksheetItemId
              }).pipe(
                tap(() => { this.loadingOverlay.show(); }),
                concatMap(
                  ({ worksheetId, worksheetItemId }) =>
                    this.handleDeleteWorksheetItemOperation(
                      worksheetId,
                      worksheetItemId
                    )
                ),
                map(() => true),
                tap(() => { this.messageService.queue('STORAGE_AREA.DELETE_STORAGE_AREA_ITEM_DELETED_SUCCESSFULLY',{value:product.descriptionLine1}); }),
                catchError(() => {
                  this.loadingOverlay.hide();
                  return of(false);
                }),
              )
            )
          ),
        )
    );

  }

  async moveToStorageAreaClicked(
    item: WorksheetItemDTO,
    product: InventoryItemDisplayDTO,
    storageAreaId: string
  ): Promise<boolean> {
    return firstValueFrom(
      of({
        worksheetId: item.worksheetId,
        worksheetItemId: item.worksheetItemId,
        storageAreaId
      }).pipe(
        tap(() => { this.loadingOverlay.show(); }),
        concatMap(({
          worksheetId,
          worksheetItemId,
          storageAreaId
        }) => this.worksheetHttp.createMoveWorksheetRequest(
          worksheetId,
          worksheetItemId,
          storageAreaId
        )),
        map(() => true),
        tap(() => {
          //update the storage area of the item on the model
          this.worksheetModel.worksheetItems[item.worksheetItemId].storageAreaId =
            storageAreaId;

          //notify the user that it was successful
          this.messageService.queue(
            'MESSAGES.ITEM_MOVED_TO_SUCCESSFULLY',
            { value: product.descriptionLine1 }
          );

          //notify the rest of the ui
          WorksheetEffects.itemChangedNotifier$.next({
            storageAreaId,
            worksheetItemId: item.worksheetItemId
          });

        }),
        catchError(() => {
          this.loadingOverlay.hide();
          return of(false);
        }),

      )
    );
  }

  handleDeleteWorksheetItemOperation(
    worksheetId: string,
    worksheetItemId: string
  ): Observable<DeleteWorksheetItemResponse> {
    return this.worksheetHttp.createDeleteWorksheetItemRequest(
      worksheetId,
      worksheetItemId
    ).pipe(
      map((v) => {
        this.removeWorksheetItemIdFromStorageAreaOrder(worksheetItemId);
        WorksheetEffects.storageAreaSchemaChange$.next();
        return v;
      })
    );
  }

  //#region Worksheet Item Re-Sequenceing
  handleWorksheetItemSequenceUpdate(
    event: {
      moveToIndex: any,
      moveFromIndex: any,
      key: string
    }
  ) {
    const storageAreaId = event.key;
    if (event.moveToIndex === event.moveFromIndex) {
      return;
    }

    const currentSequence = this.worksheetModel.storageAreaItemOrder[storageAreaId];
    const storageArea = this.worksheetModel.storageAreas[storageAreaId];

    const updatedSequence = moveKeyToIndex(currentSequence, event);

    //update the filtered productSequence
    const activeProductFilter = this.productFilter[storageAreaId];
    this.productFilter[storageAreaId] = updatedSequence.filter((v) => activeProductFilter.indexOf(v) > -1);
    this.loadingOverlay.show();

    return this.worksheetHttp.createWorksheetItemSequencePatchRequest(
      this.worksheetModel.header.worksheetId,
      storageAreaId,
      updatedSequence
    ).pipe(
      tap(v => {
        // update the model with the new sequence
        this.worksheetModel.storageAreaItemOrder[storageAreaId] = updatedSequence;
        this.messageService.queue(
          'MESSAGES.STORAGE_AREA_UPDATE_MESSAGE',
          { value: storageArea.name }
        );
      }),
      finalize(() => {
        this.loadingOverlay.hide();
        this.cdr.detectChanges();
      }),
      map(() => true),
      catchError(() => {
        this.messageService.queue('MESSAGES.SNACKBAR_ERROR_MESSAGE');
        return of(false);
      }),
    ).toPromise();

  }

  // adapt storage area sequence change to form event
  async handleWorksheetItemIndexChange(
    event,
    storageAreaId: string,
    currentIndex: number,
    target: MatInput
  ) {

    target.value = clamp(
      event.target.value,
      1,
      this.worksheetModel.storageAreaItemOrder[storageAreaId].length
    );

    return this.handleWorksheetItemSequenceUpdate({
      moveToIndex: +event.target.value - 1, // indexes start at 1 when entered by the user
      moveFromIndex: currentIndex,
      key: storageAreaId
    });
  }

  // adapt storage area sequence change to drag and drop event
  async handleWorksheetItemCDKListDropEvent(
    event: any,
    storageAreaId: string,
  ) {
    return this.handleWorksheetItemSequenceUpdate({
      moveToIndex: event.currentIndex,
      moveFromIndex: event.previousIndex,
      key: storageAreaId
    });
  }
  //#endregion

  //#endregion

  removeWorksheetItemIdFromStorageAreaOrder(
    worksheetItemId: string
  ) {
    Object.keys(this.worksheetModel.storageAreaItemOrder)
      .forEach((storageAreaId) => {
        const order = this.worksheetModel.storageAreaItemOrder[storageAreaId];
        const removeIdx = order.indexOf(worksheetItemId);
        if (removeIdx > -1) {
          this.worksheetModel.storageAreaItemOrder[storageAreaId].splice(removeIdx, 1);
        }
      });
  }

  async moveToNextItem(
    currentInventoryCountComponent: InventoryCountListComponent
  ) {

    const {
      storageAreaId: currentStorageAreaId,
      worksheetItemId: currentWorksheetItemId
    } = currentInventoryCountComponent.worksheetItem;

    const storageAreaProducts = this.productFilter[currentStorageAreaId];

    const storageAreasWithProducts =
      this.worksheetModel.storageAreaOrder
        .filter(
          storageAreaId =>
            this.productFilter[storageAreaId].length > 0
        );

    const currentWorksheetItemIndex =
      storageAreaProducts.indexOf(currentWorksheetItemId);


    const currentlyVisibleStorageAreaKeys =
      this.expansionPanelsDOM
        .toArray()
        .map(
          htmlElement =>
            htmlElement.nativeElement.value
        );

    const currentlyVisiblePaginatorKeys =
      this.paginatorsDOM
        .toArray()
        .map(
          htmlElement =>
            htmlElement.nativeElement.value);

    const currentInventoryCountInputListIndex = this.countingUnitSets
      .toArray()
      .indexOf(currentInventoryCountComponent)


    const nextVisibleWorksheetItemIdx = currentInventoryCountInputListIndex + 1;

    const currentStorageAreaIndex = currentlyVisibleStorageAreaKeys.indexOf(currentStorageAreaId);
    const currentPaginatorIndex = currentlyVisiblePaginatorKeys.indexOf(currentStorageAreaId);

    const currentPanel = this.expansionPanels.get(currentStorageAreaIndex);
    const currentPaginator = this.paginators.get(currentPaginatorIndex);

    const isStorageAreaEOD = (currentWorksheetItemIndex + 1) === storageAreaProducts.length;

    // the page never ends when infinite scroll is enabled, this has a side effect of preventing interaction with the
    // built in pagination.
    const isPageEOD = !this.uiConfig.infiniteScrollEnable && (((currentWorksheetItemIndex) + 1) % currentPaginator.pageSize) === 0;




    let nextPanel: MatExpansionPanel = null;
    let nextStorageAreaIndex = currentStorageAreaIndex + 1;
    let nextStorageAreaId: string = currentlyVisibleStorageAreaKeys[nextStorageAreaIndex];

    // ensure our virtual Next storage area has items to focus
    while ((nextStorageAreaIndex) < currentlyVisibleStorageAreaKeys.length) {
      if (storageAreasWithProducts.indexOf(nextStorageAreaId) > -1) {
        nextPanel = this.expansionPanels.get(nextStorageAreaIndex);
        break;
      }
      nextStorageAreaIndex++;
      nextStorageAreaId = currentlyVisibleStorageAreaKeys[nextStorageAreaIndex];
    }


    //we reached the end of the worksheet
    if (isStorageAreaEOD && nextPanel == null) {
      currentPanel.toggle();
    }
    // we reached the end of a page
    else if (currentPaginator.hasNextPage() && isPageEOD) {
      return Promise.all([
        currentPaginator.page
          .pipe(
            first(),
            tap((p) => {
              this.deferFocusFirstItemInStorageArea(currentStorageAreaId);
            })
          ).toPromise(),
        of(true)
          .pipe(tap(_ => {
            currentPaginator.nextPage();
          })).toPromise()
      ]);


    }
    // reached end of storage area
    else if (isStorageAreaEOD) {

      if (nextPanel.expanded) {
        currentPanel.toggle();// close the current panel and focus the next item in the next panel
        const idx = this.findNearestIndexWithCountedTiers(nextVisibleWorksheetItemIdx);

        this.tryFocusProductCountEntry(
          this.countingUnitSets.get(idx)
        );
      }
      else {

        return of(false).pipe(tap(_ => {
          currentPanel.toggle();
          nextPanel.toggle();
        }),
          concatMap(_ => nextPanel.afterExpand),
          first(),
          tap(_ => {

            // 1.1: select first item of the next storage-area
            this.deferFocusFirstItemInStorageArea(nextStorageAreaId);
          })
        ).toPromise();


      }

    }
    // move to next item on current page of current storage area
    else if (!isPageEOD) {
      const idx = this.findNearestIndexWithCountedTiers(nextVisibleWorksheetItemIdx);
      this.tryFocusProductCountEntry(this.countingUnitSets.get(idx));
    }

  }

  addToCart(Vm){
    this.cartService.disbaleSubject.next({disable : true,itemId : Vm.itemId.split('_')[1]})
    const addToCartRequest = {
      itemId : Vm.itemId.split('_')[1],
      quantity : '1',
      unitType : 'CS'
    } as AddToCartPayload
    this.store.dispatch(new AddToCartAttempt({addToCartData : addToCartRequest}))
    this.analyticsService.GA4EventLogger('inventory_add_to_cart' ,  Vm.itemId.split('_')[1])
  }

  private findNearestIndexWithCountedTiers(nextUIWorksheetItemIdx: number) {
    let idx = nextUIWorksheetItemIdx;
    const max = this.countingUnitSets.length;
    while (idx < max && this.countingUnitSets.get(idx).countingUnitTiers.length == 0) {
      idx++;
    }
    return idx;
  }

  tryFocusProductCountEntry(
    nextCountComponent: InventoryCountListComponent
  ) {
    if (nextCountComponent) {
      nextCountComponent.countingUnitTiers
        .first
        .itemCountInput.nativeElement.focus();
    }
  }

  deferFocusFirstItemInStorageArea(
    storageAreaId: string
  ) {
    return setTimeout(() => {
      try {
        const currentStorageAreaComponents = this.countingUnitSets
          .filter(v => v.worksheetItem.storageAreaId === storageAreaId);

        const firstWorksheetItemWithCountableTiers = currentStorageAreaComponents.find(v => v.countingUnitTiers.length > 0)

        this.tryFocusProductCountEntry(firstWorksheetItemWithCountableTiers);
      } catch (ex) {
        console.warn(ex);
      }
    }, 1);
  }

  getWorksheetItemIndex(
    worksheetItemId: string,
    storageAreaId: string
  ) {
    const worksheetItemIndex =
      this.worksheetModel
        .storageAreaItemOrder[storageAreaId]
        .indexOf(worksheetItemId);

    return { value: worksheetItemIndex };
  }

  isGFSItem(itemId) : boolean {
    return (itemId?.slice(0,3) === 'GFS');
  }

  canComputeValue(
    worksheetItemId: string,
    displayCode: string
  ) {
    try {
      if (displayCode === '--') {
        return true;
      }
      const worksheetItem = this.worksheetModel.worksheetItems[worksheetItemId];
      const pricableUnit = this.worksheetModel.inventoryValuationModel[worksheetItem.itemId][displayCode];

      const conditions = [
        (pricableUnit.unitMultiplier > 0),
        (pricableUnit.price > 0),
        (pricableUnit.warning?.length === 0),
      ]

      return conditions.indexOf(false) === -1
    } catch (ex) { return false; }
  }

  //#region Pagination
  onPageClick(
    event: PageEvent,
    storageAreaId: string
  ) {
    // set page start index
    const index = event.pageIndex * event.pageSize;
    this.storageAreaPaging[storageAreaId] = index;
    this.storageAreaWorksheetItemRange[storageAreaId] = [index, index + event.pageSize, event.pageIndex];

  }

  getCurrentPageByStorageAreaId(
    storageAreaId: string,
    offsetToAdd: number = 0
  ) {
    return this.storageAreaPaging[storageAreaId]
      ? this.storageAreaPaging[storageAreaId] + this.uiConfig.pageSize
      : offsetToAdd;
  }

  getCurrentPageStartByStorageAreaId(
    storageAreaId: string
  ) {
    return this.storageAreaWorksheetItemRange[storageAreaId]
      ? this.storageAreaWorksheetItemRange[storageAreaId][0]
      : 0;
  }

  getCurrentPageEndByStorageAreaId(
    storageAreaId: string
  ) {
    return this.storageAreaWorksheetItemRange[storageAreaId]
      ? this.storageAreaWorksheetItemRange[storageAreaId][1]
      : this.uiConfig.pageSize;
  }

  getPage(
    q: { storageAreaId: string },
    pageSize: number,
    page: number
  ) {
    const nextPage = page + 1;

    const sliceStart = page * pageSize;
    const sliceEnd = nextPage * pageSize;

    this.storageAreaWorksheetItemRange[q.storageAreaId] = [0, sliceEnd, page];

    const idPage = this.productFilter[q.storageAreaId].slice(sliceStart, sliceEnd);
    return of(idPage);

  }

  getCurrentPageIndex(
    storageAreaId: string
  ): number {
    return this.storageAreaWorksheetItemRange[storageAreaId]?.[2] ?? 0;
  }
  //#endregion
  canShowTotals(): boolean {
    return this.configService.getSettings().FF_WORKSHEET_TOTALS
  }
}